import React from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
  } from "react-google-maps";



// import { styled, createTheme, ThemeProvider } from '@mui/material/styles';


const REACT_APP_SWEAR = process.env.REACT_APP_SWEAR


// const mdTheme = createTheme();
// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//   },
// });


export default function WarehouseDataDisplay(props) {


    const RegularMap = withScriptjs(
        withGoogleMap(() => (
          <GoogleMap
            defaultZoom={10}
            defaultCenter={{ lat: props.netOptzData.metroCenterLat, lng: props.netOptzData.metroCenterLon}}
            defaultOptions={{
              scrollwheel: false
            }}
            >
    
            {props.netOptzData.markerList.map(marker => 
            <Marker
            key={marker.id}
            position={{lat: marker.lat, lng: marker.lon}}
            />
            )}
    
          </GoogleMap>
        ))
      );


    return (
        <div style={{ height: 600, width: '100%' }}>
            
            
          <RegularMap // eslint-disable-next-line
            googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + `${REACT_APP_SWEAR}`} 
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{
                  height: `650px`,
                  borderRadius: "6px",
                  overflow: "hidden"
                }}
              />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />

            </div>


    )
}