import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import PeopleIcon from '@mui/icons-material/People';
// import BarChartIcon from '@mui/icons-material/BarChart';
// import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Divider from '@mui/material/Divider';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import BusinessIcon from '@mui/icons-material/Business';
import InventoryIcon from '@mui/icons-material/Inventory';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import FunctionsIcon from '@mui/icons-material/Functions';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import ShoppingCart from '@mui/icons-material/ShoppingCart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SpeedIcon from '@mui/icons-material/Speed';
import { Link as RouterLink } from "react-router-dom"
// import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import WavesIcon from '@mui/icons-material/Waves';
import CropFreeIcon from '@mui/icons-material/CropFree';
import ApiIcon from '@mui/icons-material/Api';

export const mainListItems = (


  <div>
      {/*Signin*/}

    {/* <ListItem  component={RouterLink} to='/SignIn' button>
      <ListItemIcon>
        <AssignmentIndIcon/>
      </ListItemIcon>
      <ListItemText primary="Sign In" />
    </ListItem> */}


      {/*Dashboard*/}
      <ListItem  component={RouterLink} to='/'button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
  
    {/*Upload*/}
    <ListItem component={RouterLink} to='/upload' button>
      <ListItemIcon>
        <UploadFileIcon />
      </ListItemIcon>
      <ListItemText primary="Data Upload" />
    </ListItem>

    <Divider />
    <ListSubheader inset>Optimization</ListSubheader>
    <Divider />

    {/*Network Optimizations*/}
    <ListItem component={RouterLink} to='/network' button>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <ListItemText primary="Network" />
    </ListItem>
     
     {/*Inventory Optimizations*/}
    <ListItem component={RouterLink} to='/inventory' button>
      <ListItemIcon>
        <InventoryIcon />
      </ListItemIcon>
      <ListItemText primary="Inventory" />
    </ListItem>
    
    <Divider />
    <ListSubheader inset>Analysis</ListSubheader>
    <Divider />

    {/*Demand Analyzer*/}
    <ListItem component={RouterLink} to='/demand' button>
      <ListItemIcon>
        <AnalyticsIcon />
      </ListItemIcon>
      <ListItemText primary="Demand Analyzer" />
    </ListItem>
    
    {/*Simulator*/}
    <ListItem component={RouterLink} to='/simulator' button>
      <ListItemIcon>
        <FunctionsIcon />
      </ListItemIcon>
      <ListItemText primary="Simulator" />
    </ListItem>
{/* 
    <Divider />
    <ListSubheader inset>Connections</ListSubheader>
    <Divider />

    {/*Partners*/}

    {/* <ListItem component={RouterLink} to='/partners' button>
      <ListItemIcon>
        <GroupWorkIcon />
      </ListItemIcon>
      <ListItemText primary="Partners" />
    </ListItem> */}

    {/*Your Resources*/}
    {/* <ListItem component={RouterLink} to='/resources' button>
      <ListItemIcon>
        <AccountTreeIcon />
      </ListItemIcon>
      <ListItemText primary="Your Resources" />
    </ListItem> */}

    <Divider /> 


    <ListSubheader inset>Operations</ListSubheader>
    <Divider />
    


    {/*IoT*/}
    <ListItem component={RouterLink} to='/InventoryTracking' button>
      <ListItemIcon>
        <ReceiptIcon/>
      </ListItemIcon>
      <ListItemText primary="Inventory Management" />
    </ListItem>


    {/*Replenishment*/}
    <ListItem component={RouterLink} to='/replenishment' button>
      <ListItemIcon>
        <LocalShippingIcon />
      </ListItemIcon>
      <ListItemText primary="Replenishment" />
    </ListItem>

    {/*Fulfillment*/}
    <ListItem component={RouterLink} to='/fulfillment' button>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Fulfillment" />
    </ListItem>

    {/*Cycle Count*/}
    {/* <ListItem component={RouterLink} to='/CycleCount' button>
      <ListItemIcon>
        <CropFreeIcon />
      </ListItemIcon>
      <ListItemText primary="Inventory Scanning" />
    </ListItem> */}

    {/*IoT*/}
    {/* <ListItem component={RouterLink} to='/iot' button>
      <ListItemIcon>
        <WavesIcon />
      </ListItemIcon>
      <ListItemText primary="IoT Tracking" />
    </ListItem> */}
    {/*Evaluation Dashboard*/}
    <ListItem component={RouterLink} to='/evaluation' button>
      <ListItemIcon>
        <SpeedIcon />
      </ListItemIcon>
      <ListItemText primary="Performance Metrics" />
    </ListItem>


    {/* <ListItem component={RouterLink} to='/MachineLearning' button>
      <ListItemIcon>
        <AccountBalanceWalletIcon />
      </ListItemIcon>
      <ListItemText primary="Artificial Intelligence" />
    </ListItem>
    <ListItem component={RouterLink} to='/EdonAPI' button >
      <ListItemIcon>
        <ApiIcon/>
      </ListItemIcon>
      <ListItemText primary="Edon API" />
    </ListItem> */}


  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>History</ListSubheader>
    <Divider/>

    {/*Current*/}
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>

    {/*Last Month*/}
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last month" />
    </ListItem>

    {/*YTD*/}
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year to date" />
    </ListItem> 
  </div>
);