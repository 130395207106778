import * as React from "react";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import WALogo from "./WA_logo.png";
import Button from "@mui/material/Button";
import EdonLogo from "./edonLogoWhite.png";
import HeaderMenus from "./Components/HeaderMenus.js";
import FlexLogo from "./flexe_Logo.png";
import MessageSnackBar from "./Components/MessageSnackBar";
import CreateReplenishmentShipment from "./Components/CreateReplenishmentShipment";
import ViewReplenishmentOrders from "./Components/ViewReplenishmentOrders.js";
import ViewReplenishmentShipments from "./Components/ViewReplenishmentShipments.js";
import Copyright from "./Components/Copyright.js";
import Unauthorized from "./Components/Unauthorized.js";

import AdvanceShippingNoticeGrid from "./Components/AdvanceShippingNoticeGrid.js";
import ReceiptInboundShipments from "./Components/ReceiptInboundShipments.js";



// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function Replenishment(props) {
  const pageTitle = "Replenishment";
  const user = props.user;

  //SECTION Message Bar Controls
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [messageSeverity, setMessageSeverity] = React.useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackBar = (message, severity) => {
    setSnackBarMessage(message);
    setMessageSeverity(severity);
    setSnackbarOpen(true);
  };

  //!SECTION Message Bar Controls

  //SECTION View Replenishment Orders

  const [viewReplenishmentOrders, setViewReplenishmentOrders] =
    React.useState(false);
  const [viewReplenishmentOrdersMessage, setViewReplenishmentOrdersMessage] =
    React.useState("View Replenishment Orders");

  const toggleReplenishmentOrders = () => {
    if (viewReplenishmentOrders) {
      setViewReplenishmentOrders(false);
      setViewReplenishmentOrdersMessage("View Replenishment Orders");
      // setViewReplenishmentOrdersTable(false); // view the
      // setDisableReplenishmentOrdersButton(false);
    } else {
      setViewReplenishmentOrders(true);
      setViewReplenishmentOrdersMessage("Hide Replenishment Orders");
      getReplenishmentOrders();
    }
  };

  const [replenishmentOrdersData, setReplenishmentOrdersData] = React.useState(
    []
  );

  const getReplenishmentOrders = () => {
    const owner = "edon_owner";
    const user = "glenn.caudill@edonconnect.com";
    // console.log("Thanks")
    //! redo this with the new database and API
    // axios
    //   .get(`/iot/replenishmentOrders/${owner}/${user}`)
    //   .then(function async(response) {
    //     // handle success
    //     // setAccessGridData(response.data);

    //     setReplenishmentOrdersData(response.data.data);
    //     // console.log("replenishment orders:", response.data.data);
    //     // toggleAccessTable();
    //     // setDisableAccessButton(true);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //     //!Message bar
    //     var errorMessage = `Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`;
    //     handleSnackBar(errorMessage, "error"); // error: red, warning: orange, information: blue, success: green
    //   })
    //   .then(function () {
    //     // always executed
    //   });
  };

  const handleSelectReplenishmentOrder = (replenishmentOrderId) => {
    // console.log(replenishmentOrderId);
    getReplenishmentOrderDetail(replenishmentOrderId);
  };

  const [replenishmentOrderDetail, setReplenishmentOrderDetail] =
    React.useState([]);

  const getReplenishmentOrderDetail = (replenishmentOrderId) => {
    const owner = "edon_owner";
    const user = "glenn.caudill@edonconnect.com";
    // console.log("Thanks")
    //! redo this with the new database and API
    // axios
    //   .get(`/iot/replenishmentOrder/${owner}/${user}/${replenishmentOrderId}`)
    //   .then(function async(response) {
    //     // handle success
    //     // setAccessGridData(response.data);
    //     // console.log("replenishment order Detail", response.data.data);
    //     setReplenishmentOrderDetail(response.data.data);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //     //!Message bar
    //     var errorMessage = `Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`;
    //     handleSnackBar(errorMessage, "error"); // error: red, warning: orange, information: blue, success: green
    //   })
    //   .then(function () {
    //     // always executed
    //   });
  };

  //!SECTION View Replenishment Orders

  //SECTION Create Replenishent Shipments
  //!  Create Replenishment Shipments

  // ! get the Replenishment orders list
  // ! get the Replenishment orders list
  // ! get the Replenishment orders list
  const [replenishmentOrderList, setReplenishmentOrderList] = React.useState(
    []
  );
  const getReplenishmentOrdersList = () => {
    const owner = "edon_owner";
    const replenishmentLocation = "SE_DC";
    //!redo this with the new database and API
    // axios
    //   .get(
    //     `/iot/replenishmentOrderNumbersList/${owner}/${user}/${replenishmentLocation}`
    //   )
    //   .then(function async(response) {
    //     // handle success
    //     // setAccessGridData(response.data);
    //     // console.log("replenishment orders:", response.data);
    //     setReplenishmentOrderList(response.data.data);
    //     // console.log("replenishment orders:", response.data.data);
    //     // toggleAccessTable();
    //     // setDisableAccessButton(true);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //     //!Message bar
    //     var errorMessage = `Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`;
    //     handleSnackBar(errorMessage, "error"); // error: red, warning: orange, information: blue, success: green
    //   })
    //   .then(function () {
    //     // always executed
    //   });
  };

  const handleCloseCreateShipments = () => {
    toggleCreateReplenishmentShipment();
  };

  const [viewCreateReplenishmentShipment, setViewCreateReplenishmentShipment] =
    React.useState(false);
  const [
    viewCreateReplenishmentShipmentMessage,
    setViewCreateReplenishmentShipmentMessage,
  ] = React.useState("Create Replenishment Shipment");
  const toggleCreateReplenishmentShipment = () => {
    if (viewCreateReplenishmentShipment) {
      setViewCreateReplenishmentShipment(false);
      setViewCreateReplenishmentShipmentMessage(
        "Create Replenishment Shipment"
      );
    } else {
      setViewCreateReplenishmentShipment(true);
      getReplenishmentOrdersList();
      setViewCreateReplenishmentShipmentMessage(
        "Hide Create Replenishment Shipment"
      );
    }
  };
  //!SECTION Create Replenishent Shipments

  //SECTION View Replenishment Shipments
  //! View Replenishment Shipments
  //! View Replenishment Shipments
  const [viewReplenishmentShipments, setViewReplenishmentShipments] =
    React.useState(false);
  const [
    viewReplenishmentShipmentsMessage,
    setViewReplenishmentShipmentsMessage,
  ] = React.useState("View Replenishment Shipments");

  const [shipmentData, setShipmentData] = React.useState([]);
  const [shipmentDetailData] = React.useState([]);

  const getReplenishmentShipmentData = () => {
    const owner = "edon_owner";
    //! redo this with the new database and API
    // axios
    //   .get(`/iot/replenishmentShipments/${owner}/${user.email}`)
    //   .then(function async(response) {
    //     // handle success
    //     // setAccessGridData(response.data);
    //     // console.log("replenishment orders:", response.data);
    //     setShipmentData(response.data.data);
    //     // console.log("replenishment orders:", response.data.data);
    //     // toggleAccessTable();
    //     // setDisableAccessButton(true);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     // console.log(error);
    //     //!Message bar
    //     var errorMessage = `Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`;
    //     handleSnackBar(errorMessage, "error"); // error: red, warning: orange, information: blue, success: green
    //   })
    //   .then(function () {
    //     // always executed
    //   });
  };

  const handleSelectReplenishmentShipment = (trackingNumber) => {
    // console.log(trackingNumber);
    getShipmentDetail(trackingNumber);
  };

  const getShipmentDetail = (trackingNumber) => {
    const owner = "edon_owner";
    //! redo this with the new database and API
    // axios
    //   .get(`/iot/inboundShipment/${owner}/${user.email}/${trackingNumber}`)
    //   .then(function async(response) {
    //     // handle success
    //     // setAccessGridData(response.data);
    //     // console.log("shipment Detail:", response.data);
    //     // setShipmentDetailData(response.data.data);
    //     // console.log("replenishment orders:", response.data.data);
    //     // toggleAccessTable();
    //     // setDisableAccessButton(true);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //     //!Message bar
    //     var errorMessage = `Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`;
    //     handleSnackBar(errorMessage, "error"); // error: red, warning: orange, information: blue, success: green
    //   })
    //   .then(function () {
    //     // always executed
    //   });
  };

  // const shipmentDetailTrialData = [
  //   {
  //     id: "1",
  //     productName: "Lithium Node Processor",
  //     productNum: "EC-LNP-12-TST",
  //     serialNum: "LNP001",
  //     status: "Transit Market",
  //   },
  // ];

  const toggleReplenishmentShipments = () => {
    if (viewReplenishmentShipments) {
      setViewReplenishmentShipments(false);
      setViewReplenishmentShipmentsMessage("View Replenishment Shipments");
      // setViewAccessTable(false);
      // setDisableAccessButton(false);
    } else {
      setViewReplenishmentShipments(true);
      setViewReplenishmentShipmentsMessage("Hide Replenishment Shipments");
      getReplenishmentShipmentData();
    }
  };

  //!SECTION View Replenishment Shipments

 //SECTION Advanced Shipping notice

 const [viewAdvanceShippingNotice, setViewAdvanceShippingNotice] =
 React.useState(false);
const [
 viewAdvanceShippingNoticeMessage,
 setviewAdvanceShippingNoticeMessage,
] = React.useState("Advance Shipping Notice");
const [advanceShippingNoticeData, setAdvanceShippingNoticeData] =
 React.useState([]);
const toggleAdvanceShippingNotice = () => {
 if (viewAdvanceShippingNotice) {
   setViewAdvanceShippingNotice(false);
   setviewAdvanceShippingNoticeMessage("Advance Shipping Notice");
   // setViewReplenishmentOrdersTable(false); // view the
   // setDisableReplenishmentOrdersButton(false);
 } else {
   setViewAdvanceShippingNotice(true);
   setviewAdvanceShippingNoticeMessage("Hide Advance Shipping Notice");
   getAdvancedShippingNotice();
 }
};

const getAdvancedShippingNotice = () => {
 const owner = "edon_owner";
 const user = "glenn.caudill@edonconnect.com";
 const destinationId = "SB-ATL";
   //! Redo with new database and API
//  axios
//    .get(`/iot/advanceShippingNotice/${owner}/${user}/${destinationId}`)
//    .then(function async(response) {
//      // handle success
//      // setAccessGridData(response.data);

//      setAdvanceShippingNoticeData(response.data.data);
//      // console.log("Advance Shipping Notice:", response.data.data);
//    })
//    .catch(function (error) {
//      // handle error
//      console.log(error);
//      //!Message bar
//      var errorMessage = `Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`;
//      handleSnackBar(errorMessage, "error");
//    })
//    .then(function () {
//      // always executed
//    });
};
//!SECTION End Advanced Shipping notice

  //SECTION Receipt Inbound Replenishment Shipments
  const [viewReceiptInboundShipments, setViewReceiptInboundShipments] =
    React.useState(false);
  const [
    viewReceiptInboundShipmentsMessage,
    setViewReceiptInboundShipmentsMessage,
  ] = React.useState("Receipt Inbound Shipments");

  const toggleReceiptInboundShipments = () => {
    if (viewReceiptInboundShipments) {
      setViewReceiptInboundShipments(false);
      setViewReceiptInboundShipmentsMessage("Receipt Inbound Shipments");
      // setViewReplenishmentOrdersTable(false); // view the
      // setDisableReplenishmentOrdersButton(false);
    } else {
      setViewReceiptInboundShipments(true);
      setViewReceiptInboundShipmentsMessage("Hide Receipt Inbound Shipments");
    }
  };

  //!SECTION Receipt Inbound Replenishment Shipments





  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />

        {/* Content*/}
        {/* Content*/}
        {/* Content*/}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          <img src={EdonLogo} width={200} alt="Logo" />
          <Container
            overflow="auto"  //! Changed from scroll to auto 
            maxWidth="xl"
            sx={{ mt: 5, mb: 10, border: 1 }}
          >
            {props.user.membershipCode === "0x12a6b5324" && ( //Warehouse Anywhere membership
              <Grid item sx={{ p: 2 }}>
                <img src={WALogo} width={200} alt="Logo" />
              </Grid>
            )}
            {props.user.membershipCode === "0x4ee11bfe" && ( //Warehouse Anywhere membership
              <Grid item sx={{ p: 2 }}>
                <img src={FlexLogo} width={150} alt="Logo" />
              </Grid>
            )}

            <Grid
              container
              spacing={3}
              sx={{ alignContent: "center", border: 0, pb: 2 }}
            >
              <MessageSnackBar
                messageBarMessage={snackBarMessage}
                messageBarOpen={snackbarOpen}
                handleMessageBarClosing={handleClose}
                messageSeverity={messageSeverity}
              />
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ alignContent: "center", border: 0 }}
              >
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    border: 0,
                  }}
                >
                  <Typography align="center" variant={"h4"}>
                    {props.user.company} Replenishment
                  </Typography>
                  <Typography align="center" variant={"subtitle1"}>
                    {props.user.email}
                  </Typography>
              {!props.user.services.includes("RP") &&
              <Grid item md={12} lg={12} sx={{ alignContent: "center", p:2, border: 0 }}>
                <Unauthorized />
              </Grid>
              }
                </Paper>
              </Grid>
            </Grid>


            <Grid
              container
              spacing={3}
              sx={{ alignContent: "center", border: 0 }}
            >
              {/* Replenishment Orders */}
              {props.user.role > 5 && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                  >
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <Button onClick={toggleReplenishmentOrders}>
                        {" "}
                        {viewReplenishmentOrdersMessage}
                      </Button>

                      {/* View Replenishment Orders */}
                      {viewReplenishmentOrders && (
                        <ViewReplenishmentOrders
                          replenishmentOrdersData={replenishmentOrdersData}
                          replenishmentOrderDetail={replenishmentOrderDetail}
                          handleSelectReplenishmentOrder={
                            handleSelectReplenishmentOrder
                          }
                        />
                      )}
                    </Paper>
                  </Grid>
                </>
              )}
              {/*  Create Shipments */}
              {props.user.role > 5 && props.user.services.includes("RP") && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                  >
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <Button onClick={toggleCreateReplenishmentShipment}>
                        {" "}
                        {viewCreateReplenishmentShipmentMessage}
                      </Button>

                      {/* Item Assignment Filters */}

                      {viewCreateReplenishmentShipment && (
                        <CreateReplenishmentShipment
                          getReplenishmentOrdersList={
                            getReplenishmentOrdersList
                          }
                          handleCloseCreateShipments={
                            handleCloseCreateShipments
                          }
                          replenishmentOrderList={replenishmentOrderList}
                          handleSnackBar={handleSnackBar}
                        />
                      )}
                    </Paper>
                  </Grid>
                </>
              )}

              <>
                {/* View Replenishment Shipment */}
                {props.user.role > 5 && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                  >
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <Button onClick={toggleReplenishmentShipments}>
                        {" "}
                        {viewReplenishmentShipmentsMessage}
                      </Button>

                      {/* Access Filters */}
                      {viewReplenishmentShipments && (
                        <ViewReplenishmentShipments
                          shipmentData={shipmentData}
                          shipmentDetailData={shipmentDetailData}
                          handleSelectReplenishmentShipment={
                            handleSelectReplenishmentShipment
                          }
                        />
                      )}
                    </Paper>
                  </Grid>

                

                )}
              {/* Advance Shipping Notice */}
              {props.user.role > 5 && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                  >
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <Button onClick={toggleAdvanceShippingNotice}>
                        {" "}
                        {viewAdvanceShippingNoticeMessage}
                      </Button>
                      {viewAdvanceShippingNotice && (
                        <>
                          <AdvanceShippingNoticeGrid
                            gridData={advanceShippingNoticeData}
                          />
                        </>
                      )}
                    </Paper>
                  </Grid>
                </>
              )}
              </>
              {/* Inbound Shipment Receipt */}
              {props.user.role > 5 && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                  >
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <Button onClick={toggleReceiptInboundShipments}>
                        {" "}
                        {viewReceiptInboundShipmentsMessage}
                      </Button>
                      {viewReceiptInboundShipments && (
                        <>
                          <ReceiptInboundShipments
                            handleSnackBar={handleSnackBar}
                          />
                        </>
                      )}
                    </Paper>
                  </Grid>
                </>
              )}



            </Grid>
            


            <Grid item border={0} xs={12} sm={12} md={12}>
              <Copyright sx={{ pt: 4, align: "center" }} />
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
