import * as React from 'react';
import axios from 'axios';
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
// import { DataGridPro } from '@mui/x-data-grid-pro';
import MessageSnackBar from './MessageSnackBar';

import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function UserDirtyFilesGrid(props) {

    //SECTION Message Bar Controls
    const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
    const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
    const [ messageSeverity, setMessageSeverity ] = React.useState('success')
    const handleMessageBarClosing = () => {
      setMessageBarOpen(false);
    };
    //!SECTION
    
    const [selectedModel, setSelectedModel ] = React.useState();
    //SECTION Axios Delete Dirty File
    const deleteFile = React.useCallback( (sentID) => async () => {
      const user = props.user.email;
      const index = props.userDirtyFileUploadInfo.map(file => file.id).indexOf(sentID)
      // console.log(props.userDirtyFileUploadInfo)
      // console.log("user: ", user)
      // console.log("id: ", sentID)
      // console.log("index: ", index)
      const filename =  props.userDirtyFileUploadInfo[index].fileName

      await axios.delete(`/deletesuperdirtyfile/${filename}/${user}`)
      .then(function (response) {
        // handle success
        alert(response.data)
        props.toggleUploadedFiles();
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        //!Message bar
        setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      })
      .then(function () {
        // always executed
      });

    },
    [props], );
    //!SECTION


    const  UserDirtyFileNames  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'fileName', headerName: 'File Name', width:200, editable: false},
      {field: 'memberCompany', headerName: 'Member Company', hide:(props.user.role<10), width:200, editable: false},
      {field: 'accountCompany', headerName: 'Account Company', hide:(props.user.role<8),width:200, editable: false},
      {field: 'actions', type: 'actions', hide:(props.user.role<6), headerName: 'Delete', width:75,  
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} onClick={deleteFile(params.id)}  label="Delete" />
      ]},
      {field: 'cleaningStatus', headerName: 'Cleaned', width:125, editable: false, headerAlign: 'center', align:'center',},
      {field: 'blankFields', headerName: 'Blank Rows', width:125, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'badZipFormat', headerName: 'Bad Zip Format', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'badZipMatching', headerName: 'Bad Zip Matching', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'badGeos', headerName: 'Bad GEOs', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'nonTopMetros', headerName: 'Not In Metro', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'totalOrders', headerName: 'Total Orders', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'totalItemQuantity', headerName: 'Total Item Quantity', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'marketsBelowMinimum', headerName: 'Markets Below Minimum', width:200, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'marketCount', headerName: 'Number of Markets', width:175, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'numberOfInvalidDataType', headerName: 'Data Type Invalid', width:175, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      ], 
      rows: props.userDirtyFileUploadInfo
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
      <DataGrid
      initialState={{ pinnedColumns: { left: ['fileName'], right: ['actions'] } }}
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...UserDirtyFileNames} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};