import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems} from './listItems';
import EdonLogo from './edonLogoWhite.png'
import BarChart from './Components/BarChart.js'
import LineChart from './Components/LineChart.js'
import PieChart from './Components/PieChart.js'
import MetricsDefinitions from './img/MetricsDefinitions.png'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import HeaderMenus from './Components/HeaderMenus.js';
import Unauthorized from './Components/Unauthorized.js'



// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Edon Connect
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);




export default function PerformanceMetrics(props) {
  const pageTitle = 'Performance Metrics'
  const [open, setOpen] = React.useState(true);

  var data = [{'name': 'Aug 2022', 'value': '2660'}, {'name': 'Sep 2022', 'value': '2965'}, {'name': 'Nov 2022', 'value': '1321'}, {'name': 'Dec 2022', 'value': '796'}, {'name': 'Jan 2023', 'value': '673'}]
  var range = ['2660', '2965', '1321', '796', '673']

  const lineData = [
    {
      "name": "Jun 2022",
      "value": .75
    },
    {
      "name": "Jul 2022",
      "value": .78
    },
    {
      "name": "Aug 2022",
      "value": .72
    },
    {
      "name": "Sep 2022",
      "value": .82
    },
    {
      "name": "Nov 2022",
      "value": .91
    },
    {
      "name": "Dec 2022",
      "value": .94
    },
    {
      "name": "Jan 2023",
      "value": .95
    }
  ]
  
  const piedata01 = [
    {
      "name": "Success",
      "value": 75,
    },
    {
      "name": "Execution Failure",
      "value": 15,
    },
    {
      "name": "Planning Failure",
      "value": 10,
    }
  ];
  const colors01 = ['#548235', '#C55A11', '#2E75B6']
  const piedata02 = [
    {
      "name": "Utilization",
      "value": 40,
    },
    {
      "name": "Replenishment",
      "value": 20, 
    },
    {
      "name": "Plan Replenishment",
      "value": 20,
    },
    {
      "name": "Plan Inventory",
      "value": 8,
    },
    {
      "name": "Plan Location",
      "value": 12,
    }
  ];
  const colors02 = ['#C55A11', '#ED7D30', '#2F5597', '#9DC3E6', '#2E75B6']

  const toggleDrawer = () => {
    setOpen(!open);
  };


  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />


          {/* Content*/}
          {/* Content*/}
          {/* Content*/}

        <Container sx={{ pt: 8, pb:4 }} maxWidth="md">
          <img src={EdonLogo} width={200} alt="Logo" />

          {!props.user.services.includes("PM") &&
                  <Grid item md={12} lg={12} sx={{ alignContent: "center", p:2, border: 0 }}>
                    <Unauthorized />
                  </Grid>
                  }
          
          {/* End hero unit */}
          <Grid container paddingTop = {5} spacing={4}>

          <Grid item  xs={6} sm={6} md={6} sx={{border:1}}>
          <Box sx ={{display: "flex", justifyContent:'space-around'}} >
            <Typography variant="h6" gutterBottom center component="div">Service Level with Parts </Typography>
          </Box>
            <PieChart data={piedata01}  colors={colors01} range={range} />
          </Grid>
          <Grid item  xs={6} sm={6} md={6} sx={{border:1}}>
            <Box sx ={{display: "flex", justifyContent:'space-around'}} >
            <Typography variant="h6" gutterBottom>Service Failures with Parts </Typography>
            </Box>
            <PieChart data={piedata02}  colors={colors02}  range={range} />
          </Grid>


          <Grid item  xs={12} sm={12} md={12} sx={{border:1, p:5}}>
            <Box sx ={{display: "flex", justifyContent:'space-around'}} >
            <Typography variant="h6" gutterBottom>Service Failures with Parts </Typography>
            </Box>
            <LineChart data={lineData} range={range} />
          </Grid>
            
          <Grid item  xs={12} sm={12} md={12} sx={{border:1, p:5}}>
            <Box sx ={{display: "flex", justifyContent:'space-around'}} >
            <Typography variant="h6" gutterBottom>Service Orders</Typography>
            </Box>
            <BarChart data={data} range={range} />
          </Grid>

          <Grid item  xs={12} sm={12} md={12} sx={{border:1, p:5}}>
          <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      // 16:9
                      pt: '10%',
                      maxHeight: "100%"
                    }}
                    image={MetricsDefinitions}
                  />

                </Card>
          </Grid>

          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Copyright sx={{ pt: 4, align: "center" }} />
          </Grid>

        </Container>

          
        </Box>
      </Box>
    </ThemeProvider>
  );
}