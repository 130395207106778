import React from 'react';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';

import axios from 'axios';

import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

// import { createTheme} from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert';
import MessageSnackBar from './MessageSnackBar';




// const theme = createTheme();

// const darkTheme = createTheme({
//     palette: {
//       mode: 'dark',
//     },
//   });

export default function createMember(props) {

    //! Snackbar Message
    const [ snackBarMessage, setSnackBarMessage ] = React.useState('');
    const [ snackBarOpen, setSnackBarOpen ] = React.useState(false)
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackBarOpen(false);
      props.toggleViewCreateMember();
    };

    //SECTION Message Bar Controls
    const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
    const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
    const [ messageSeverity, setMessageSeverity ] = React.useState('success')
    const handleMessageBarClosing = () => {
      setMessageBarOpen(false);
    };
    //!SECTION


    //SECTION Form Validation
    //! Form Validation
    const [ buttonDisable, setButtonDisable ] = React.useState(true);
    const [ validFields, setValidFields ] = React.useState(
      {
        "companyName": false,
        "adminstratorEmail": false,

     });
    const [ helperText, setHelperText ] = React.useState(
      {
        "companyName":"",
        "adminstratorEmail":"",
       });
    const [ validName, setValidName ] = React.useState(false);
    const [ validEmail, setValidEmail ] = React.useState(false);
    const [ validType, setValidType ] = React.useState(false);
    // const forceUpdate = React.useCallback(() => setValidField(!validField),[]);

    const formValidation =  (e) => {
      e.preventDefault();
      // console.log(e.target.id);
      // console.log(e.target.value);
      switch (e.target.id){
        case 'companyName': 
          if (e.target.value.length > 4){
            setValidFields(prevState => ({
              ...prevState,
              "companyName": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                companyName: "",
                }));
              setValidName(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "companyName": true}));
              setHelperText(prevState => ({
                ...prevState,
                companyName: "Company Name needs at least five characters",
                }));
              setValidName(false)
          }
          break;

        case 'adminstratorEmail': 

          var email = e.target.value;
          // eslint-disable-next-line 
          var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          var emailValid = email.match(pattern);

          if (emailValid){

            setValidFields(prevState => ({
              ...prevState,
              "adminstratorEmail": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                adminstratorEmail: "",
                }));
              setValidEmail(true);
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "adminstratorEmail": true}));

              setHelperText(prevState => ({
                ...prevState,
                adminstratorEmail: "Need a valid email adress",
                }));
              setValidEmail(false);
          }
          break;
        case 'memberType': 
          if (e.target.value){
            setValidType(true)
          }
          break;
        default:
          break;
      }
    }
    React.useEffect(() => {

      if (validName && validEmail && validType) {
        setButtonDisable(false);
      }else{

        setButtonDisable(true);
      }
    },[validName, validEmail, validType]
    )
    //!SECTION
    

    //SECTION Axios Create a Member
    const handleCreateMember = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const companyName = formData.get('companyName');
        const adminstratorEmail = formData.get('adminstratorEmail');
        const memberType = formData.get('memberType');
        const creatorEmail = props.user.email;
        // console.log("memberdata", companyName, adminstratorEmail, memberType);
        // console.log("userdata", creatorEmail);
        const headers = props.headers
        var data = JSON.stringify({
            "companyName": companyName,
            "adminstratorEmail": adminstratorEmail,
            "creatorEmail": creatorEmail,
            "type": memberType,
          });
        await axios({
            method: 'post',
            url: `adminstration/addmember/`,
            data: data, 
            headers 
          })
          .then(function (response) {
            // handle success
            // console.log(response.data);
            setSnackBarMessage(response.data)
            setSnackBarOpen(true)

          })
          .catch(function (error) {
            // handle error
            console.log(error);
            //!Message bar
            setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          })
          .then(function () {
            // always executed
          });
    };
    //!SECTION

  return (
    <Grid container marginTop={5} sx={{justifyContent:'center', alignContent:'center', p:5, border:1, borderStyle: 'dotted', borderRadius: '5px'  }}>
        <Stack direction="column" alignItems="center" spacing={2}>
          <Typography component="h1" variant="h5">
            Create a New Membership
          </Typography>


    <Box component="form" onSubmit={handleCreateMember} noValidate sx={{ mt: 1 }}>  
      <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />  

        <Snackbar
                anchorOrigin = {{ horizontal: 'center', vertical: 'top'}}
                open={snackBarOpen}
                autoHideDuration={3000}
                onClose={handleClose}
              >
                <MuiAlert elevation={6} variant="filled" severity="success">{snackBarMessage}</MuiAlert>
          </Snackbar>

            <TextField
              error = {validFields.companyName}
              helperText={helperText.companyName}
              margin="normal"
              required
              fullWidth
              name="companyName"
              label="Company Name"
              type="text"
              id="companyName"
              onChange={(e) => formValidation(e)}
            />
            <TextField
              error = {validFields.adminstratorEmail}
              helperText={helperText.adminstratorEmail}
              margin="normal"
              required
              fullWidth
              name="adminstratorEmail"
              label="Adminstrator Email"
              type="text"
              id="adminstratorEmail"
              onChange={(e) => formValidation(e)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="memberType"
              label="Member Type"
              type="text"
              id="memberType"
              onChange={(e) => formValidation(e)}
            />

            <Button
              align="center"
              type="submit"
              fullWidth
              variant="contained"
              disabled={buttonDisable}
              sx={{ mt: 3, mb: 2 }} 
            >
              Create New Member
            </Button>

          </Box>
          </Stack>
        </Grid>

  );
}