import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';


import axios from 'axios';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material//MenuItem';
// import { createTheme} from '@mui/material/styles';





export default function MobileUser(props) {

  const title = "Create A Product"
  //&Account DropDown
  //&Account DropDown
  //&Account DropDown
  //! Data and Select for Account Dropdown
  const [selectedAccount, setSelectedAccount] = React.useState('');
  //! accountList comes from Dashboard
  const handleAccountSelect = (event) => {
    setSelectedAccount(event.target.value);
  };



   //SECTION Form Validation
    //! Form Validation
    const [ buttonDisable, setButtonDisable ] = React.useState(true);


    const [ validFields, setValidFields ] = React.useState(
      {
        "productNum": false,
        "barcode": false,
        "productName": false,
        "itemCost": false,
        "size": false,
        "weight": false,
        "manufacture": false,
     });

    const [ helperText, setHelperText ] = React.useState(
      {
        "productNum":"Product Number",
        "productName":"Product Name",
        "barcode":"Barcode",
        "itemCost":"Item Cost",
        "size":"Size",
        "weight":"Weight",
        "manufacture":"Manufacture"
       });


    const [ validProductNum, setValidProductNum ] = React.useState(false);
    const [ validBarcode, setValidBarcode ] = React.useState(false);
    const [ validProductName, setValidProductName ] = React.useState(false);
    const [ validItemCost, setValidItemCost ] = React.useState(false);
    const [ validSize, setValidSize ] = React.useState(false);
    const [ validWeight, setValidWeight ] = React.useState(false);
    const [ validManufacture, setValidManufacture ] = React.useState(false);
    //!erase
    // const [ validWarehouseName, setValidWarehouseName ] = React.useState(false);
    // const [ validWarehouseAddress, setValidWarehouseAddress ] = React.useState(false);
    // const [ validWarehouseCity, setValidWarehouseCity ] = React.useState(false);
    // const [ validWarehouseState, setValidWarehouseState ] = React.useState(false);
    // const [ validWarehouseZip, setValidWarehouseZip ] = React.useState(false);
    // const [ validWarehouseType, setValidWarehouseType ] = React.useState(false);
    // const [ validWarehouseDescription, setValidWarehouseDescription ] = React.useState(false);

    React.useEffect(() => {
        if (validProductName, validBarcode, validProductNum, validItemCost, validSize, validWeight, validManufacture) {
          // console.log("validProductName", validProductName)
          // console.log("validProductNum", validProductNum)
          // console.log("validItemCost", validItemCost)
          // console.log("validSize", validSize)
          // console.log("validWeight", validWeight)
          // console.log("validManufacture", validManufacture)
          // console.log("validBarcode", validBarcode)
          // console.log("All Fields Valid");
          setButtonDisable(false);
        }else{
          setButtonDisable(true);
        }
      },[ validProductName, validBarcode, validProductNum, validItemCost, validSize, validWeight, validManufacture]
      )
 

    const formValidation =  (e) => {
      e.preventDefault();
      // console.log(e.target.id);
      // console.log(e.target.value);
      switch (e.target.id){

        case 'productNum': 
          var productNum = e.target.value;
          // eslint-disable-next-line 
          if (productNum.length > 5){
            setValidFields(prevState => ({
                ...prevState,
                "productNum": false,
                }));
            setHelperText(prevState => ({
            ...prevState,
            productNum: "Product Number",
            }));
            setValidProductNum(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "productNum": true}));

              setHelperText(prevState => ({
                ...prevState,
                productNum: "Product Number needs at least six characters",
                }));
              setValidProductNum(false);
          }
          break;

        case 'barcode': 
          var barCode = e.target.value;
          // eslint-disable-next-line 
          if (barCode.length > 5){
            setValidFields(prevState => ({
                ...prevState,
                "barcode": false,
                }));
            setHelperText(prevState => ({
            ...prevState,
            barCode: "Barcode",
            }));
            setValidBarcode(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                barcode: true}));

              setHelperText(prevState => ({
                ...prevState,
                barcode: "Barcode needs at least six characters",
                }));
              setValidBarcode(false);
          }
          break;

        case 'productName':
          var productName = e.target.value;
          // eslint-disable-next-line 
          if (productName.length > 5){
            setValidFields(prevState => ({
                ...prevState,
                "productName": false,
                }));
            setHelperText(prevState => ({
            ...prevState,
            productName: "Product Name",
            }));
            setValidProductName(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "productName": true}));

              setHelperText(prevState => ({
                ...prevState,
                productName: "Product Name needs at least six characters",
                }));
              setValidProductName(false);
          }
          break;

        case 'itemCost':           
            var itemCost = e.target.value;
            // eslint-disable-next-line
            if (itemCost.length > 2){
                setValidFields(prevState => ({
                    ...prevState,
                    "itemCost": false, 
                    }));
                setHelperText(prevState => ({
                ...prevState,
                itemCost: "Item Cost",
                }));
                setValidItemCost(true);
            }else{
                setValidFields(prevState => ({
                    ...prevState,
                    "itemCost": true}));
                setHelperText(prevState => ({
                    ...prevState,
                    itemCost: "Cost needs at least five characters",
                    }));
                setValidItemCost(false);
            }
            break;

        case 'size':
            var size = e.target.value;
            // eslint-disable-next-line
            if (size.length > 1){
                setValidFields(prevState => ({
                    ...prevState,
                    "size": false,
                    }));
                setHelperText(prevState => ({
                ...prevState,
                size: "Size",
                }));
                setValidSize(true);
            }else{
                setValidFields(prevState => ({
                    ...prevState,
                    "size": true}));
                setHelperText(prevState => ({
                    ...prevState,
                    size: "Size needs at least two characters",
                    }));
                setValidSize(false);
            }
            break;

        case 'weight':
            var weight = e.target.value;
            // eslint-disable-next-line
            if (weight.length > 0){
                setValidFields(prevState => ({
                    ...prevState,
                    "weight": false,
                    }));
                setHelperText(prevState => ({
                ...prevState,
                weight: "Product Weight",
                }));
                setValidWeight(true);
            }else{
                setValidFields(prevState => ({
                    ...prevState,
                    "weight": true}));
                setHelperText(prevState => ({
                    ...prevState,
                    weight: "Weight needs at least five characters",
                    }));
                setValidWeight(false);
            }
            break;

        case 'manufacture':
            var manufacture = e.target.value;

            if (manufacture.length > 3){
                setValidFields(prevState => ({
                    ...prevState,
                    "manufacture": false,
                    }));
                setHelperText(prevState => ({
                ...prevState,
                manufacture: "Product Manufacture",
                }));
                setValidManufacture(true);
            }else{
                setValidFields(prevState => ({
                    ...prevState,
                    "manufacture": true}));
                setHelperText(prevState => ({
                    ...prevState,
                    manufacture: "Manufacture needs at least six character",
                    }));
                setValidManufacture(false);
            }
            break;
        default:
          break;
      }
    }


    //!SECTION
    


    //SECTION Axios Create Product
  const handleCreateProduct = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    var assignAccount = formData.get("assignAccount");
    var productNum = formData.get("productNum");
    var barcode = formData.get("barcode");
    var productName = formData.get("productName");
    var itemCost = formData.get("itemCost");
    var size = formData.get("size");
    var weight = formData.get("weight");
    var manufacture = formData.get("manufacture");

    if (assignAccount === "" || assignAccount === null || assignAccount === undefined){
      assignAccount = props.user.accountCode;
    }

    const headers = props.headers;

    var data = {
        accountCode: assignAccount, 
        productNum: productNum,
        barcode: barcode,
        productName: productName,
        itemCost: itemCost,
        size: size,
        weight: weight,
        manufacture: manufacture
    };


    await axios(
      {
      method: "post",
      url: `/adminstration/createproductinfo/`,
      data: data,
      headers: headers
      }
      )
      .then(function (response) {
        // console.log("response", response.data);
        props.handleMessageBarOpen(response.data.message, "success");
        props.toggleViewCreateProduct();
      })
      .catch(function (error) {
        // handle error
        // console.log(error.response.data.detail);
        props.handleMessageBarOpen(error.response.data.detail, "error");
      })
  };
  //!SECTION


  return (
    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 5,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      {/* <Stack direction="column" alignItems="center" spacing={2}> */}
        <Typography component="h1" variant="h5">
          {title}
        </Typography>

        <Box
          component="form"
          onSubmit={handleCreateProduct}
          noValidate
          overflow="auto"
          sx={{ mt: 1 }}
        >
          {props.user.role ==10 &&
          <TextField
            margin="normal"
            select
            required
            fullWidth
            name="assignAccount"
            label="Account"
            value={selectedAccount}
            onChange={handleAccountSelect}
            type="number"
            id="assignAccount"
            helperText="Select an Account"
          >
            {props.accountList.map((option) => (
              <MenuItem
                sx={{ whiteSpace: "normal" }}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          }

          <TextField
            error = {validFields.productNum}
            helperText={helperText.productNum}
            margin="normal"
            required
            fullWidth
            name="productNum"
            label="ex. 324-234-234"
            type="text"
            id="productNum"
            onChange={(e) => formValidation(e)}
          />
          <TextField
            error = {validFields.barcode}
            helperText={helperText.barcode}
            margin="normal"
            required
            fullWidth
            name="barcode"
            label="ex. PN4056X"
            type="text"
            id="barcode"
            onChange={(e) => formValidation(e)}
          />
          <TextField  
            error = {validFields.productName}
            helperText={helperText.productName}
            margin="normal"
            required
            fullWidth
            name="productName"
            label="ex. Optical Scanner 202"
            type="text"
            id="productName"
            onChange={(e) => formValidation(e)}
          />
          <TextField
            error = {validFields.itemCost}
            helperText={helperText.itemCost}
            margin="normal"
            required
            fullWidth
            name="itemCost"
            label="cents ex. 1200 is $12.00"
            type="text"
            id="itemCost"
            onChange={(e) => formValidation(e)}
          />
          <TextField
            error = {validFields.size}
            helperText={helperText.size}
            margin="normal"
            required
            fullWidth
            name="size"
            label="Square inches with no decimals: ex. 12"
            type="text"
            id="size"
            onChange={(e) => formValidation(e)}
          />
          <TextField
            error = {validFields.weight}
            helperText={helperText.weight}
            margin="normal"
            required
            fullWidth
            name="weight"
            label="Lbs with no decimals: ex. 12"
            type="text"
            id="weight"
            onChange={(e) => formValidation(e)}
          />
          <TextField
            error = {validFields.manufacture}
            helperText={helperText.manufacture}
            margin="normal"
            required
            fullWidth
            name="manufacture"
            label="ex. Dell"
            type="text"
            id="manufacture"
            onChange={(e) => formValidation(e)}
          />

          

          <Button
            disabled={buttonDisable}
            align="center"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Create Product
          </Button>
          
          {/* <FormControlLabel
            control={<Checkbox defaultChecked onChange={handleEmailCheckbox} />}
            label="Send Mobile User Notification Email"
          /> */}
        </Box>
      {/* </Stack> */}
    </Grid>
  );
}