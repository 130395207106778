import * as React from 'react';
import axios from 'axios';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EdonWidget from './img/edon Connect Logo only.png'

//! signing on

import UserContext from "./context/UserContext";

import Copyright from './Components/Copyright';



const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

export default function SignIn(props) {


  const { onLogin } = React.useContext(UserContext);
  const { onLogout } = React.useContext(UserContext);
  const [ viewForgotPassword, setViewForgotPassword ] = React.useState(false);
  const [ viewSignIn, setViewSignIn ] = React.useState(true);
  const [ forgotPasswordMessage, setForgotPasswordMessage ] =React.useState('Forgot Password?');

  // const SentryTest = () => {
  //   throw new Error("Sentry Test Error");
  //   console.log(forgotPasswordMessage[0])
  // };

  
  const handleSignIn = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log(data.get('email'));
    // console.log(data.get('password'));
    const email = data.get('email')
    const password = data.get('password')
    // console.log("Email", email)
    onLogin(email, password);
    // eslint-disable-next-line no-console
  };

  const handleForgotPassword = () => {
    if(!viewForgotPassword){
      setForgotPasswordMessage('Show Sign In');
      setViewForgotPassword(true);
      setViewSignIn(false);
    }else{
      setForgotPasswordMessage('Forgot Password?');
      setViewForgotPassword(false);
      setViewSignIn(true);
    }
  }
  
  const handleSendPasswordResetEmail= (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    resetPasswordEmail(data.get('resetemail'))
  }

  const resetPasswordEmail = async (email) => {
    // alert(`Reset email sent to: ${email}`)
    // resetPasswordFlag(email); //! signal database that the password was changed
    // console.log("Reset Password", email)
    const data = {
      "requestingUser": email
    }
    
    await axios.post(`notifications/requestpasswordresetemail`, data)
    .then(function(response){
      alert(`Reset email was sent to: ${email}`)
      // console.log(response.data.message);
      setViewForgotPassword(false);
      setViewSignIn(true);
    })
    .catch(function(error){
      if (error.response.status === 401){
        alert ("Your session has expired. Please login again.");
        onLogout();
      };
      alert(`Could not send reset email to: ${email}, ${error.response.detail}`)
    })
  }


  return (
    <ThemeProvider theme={darkTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border:0
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: '#68a0d7' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <img src={EdonWidget} width={50} margin={40} alt="Logo" />
          <Typography component="h1" marginTop={2} variant="h5">
            Sign in
          </Typography>

          {viewSignIn &&
          <Box component="form" onSubmit={handleSignIn} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button
              align="center"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }} 
            >
              Sign In
            </Button>
          </Box>


          }

          </Box>


        <Grid container sx={{border:0}}>
            {true &&
            <Button
              align="center"
              onClick={handleForgotPassword}
              fullWidth
              variant="text"
              sx={{ mt: 3, mb: 2 }} 
            >
              {forgotPasswordMessage}
            </Button>
            }
                
          {viewForgotPassword &&
          <Box component="form" onSubmit={handleSendPasswordResetEmail} noValidate sx={{ mt:1, width:1, alignItems: 'center', border:0 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="resetemail"
              label="Email Address"
              name="resetemail"
              autoComplete="email"
              autoFocus
            />
            <Button
              align="center"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }} >
            Reset My Password
            </Button>
          </Box>
          }
        </Grid>


        <Copyright sx={{ mt: 8, mb: 4 }} />

        {/* <Button
          onClick={SentryTest}
          >
            Sentry Test Button
        </Button> */}

      </Container>
    </ThemeProvider>
  );
}