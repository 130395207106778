import * as React from 'react';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import WALogo from './WA_logo.png'
import Button from '@mui/material/Button';
import EdonLogo from './edonLogoWhite.png';
import { useStorageState } from "react-storage-hooks";
import AccountDropDown from './Components/AccountDropDown.js'
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import UserContext from "./context/UserContext";

import RotateRightIcon from '@mui/icons-material/RotateRight';
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import DropDownButtonless from "./Components/DropDownButtonless.js";


import HeaderMenus from './Components/HeaderMenus.js';


import FlexLogo from './flexe_Logo.png';
import MessageSnackBar from './Components/MessageSnackBar';

import SimpleGrid from './Components/SimpleGrid.js';
import SimpleGridOutbound from './Components/SimpleGridOutbound.js';
import SimpleGridShippedItems from './Components/SimpleGridShippedItems.js';
import Copyright from './Components/Copyright.js';
import Unauthorized from './Components/Unauthorized.js';

import { set } from 'firebase/database';



// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});



export default function InventoryManagement(props) {

  // React.useEffect(() => {
  //   processOperationsEvents(); //This processes the inventory events and 
  // }, [props]);
  const { onLogout } = React.useContext(UserContext);

  // const processOperationsEvents = () => {
  //   // var rowlimit = 5000;
  //   var user = props.user.email;
  //   const headers = props.headers;
  //   axios
  //     .get(
  //       `/EventProcessing/processinventoryevent`,
  //       {headers: headers}
  //     );
  //   axios
  //     .get(
  //       `/EventProcessing/countcyclecountsdiscrepancies`,
  //       {headers: headers}
  //     );
  //   axios
  //     .get(
  //       `/EventProcessing/processInbound`,
  //       {headers: headers}
  //     );
  //   axios
  //     .get(
  //       `/EventProcessing/processOutbound`,
  //       {headers: headers}
  //     );
  // };

  const pageTitle = 'Inventory Management';

    //SECTION Message Bar Controls
    const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
    const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
    const [ messageSeverity, setMessageSeverity ] = React.useState('success')
    const handleMessageBarClosing = () => {
      setMessageBarOpen(false);
    };
    //!SECTION

    //SECTION Select Account
    const [ currentInventoryAccount, setCurrentInventoryAccount ] = useStorageState(localStorage, "CurrentInventoryAccount", props.user.company);
    const [ currentInventoryAccountCode, setCurrentInventoryAccountCode ] = useStorageState(localStorage, "CurrentInventoryAccountCode", props.user.accountCode);

    const [ accountName, setAccountName ] = React.useState(props.user.company);
    const [ accountCode, setAccountCode ] = React.useState(props.user.accountCode);
    const [ viewAccounts, setViewAccounts ] = React.useState(false);
    const [ viewAccountsMessage, setAccountsMessage ] = React.useState('Select Account');

    const toggleAccounts = () => {
        if (viewAccounts) {
            setViewAccounts(false);
            setAccountsMessage("View Accounts");
            // setViewFileDeleteButton(false);
        } else {
            setViewAccounts(true);
            setAccountsMessage("Hide Accounts");
            // getAccountList();
        }
    };
    //handler to send down
      const handleSelectedAccount = async (accountname, accountcode, membershipCode) => {
        setAccountName(accountname); 
        setAccountCode(accountcode);
        setCurrentInventoryAccount(accountname)
        setCurrentInventoryAccountCode(accountcode)
      };
    //!SECTION


//SECTION  View Current Inventory 
  const [ viewInventory, setViewInventory ] = React.useState(false);
  const [ viewInventoryMessage, setViewInventoryMessage ] = React.useState('View Current Inventory');
  const [ warehouseList, setWarehouseList ] = React.useState([]);
    //Variables to send down
  const warehouseTitles = {
    dropDownTitle: "Select Warehouse",
    dropDownLabel: "Select Warehouse",
    dropDownHelperText: "",
    dropDownAlertMessage: "Inventory for  " ,
  };
  const plecibo = () => {
  };

  const toggleInventory = () => {
      if (viewInventory){
          setViewInventory(false);
          setViewInventoryMessage('View Current Inventory');
          // setViewInventoryTable(false); // view the  
          // setDisableInventoryButton(false);
      }else{
          getWarehouseList(props.user.email)
          setViewInventory(true);
          setViewInventoryMessage('Hide Current Inventory');
          // handleGetInventory(props.user.email, accountCode,"All");
      };
  };

  const handleSelectedWarehouseInventory = async (selection) => {
    handleGetInventory(props.user.email, accountCode,selection);
  };

  const getWarehouseList = async (user) => {
    const headers = props.headers;
    var parameters = { accountcode: currentInventoryAccountCode }
    await axios
    .get(
      `/OperationsInventory/validwarehouselocations`,
      { headers: headers, params: parameters }
      )
      .then(function async(response) {
        setWarehouseList(response.data.message)
        })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
          onLogout();
        }
        if (error.response.status != 401) {
          setMessageBarMessage(`${error.response.data.detail}  You can add a warehouse on the Dashboard page.`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
  };


      //! State variables for Inventory
      //! This is sent down to the Grid Component
  const [ inventoryGridColumns, setInventoryGridColumns ] = React.useState([
      {field: 'id', hide: true},
      {field: 'warehouseName', headerName: 'Warehouse', width: 200, editable: false, headerAlign: 'center', align:'center'},
      {field: 'serialNum', headerName: 'Serial Number', width: 150, editable: false, headerAlign: 'center', align:'center', hide: true},
      {field: 'itemNumber', headerName: 'Item Number', width: 150, editable: false, headerAlign: 'center', align:'center'},
      // {field: 'barcode', headerName: 'Barcode', width: 150, editable: false, headerAlign: 'center', align:'center'},
      {field: 'productName', headerName: 'Item Name', width: 400, editable: false, headerAlign: 'center', align:'center'},
      {field: 'shelfNum', headerName: 'Shelf', width: 150, editable: false, headerAlign: 'center', align:'center'},
      {field: 'orderStatus', headerName: 'Inventory Status', width: 150, editable: false, headerAlign: 'center', align:'center'},
      {field: 'discrepancyStatus', headerName: 'Discrepancy Status', width: 200, editable: false, headerAlign: 'center', align:'center'},
      {field: 'arrivedAt', headerName: 'Arrival Date', width:200, editable: false, headerAlign: 'center', align:'center'},
      // {field: 'statusUpdatedAt', headerName: 'Updated Date', width:200, editable: false, headerAlign: 'center', align:'center'},
      ])
  const [ inventoryGridData, setInventoryGridData ] = React.useState([])


  // const [ disableInventoryButton, setDisableInventoryButton ] = React.useState(false);
  const [ selectedWarehouse, setSelectedWarehouse ] = React.useState('All');


  const handleGetInventory = (user, account, warehouse) => {
    // console.log("handleGetInventory", user, account, warehouse)
    const headers = props.headers;
    var parameters = { accountcode: account}
    axios
      .get(
        `/OperationsInventory/currentInventory/${warehouse}`,
        { headers: headers, params: parameters }
      )
      .then(function async(response) {
        // handle success
        setInventoryGridData(response.data.message);
        // console.log("Current Inventory:", response.data.message);
        // toggleInventoryTable();
        // setDisableInventoryButton(true);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
          onLogout();
        }
        if (error.response.status != 401) {
          setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
  };
  //!SECTION View Current Inventory



//SECTION  View Inbound Inventory
  const [ viewInbound, setViewInbound ] = React.useState(false);
  const [ viewInboundMessage, setViewInboundMessage ] = React.useState('View Inbound Inventory');

  const toggleInbound = () => {
      if (viewInbound){
          setViewInbound(false);
          setViewInboundMessage('View Inbound Inventory');
          // setDisableInventoryButton(false);
      }else{
          getWarehouseList(props.user.email)
          setViewInbound(true);
          setViewInboundMessage('Hide Inbound Inventory');
          // handleGetInbound();
      };
  };

  const handleSelectedWarehouseInbound = async (selection) => {
    // console.log("handleSelectedWarehouseInbound", selection)
    handleGetInbound(props.user.email, currentInventoryAccountCode,selection);
  };


  const [ inboundGridColumns, setInboundGridColumns ] = React.useState([
      {field: 'id', hide: true},
      {field: 'locationName', headerName: 'Warehouse', width: 200, editable: false},
      {field: 'productName', headerName: 'Item Name', width: 200, editable: false},
      {field: 'productId', headerName: 'Item Number', width: 200, editable: false},
      {field: 'serialNum', headerName: 'Serial Number', width: 200, editable: false, hide: true},
      {field: 'shelfNum', headerName: 'Shelf', width: 200, editable: false},
      {field: 'CreatedBy', headerName: 'Received By', width: 250, editable: false},
      {field: 'arrivedAt', headerName: 'Arrival Date', width: 200, editable: false},
      ])
  const [ inboundGridData, setInboundGridData ] = React.useState([])

  // const [ disableInboundButton, setDisableInboundButton ] = React.useState(false);


  const handleGetInbound = (user, currentInventoryAccountCode,selection) => {
    var warehouse = selection;
    // console.log("handleGetInbound", user, currentInventoryAccountCode, selection);
    const headers = props.headers;
    var parameters = { accountcode: currentInventoryAccountCode};

    axios
      .get(
        `/OperationsInventory/inbound/${warehouse}`,
        { headers: headers, params: parameters }
      )
      .then(function async(response) {
        // console.log("Inbound Inventory:", response.data.message);
        // handle success
        setInboundGridData(response.data.message);
        // console.log("Current Inventory:", response.data.message);
        // toggleInventoryTable();
        // setDisableInventoryButton(true);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
          onLogout();
        }
        if (error.response.status != 401) {
          setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
  };
  //!SECTION View Inbound Inventory



  //SECTION View Outbound and Replenish
  const [ viewOutbound, setViewOutbound ] = React.useState(false);
  const [ viewOutboundMessage, setViewOutboundMessage ] = React.useState('View Outbound and Replenishment');
  const [ selectedOutboundWarehouse, setSelectedOutboundWarehouse ] = React.useState();

  const toggleOutbound = () => {
      if (viewOutbound){
        setViewOutbound(false);
        setViewOutboundMessage('View Outbound and Replenishment');
        // setDisablebuttonButton(false);
      }else{
        getWarehouseList(props.user.email)
        setViewOutbound(true);
        setViewOutboundMessage('Hide Outbound and Replenishment');
          // handleGetAssignment();
      };
  };
  const handleSelectedWarehouseOutbound = async (selection) => {
    // console.log("handleSelectedWarehouseInbound", selection)
    // var outboundWarehouse = selection;
    setSelectedOutboundWarehouse(selection);
    handleGetOutbound(props.user.email, currentInventoryAccountCode,selection);
  };


  const handleGetOutbound = (user, currentInventoryAccountCode, selection) => {
    var warehouse = selection;
    const headers = props.headers;
    var parameters = { accountcode: currentInventoryAccountCode};
  
    axios
      .get(
        `/OperationsInventory/removedItems/${warehouse}`,
        { headers: headers, params: parameters }
      )
      .then(function async(response) {
        // console.log("Outbound Inventory:", response.data.message)
        // console.log("Outbound Inventory:", selectedOutboundWarehouse)
        setOutboundGridData(response.data.message);


      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
          onLogout();
        }
        if (error.response.status != 401) {
          setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        //always executed
      });
    };
    
    const [ outboundGridData, setOutboundGridData ] = React.useState([])
    const [ outboundGridColumns, setOutboundGridColumns ] = React.useState([
        {field: 'id', hide: true},
        //center: 'center', align: 'center', align column names and data to center of cell 
        {field: 'replenishedOrder', hide:(props.user.role<6), type: 'actions', align: 'center', headerAlign:'center', headerName: 'Replenish', minWidth: 125,
        getActions: (params) => [
          <GridActionsCellItem icon={<RotateRightIcon sx={{color:"#68a0d7", fontSize:30 }}/>} onClick={changeReorderStatus(params, "Ordered")}  name="reorder" label="reorder" />
        ]},
        {field: 'doNotReorder', hide:(props.user.role<6), type: 'actions', align: 'center', headerAlign:'center', headerName: 'No Reorder', minWidth: 125,
        getActions: (params) => [
          <GridActionsCellItem icon={<CloseIcon sx={{color:"#68a0d7", fontSize:30 }}/>} onClick={changeReorderStatus(params, "Do Not Reorder")}  name="reorder" label="reorder" />
        ]},
        {field: 'serialNum', headerName: 'Serial Number', headerAlign:'center', width: 200, editable: false, hide: true},
        {field: 'itemNumber', headerName: 'Item Number', width: 125, headerAlign:'center', align:'center', editable: false},
        {field: 'productName', headerName: 'Item Name', width: 300, editable: false},
        {field: 'locationName', headerName: 'Warehouse', width: 200, headerAlign:'center', align:'center', editable: false, hide: false},
        {field: 'warehouseNumber', headerName: 'Warehouse Number', width: 175, headerAlign:'center', align:'center', editable: false, hide: false},
        {field: 'reorderStatus', headerName: 'Replenishment Status', width: 175, headerAlign:'center', align:'center', editable: false, hide: false},
        {field: 'discrepancyStatus', headerName: 'Discrepancy Status', width: 175, headerAlign:'center', align:'center',editable: false},
        {field: 'discrepancyCause', headerName: 'Discrepancy Cause', width: 175, align:'center', headerAlign:'center', editable: false},
        {field: 'shippingStatus', headerName: 'Shipping Status', width: 175, headerAlign:'center', align:'center',editable: false},
        {field: 'removedBy', headerName: 'Removed By', width: 250, headerAlign:'center', align:'center', editable: false},
        {field: 'removedAt', headerName: 'Warehouse Removal Date', width: 200, headerAlign:'center', align:'center',editable: false},
        {field: 'arrivedAt', headerName: 'Warehouse Arrival Date', width: 200, headerAlign:'center', align:'center', editable: false},
        {field: 'shelfNum', headerName: 'Shelf', width: 125, align:'center', headerAlign:'center', editable: false},
        {field: 'notOrdered', hide:(props.user.role<6), type: 'actions', align: 'center', headerAlign:'center', headerName: 'Reset Status', minWidth: 125,
        getActions: (params) => [
          <GridActionsCellItem icon={<RotateLeftIcon sx={{color:"#68a0d7", fontSize:30 }}/>} onClick={changeReorderStatus(params, "Not Ordered")}  name="notOrdered" label="notOrdered" />
        ]},
        //column to change the reorder status of the item
        // {field: 'delete', hide:(props.user.role<6), type: 'actions', align: 'center', headerAlign:'center', headerName: 'Delete', minWidth: 125,  
        // getActions: (params) => [
        //   <GridActionsCellItem icon={<DeleteIcon sx={{color:"#68a0d7", fontSize:30 }}/>} onClick={deleteOutbound(params)}  label="delete" />
        // ]}
        // flex
        ])
    
    //! this is the way to handle getting the updated state
    const selectedOutboundWarehouseRef = React.useRef(selectedOutboundWarehouse);
    selectedOutboundWarehouseRef.current = selectedOutboundWarehouse;

     //! need to set selected warehouse so I can get the updated Outbound again   
    const changeReorderStatus = React.useCallback( (info, changeType) => async () => {

      var status = changeType;
      var itemNumber = info.row.id.toString();
      var warehouse = selectedOutboundWarehouseRef.current;

      const headers = props.headers;
      var parameters = { accountcode: currentInventoryAccountCode};
      var data = {
        status: status, 
        item_id: itemNumber
      };
      // console.log("deleteOutbound", parameters)
      axios
      .put(
        `/OperationsInventory/changeRemovedItemStatus/`, 
        data,
        { headers: headers, params: parameters }
      )
      .then(function async(response) {
        // handle success
        // console.log("deleteOutbound", response.data.message)
        setMessageSeverity("success") // error: red, warning: orange, information: blue, success: green
        setMessageBarMessage(response.data.message);
        setMessageBarOpen(true);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
          onLogout();
        }
        if (error.response.status != 401) {
          // put the details of the error a variable message
          var message = error.response.data.detail;
          setMessageBarMessage(`${message}. Please try again or email info@edonconnect.tech for help.`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        handleGetOutbound(props.user, currentInventoryAccountCode, warehouse);
      });

    },[]);

     //! need to set selected warehouse so I can get the updated Outbound again   
     //! depricated
    const deleteOutbound = React.useCallback( (info) => async () => {
      // console.log("deleteOutbound", info.row.locationName)
      var deleteId = info.id.toString();
      var warehouse = selectedOutboundWarehouseRef.current;
      var location = info.row.locationName;
      // console.log("Location", location)

      const headers = props.headers;
      var parameters = { accountcode: currentInventoryAccountCode};

      axios
      .delete(
        `/OperationsInventory/outboundItem/${location}/${deleteId}`,
        { headers: headers }
      )
      .then(function async(response) {
        // handle success
        // console.log("deleteOutbound", response.data.message)
        setMessageSeverity("success") // error: red, warning: orange, information: blue, success: green
        setMessageBarMessage(response.data.message);
        setMessageBarOpen(true);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
          onLogout();
        }
        if (error.response.status != 401) {
          // put the details of the error a variable message
          var message = error.response.data.detail;
          setMessageBarMessage(`${message}. Please try again or email info@edonconnect.tech for help.`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        handleGetOutbound(props.user, currentInventoryAccountCode, warehouse);
      });

    },[]);

        

    







      //!SECTION - OUTBOUND INVENTORY

  //SECTION View Warehouse Access 
  const [ viewWarehouseAccess, setViewWarehouseAccess ] = React.useState(false);
  const [ viewWarehouseAccessMessage, setViewWarehouseAccessMessage ] = React.useState('View Warehouse Access');
    const toggleWarehouseAccess = () => {
      if (viewWarehouseAccess){
        setViewWarehouseAccess(false);
        setViewWarehouseAccessMessage('View Warehouse Access');
        setViewAccessTable(false);
        setDisableAccessButton(false);
      }else{
        setViewWarehouseAccess(true);
        setViewWarehouseAccessMessage('Hide Warehouse Access');
        handleGetAccess();
      };
    };
  //! View the Assignment Table
  const [ viewAccessTable, setViewAccessTable ] = React.useState(false);
  const toggleAccessTable = () => {
      if (viewAccessTable){
      setViewAccessTable(false);
      }else{
      setViewAccessTable(true);
      };
  };
      //! State variables for Inventory
      //! This is sent down to the Grid Component
      const [ accessGridColumns, setAccessGridColumns ] = React.useState([
          {field: 'id', hide: true, width:550},
          {field: 'entryTime', hide:true,  headerName: 'Unix Entry Time', width: 250, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'warehouseId', headerName: 'Warehouse', width: 150, editable: false},
          {field: 'warehouseUserId', headerName: 'Access Code', width: 150, editable: false},
          {field: 'UTCentryTime', headerName: 'UTC Entry Time', width: 250, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'UTCexitTime', headerName: 'UTC Exit Time', width:250, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'durationMinutes', headerName: 'Duration Minutes', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'customerOrderNumbers', headerName: 'Customer Orders', width:350, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          ])
      const [ accessGridData, setAccessGridData ] = React.useState([])

      //! Button disable control 
      //! This is sent down to the filters component 
      const [ disableAccessButton, setDisableAccessButton ] = React.useState(false);

        //! Get the data 
      //! This is sent down to the filters component
      //SECTION Axios get example access data
      const handleGetAccess = () => {
          var rowlimit = 5000;
          var user = props.user.email;
          const owner = "edon_owner"
          var filetype = "currentAccess"
          if(accessDate!=="All")
              {var accessDate = new Date(accessDate)}
          // console.log("Iot Market:", marketName)
          // console.log("Iot Access Code:", accessCode)
          // console.log("Iot Warehouse Number:", warehouseNumber)
          // console.log("Iot Access Date:", accessDate)
          var itemNumber = "All"
          var assignmentCode = "All"

          // // //! Get Data from endpoint
          axios
            .get(
              `/iot/warehouseAccess/${owner}/${user}`
            )
            .then(function async(response) {
              // handle success
              setAccessGridData(response.data.data);
              // console.log("access:", response.data.data);
              toggleAccessTable();
              setDisableAccessButton(true);
            })
            .catch(function (error) {
              if (error.response.status === 401) {
                alert ("Your session has expired. Please login again.");
                onLogout();
              }
              if (error.response.status != 401) {
                setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
                setMessageBarOpen(true);
                setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
              }
            })
            .then(function () {
              // always executed
            });
      };
      //!SECTION


    //SECTION  View Cycle Counts
    const [ viewCycleCounts, setViewCycleCounts ] = React.useState(false);
    const [ viewCycleCountsMessage, setViewCycleCountsMessage ] = React.useState('View Cycle Counts');

    const toggleCycleCounts = () => {
        if (viewCycleCounts){
            setViewCycleCounts(false);
            setViewCycleCountsMessage('View Cycle Counts');
            // setViewCycleCountsTable(false); // view the  
            // setDisableCycleCountsButton(false);
        }else{
            setViewCycleCounts(true);
            setViewCycleCountsMessage('Hide Cycle Counts');
            handleGetCycleCounts();
        };
    };
    const processCycleCount = React.useCallback( (info) => async () => {
      var cycleCountId = info;
      const headers = props.headers;
      var parameters = { accountcode: currentInventoryAccountCode};
      axios
      .get(
        `/EventProcessing/processcyclecountdiscrepancies/${cycleCountId}`,
        {headers: headers, params: parameters}
      )
      .then(function async(response) {
        // handle success
        setMessageSeverity("success") // error: red, warning: orange, information: blue, success: green
        setMessageBarMessage(response.data.message);
        setMessageBarOpen(true);
        handleGetCycleCounts();
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
          onLogout();
        }
        if (error.response.status != 401) {
          setMessageBarMessage(`${error.response.data.detail}   a warehouse on the Dashboard page.`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
        //!Message bar
      })
      },[]);

    //!needs an end point
    const deleteCycleCount = React.useCallback( (info) => async () => {
      var cycleCountId = info.toString();
      const headers = props.headers;
      var parameters = { accountcode: currentInventoryAccountCode};
      axios
      .delete(
        `/EventProcessing/deletecyclecount/${currentInventoryAccountCode}/${cycleCountId}`,
        { headers: headers }
      )
      .then(function async(response) {
        // handle success
        setMessageSeverity("success") // error: red, warning: orange, information: blue, success: green
        setMessageBarMessage(response.data);
        setMessageBarOpen(true);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
          onLogout();
        }
        if (error.response.status != 401) {
          setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        handleGetCycleCounts();
      });
    },[]);

    const [ cycleCountsGridColumns, setCycleCountsGridColumns ] = React.useState([
      {field: 'id', hide: true},
      {field: 'process', hide:(props.user.role<6), type: 'actions', align: 'center', headerAlign:'center', headerName: 'Process', minWidth: 100,
      getActions: (params) => [
        <GridActionsCellItem icon={<RotateRightIcon sx={{color:"#68a0d7", fontSize:30 }} />} onClick={processCycleCount(params.id)}  label="process" />,
      ]},
        {field: 'location', headerName: 'Location', width: 200, editable: false, align: 'center', headerAlign: 'center'},
        {field: 'CreatedBy', headerName: 'Created By', width: 250, editable: false, align: 'center', headerAlign: 'center'},
        {field: 'CreatedAt', headerName: 'Creation UTC', width: 165, editable: false, align: 'center', headerAlign: 'center'},
        {field: 'correctScans', headerName: 'Correct Scans', width: 150, editable: false, align: 'center', headerAlign: 'center'},
        {field: 'foundDiscrepancies', headerName: 'Found Items', width: 150, editable: false, align: 'center', headerAlign: 'center'},
        {field: 'missingDiscrepancies', headerName: 'Missing Items', width: 150, editable: false, align: 'center', headerAlign: 'center'},
        {field: 'movedDiscrepancies', headerName: 'Wrong Shelf Items', width: 150, editable: false, align: 'center', headerAlign: 'center'},
        {field: 'delete', hide:(props.user.role<6), type: 'actions', align: 'center', headerAlign:'center', headerName: 'Delete', minWidth: 125,  
        getActions: (params) => [
          <GridActionsCellItem icon={<DeleteIcon sx={{color:"#68a0d7", fontSize:30 }}/>} onClick={deleteCycleCount(params.id)}  label="delete" />
        ]}
        ])
    const [ cycleCountsGridData, setCycleCountsGridData ] = React.useState([])

    const handleGetCycleCounts = () => {
      // var rowlimit = 5000;
      var user = props.user.email;
      const headers = props.headers;
      var parameters = { accountcode: currentInventoryAccountCode};

      axios
        .get(
          `/EventProcessing/viewcyclecountsmetadata`,
          {headers: headers, params: parameters}
        )
        .then(function async(response) {
          // handle success
          setCycleCountsGridData(response.data.message);

        })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");
            onLogout();
          }
          if (error.response.status != 401) {
            setMessageBarMessage(`${error.response.data.detail} `);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
        .then(function () {
          // always executed
        });
    };

    //!SECTION Cycle Counts




    //SECTION  View reconciliation
    const [ viewReconciliation, setViewReconciliation ] = React.useState(false);
    const [ viewReconciliationMessage, setViewReconciliationMessage ] = React.useState('View Reconciliation');
    const [ viewReconcileAllButton, setViewReconcileAllButton ] = React.useState(false);
    // const [ currentWarehouseDiscrepancies, setCurrentWarehouseDiscrepancies ] = React.useState('');
    const [ reconcileAll, setReconcileAll ] = React.useState({});


    const toggleReconciliation = () => {
        if (viewReconciliation){
            setViewReconciliation(false);
            setViewReconciliationMessage('View Reconciliation');
            // setViewReconciliationTable(false); // view the  
            // setDisableReconciliationButton(false);
        }else{
            getWarehouseList(props.user.email)
            setViewReconciliation(true);
            setViewReconciliationMessage('Hide Reconciliation');
            // handleGetReconciliation(props.user.email, accountCode, selectedWarehouse);
        };
    };

    const handleSelectedWarehouseRecon = async (selection) => {
      // setCurrentWarehouseDiscrepancies(selection);
      setReconcileAll({
        id: "All",
        row: {location: selection}});
      handleGetReconciliation(selection);
    };

    const handleResolveDiscrepancy = React.useCallback( (params) => async () => {


      // get the product id and set to item
      var item = params.row.productId;
      var warehouse = params.row.location;
      var headers = props.headers;
      var parameters = { accountcode: currentInventoryAccountCode};
      if (params.id === "All"){
        item = "All";
        warehouse = params.row.location;
      }
      // console.log("handleResolveDiscrepancy", parameters)
      axios
      .get(
        `/OperationsInventory/resolvediscrepancy/${warehouse}/${item}`,
        {headers: headers, params: parameters}
      )
      .then(function async(response) {
        setMessageBarMessage(response.data.message);
        setMessageBarOpen(true);
        setMessageSeverity("success") // error: red, warning: orange, information: blue, success: green
        handleGetReconciliation(warehouse);
        // toggleReconciliation();
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
          onLogout();
        }
        if (error.response.status != 401) {
          setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })

    });

    const [ reconciliationGridColumns, setReconciliationGridColumns ] = React.useState([
      {field: 'id', width:300,hide: true},
      {field: 'location', hide: true},
      {field: 'process', hide:(props.user.role<6), type: 'actions', align: 'center', headerAlign:'center', headerName: 'Add/Remove', minWidth: 125,
      getActions: (params) => [
        <GridActionsCellItem icon={<RotateRightIcon sx={{color:"#68a0d7", fontSize:30 }} />} onClick={handleResolveDiscrepancy(params)}  label="process" />,
      ]},
      {field: 'discrepancyStatus', headerName: 'Scan Status', width: 125, editable: false},
      {field: 'locationName', headerName: 'Location', width: 200, editable: false},
      {field: 'serialNum', headerName: 'Serial Number', width: 200, editable: false, hide: true},
      {field: 'productName', headerName: 'Item Name', width: 200, editable: false},
      {field: 'productId', headerName: 'Item Number', width: 200, editable: false},
      {field: 'shelfNum', headerName: 'Shelf', width: 150, editable: false},
      {field: 'discrepancyCause', headerName: 'Cause', width: 150, editable: false},
      {field: 'statusUpdatedAt', headerName: 'Status Date', width:200, editable: false, headerAlign: 'center', align:'center'},
        ])
    const [ reconciliationGridData, setReconciliationGridData ] = React.useState([])

    const handleGetReconciliation = (warehouse) => {
      const headers = props.headers;
      var parameters = { accountcode: currentInventoryAccountCode};
      axios
        .get(
          `/OperationsInventory/discrepancies/${warehouse}`,
          { headers: headers, params: parameters}
        )
        .then(function async(response) {
          setReconciliationGridData (response.data.discrepancies);
          
          if (warehouse !== "All" && response.data.discrepancies.length>0){
            setViewReconcileAllButton(true);
          }else{
            setViewReconcileAllButton(false);
          }

        })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");
            onLogout();
          }
          if (error.response.status != 401) {
            setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
        .then(function () {
          // always executed
        });
      };
    //!SECTION View Reconciliation


    //SECTION  Shipped Items
    const [ viewShippedItems, setViewShippedItems ] = React.useState(false);
    const [ viewShippedItemsMessage, setViewShippedItemsMessage ] = React.useState('View Shipped Items');
    const toggleShippedItems = () => {
        if (viewShippedItems){
            setViewShippedItems(false);
            setViewShippedItemsMessage('View Shipped Items');
        }else{
            getWarehouseList(props.user.email)
            setViewShippedItems(true);
            setViewShippedItemsMessage('Hide Shipped Items');
            // handleGetReconciliation(props.user.email, accountCode, selectedWarehouse);
        };
    };
    const handleSelectedWarehouseShippedItems = async (selection) => {
      // console.log("handleSelectedWarehouseASN", selection)
      handleGetShippedItems(selection);
    };
    const [ shippedItemsGridColumns, setShippedItemsGridColumns ] = React.useState([
      {field: 'id', width:300,hide: true},
      {field: 'Receipt Status', headerName: 'Inbounding Status', width: 175, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Current Status', headerName: 'Shipping Status', width: 170, editable: false, hide: false, headerAlign: 'center', align:'center'},
      {field: 'Item Number', headerName: 'Item Number', width: 120, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Item Name', headerName: 'Item Name', width: 250, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Ship To Number', headerName: 'Ship To Number', width: 150, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Ship To Name', headerName: 'Ship To Name', width: 200, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Ship To Address', headerName: 'Ship To Address', width: 225, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Issue Date', headerName: 'Issue Date', width: 125, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Tracking', headerName: 'Tracking', width: 175, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Delivery Date', headerName: 'Delivery Date', width: 150, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Delivery Time', headerName: 'Time', width:125, editable: false, headerAlign: 'center', align:'center', headerAlign: 'center', align:'center'},
      {field: 'Delivery Location', headerName: 'Delivery Location', width: 175, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Received By', headerName: 'Received By', width: 200, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Removed Item Status', headerName: 'Order Replenishment', width: 200, editable: false, headerAlign: 'center', align:'center'},
        ])
    const [ shippedItemsGridData, setShippedItemsGridData ] = React.useState([])
    const handleGetShippedItems = (warehouse) => {
      const headers = props.headers;
      var parameters = { accountcode: currentInventoryAccountCode};
      // console.log("handleGetASNs", warehouse)
      axios
        .get(
          `/Shipments/getShippedItems/${warehouse}`,
          { headers: headers, params: parameters}
        )
        .then(function async(response) {
          // console.log("handleGetASNs", response.data.message)
          setShippedItemsGridData (response.data.message);

        })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");
            onLogout();
          }
          if (error.response.status != 401) {
            setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
        .then(function () {
          // always executed
        });
      };
    //!SECTION View Shipped Items
  


    //SECTION  View  Received Items
    const [ viewDeliveredItems, setViewDeliveredItems ] = React.useState(false);
    const [ viewDeliveredItemsMessage, setViewDeliveredItemsMessage ] = React.useState('View Received Items');
    const toggleDeliveredItems = () => {
        if (viewDeliveredItems){
            setViewDeliveredItems(false);
            setViewDeliveredItemsMessage('View Received Items');
        }else{
            getWarehouseList(props.user.email)
            setViewDeliveredItems(true);
            setViewDeliveredItemsMessage('Hide Received Items');
            // handleGetReconciliation(props.user.email, accountCode, selectedWarehouse);
        };
    };
    const handleSelectedWarehouseDeliveredItems = async (selection) => {
      // console.log("handleSelectedWarehouseASN", selection)
      handleGetDeliveredItems(selection);
    };
    const [ deliveredItemsGridColumns, setDeliveredItemsGridColumns ] = React.useState([
      {field: 'id', width:300,hide: true},
      {field: 'itemNumber', headerName: 'Item Number', width: 125, editable: false, headerAlign: 'center', align:'center'},
      {field: 'productName', headerName: 'Item Name', width: 300, editable: false, headerAlign: 'center', align:'center'},
      {field: 'locationName', headerName: 'Location Name', width: 250, editable: false, headerAlign: 'center', align:'center'},
      {field: 'warehouseNumber', headerName: 'Warehouse Number', width: 200, editable: false, headerAlign: 'center', align:'center'},
      {field: 'CreatedBy', headerName: 'Inbounded By', width: 200, editable: false, headerAlign: 'center', align:'center'},
      {field: 'arrivedAt', headerName: 'Inbound Date', width: 150, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Tracking', headerName: 'Tracking', width: 185, editable: false, headerAlign: 'center', align:'center'}
        ])
    const [ deliveredItemsGridData, setDeliveredItemsGridData ] = React.useState([])

    const handleGetDeliveredItems = (warehouse) => {
      const headers = props.headers;
      var parameters = { accountcode: currentInventoryAccountCode};
      // console.log("handleGetASNs", warehouse)
      axios
        .get(
          `/OperationsInventory/receivedItems/${warehouse}`,
          { headers: headers, params: parameters}
        )
        .then(function async(response) {
          // console.log("handleGetDeliveredItems", response.data.message)
          setDeliveredItemsGridData (response.data.message);

        })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");
            onLogout();
          }
          if (error.response.status != 401) {
            setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
        .then(function () {
          // always executed
        });
      };
    //!SECTION Received Items

   //SECTION  View Removed Items
    const [ viewRemovedItems, setViewRemovedItems ] = React.useState(false);
    const [ viewRemovedItemsMessage, setViewRemovedItemsMessage ] = React.useState('View Removed Items');
    const toggleRemovedItems = () => {
        if (viewRemovedItems){
            setViewRemovedItems(false);
            setViewRemovedItemsMessage('View Received Items');
        }else{
            getWarehouseList(props.user.email)
            setViewRemovedItems(true);
            setViewRemovedItemsMessage('Hide Removed Items');
            // handleGetReconciliation(props.user.email, accountCode, selectedWarehouse);
        };
    };
    const handleSelectedWarehouseRemovedItems = async (selection) => {
      // console.log("handleSelectedWarehouseASN", selection)
      handleGetRemovedItems(selection);
    };
    const [ removedItemsGridColumns, setRemovedItemsGridColumns ] = React.useState([
      {field: 'id', width:75,hide: true},
      {field: 'itemNumber', headerName: 'Item Number', width: 125, editable: false, headerAlign: 'center', align:'center'},
      {field: 'productName', headerName: 'Item Name', width: 250, editable: false, headerAlign: 'center', align:'center'},
      {field: 'locationName', headerName: 'Location Name', width: 250, editable: false, headerAlign: 'center', align:'center'},
      {field: 'warehouseNumber', headerName: 'Warehouse Number', width: 175, editable: false, headerAlign: 'center', align:'center'},
      {field: 'removedBy', headerName: 'Removed By', width: 200, editable: false, headerAlign: 'center', align:'center'},
      {field: 'removedAt', headerName: 'Removed At', width: 200, editable: false, headerAlign: 'center', align:'center'},
      {field: 'discrepancyStatus', headerName: 'Discrepancy Status', width: 200, editable: false, headerAlign: 'center', align:'center'},
      {field: 'discrepancyCause', headerName: 'Discrepancy Cause', width: 200, editable: false, headerAlign: 'center', align:'center'},
      {field: 'reorderStatus', headerName: 'Reorder Status', width: 200, editable: false, headerAlign: 'center', align:'center'},
      {field: 'replenishmentShippingStatus', headerName: 'Replenishment Shipping Status', width: 275, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Tracking', headerName: 'Tracking', width: 175, editable: false, headerAlign: 'center', align:'center'},
        ])
    const [ removedItemsGridData, setRemovedItemsGridData ] = React.useState([])

    const handleGetRemovedItems = (warehouse) => {
      const headers = props.headers;
      var parameters = { accountcode: currentInventoryAccountCode};
      // console.log("handleGetASNs", warehouse)
      axios
        .get(
          `/OperationsInventory/removedItems/${warehouse}`,
          { headers: headers, params: parameters}
        )
        .then(function async(response) {
          // console.log("handleGetDeliveredItems", response.data.message)
          setRemovedItemsGridData (response.data.message);

        })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");
            onLogout();
          }
          if (error.response.status != 401) {
            setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
        .then(function () {
          // always executed
        });
      };
    //!SECTION Removed Items
  

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />

        {/* Content*/}
        {/* Content*/}
        {/* Content*/}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          <img src={EdonLogo} width={200} alt="Logo" />
          <Container
            overflow="auto"  //! Changed from scroll to auto 
            maxWidth="xl"
            sx={{ mt: 5, mb: 10, border: 1 }}
          >
            {props.user.membershipCode === "0x12a6b5324" && ( //Warehouse Anywhere membership
              <Grid item sx={{ p: 2 }}>
                <img src={WALogo} width={200} alt="Logo" />
              </Grid>
            )}
            {props.user.membershipCode === "0x4ee11bfe" && //Warehouse Anywhere membership
            <Grid item sx={{p:2}}>
                <img src={FlexLogo} width={150} alt="Logo" />
              </Grid>
            }

            <Grid
              container
              overflow="auto"
              spacing={3}
              sx={{ alignContent: "center", border: 0, pb: 2 }}
            >
              <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ alignContent: "center", border: 0 }}
              >
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    border: 0,
                  }}
                >
                  <Typography align="center" variant={"h4"}>
                    {currentInventoryAccount}
                  </Typography>
                  <Typography align="center" variant={"h4"}>
                    {pageTitle}
                  </Typography>
                  <Typography align="center" variant={"subtitle1"}>
                    {props.user.email}
                  </Typography>

            {!props.user.services.includes("IM") &&
              <Grid item md={12} lg={12} sx={{ alignContent: "center", p:2, border: 0 }}>
                <Unauthorized />
              </Grid>
            }
                

            {/* Accounts Drop Down */}
            {props.user.accountViewer && (
                <Grid
                  item
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 ,display:"flex", flexDirection:"column"}}
                >
                    <Button
                      onClick={toggleAccounts}
                    >
                      {viewAccountsMessage}
                    </Button>
                    {
                      viewAccounts && (

                        <AccountDropDown 
                        handleSelectedAccount={handleSelectedAccount}
                        toggleAccounts={toggleAccounts}
                        user={props.user}
                        headers={props.headers} />
                      ) // send the list of files and a handler down to the dropdown
                    }
                </Grid>
              )}
                </Paper>
              </Grid>
            </Grid>

            {/* Current Inventory*/}
            <Grid
              container
              spacing={3}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ alignContent: "center", border: 0 }}
            >

              {props.user.role > 3 && (
                  <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >         

                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button onClick={toggleInventory}>
                      {" "}
                      {viewInventoryMessage}
                    </Button>

                    {/* Inventory Filters */}
                    {viewInventory && 
                    <>
                    <Grid
                    container
                    justifyContent={"center"}
                    sx={{marginBottom:4, marginTop:2}}

                    >
                    <DropDownButtonless
                          itemList={warehouseList}
                          handleSelectedItem={handleSelectedWarehouseInventory}
                          dropDownTitles={warehouseTitles}
                          toggleClose={plecibo}
                      />
                    </Grid>
                    <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                    >
                    <SimpleGrid 
                    gridColumns={inventoryGridColumns} 
                    gridData={inventoryGridData} />

                    </Grid>
                </>
              }
                  </Paper>
                </Grid>
            </>
              )}


              {/* Inventory Inbound */}
              {props.user.role > 3 && false && (
                  <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button onClick={toggleInbound}>
                      {" "}
                      {viewInboundMessage}
                    </Button>

                    {/* Inventory Filters */}
                    {viewInbound && 
                    <>
                    <Grid
                    container
                    justifyContent={"center"}
                    sx={{marginBottom:4, marginTop:2}}
                    >
                    <DropDownButtonless
                          itemList={warehouseList}
                          handleSelectedItem={handleSelectedWarehouseInbound}
                          dropDownTitles={warehouseTitles}
                          toggleClose={plecibo}
                      />
                    </Grid>
                    <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                    >
                    <SimpleGrid gridColumns={inboundGridColumns} gridData={inboundGridData} />
                    </Grid>
                </>
              }
                  </Paper>
                </Grid>
            </>
              )}


              {/*  Outbound */}
              {props.user.role > 3 && (
                  <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button onClick={toggleOutbound}>
                      {" "}
                      {viewOutboundMessage}
                    </Button>

                    {/* Outbound Filters */}
                    {viewOutbound && 
                    <>
                    <Grid
                    container
                    justifyContent={"center"}
                    sx={{marginBottom:4, marginTop:2}}
                    >
                    <DropDownButtonless
                          itemList={warehouseList}
                          handleSelectedItem={handleSelectedWarehouseOutbound}
                          dropDownTitles={warehouseTitles}
                          toggleClose={plecibo}
                      />
                    </Grid>


                      <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sx={{ alignContent: "center", border: 0 }}
                      >
      
                      <SimpleGridOutbound gridColumns={outboundGridColumns} gridData={outboundGridData}  />
      
                      </Grid>
                  </>
                    }
                  </Paper>
                </Grid>

            </>
              )}

            <>
              {/* Warehouse Access Tracking */}

              {false > 5 && (
                  <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                  >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                    <Button onClick={toggleWarehouseAccess}>
                      {" "}
                      {viewWarehouseAccessMessage}
                    </Button>

                    {/* Access Filters */}
                    {viewWarehouseAccess && (
                        // <IoTAccessFilters
                        // disableButton={disableAccessButton}
                        // handleCurrentData={handleGetAccess}

                        // />
                      <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sx={{ alignContent: "center", border: 0 }}
                      >

                      <SimpleGrid gridColumns={accessGridColumns} gridData={accessGridData} />
                      </Grid>
                        )}
                  </Paper>
                </Grid>
              )}



              {/* Reconcilation */}
              {props.user.role > 3 && (
                  <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button onClick={toggleReconciliation}>
                      {" "}
                      {viewReconciliationMessage}
                    </Button>

                    {/* Cycle Counts Filters */}
                    {viewReconciliation && 
                    <>
                  <Grid
                    container
                    justifyContent={"center"}
                    sx={{marginBottom:4, marginTop:2}}
                    >
                    <DropDownButtonless
                          itemList={warehouseList}
                          handleSelectedItem={handleSelectedWarehouseRecon}
                          dropDownTitles={warehouseTitles}
                          toggleClose={plecibo}
                      />
                    </Grid>
                    {viewReconcileAllButton &&
                    <Grid
                      container
                      justifyContent={"right"}
                      sx={{marginBottom:1, marginTop:0}}
                      >
                      <Button onClick={handleResolveDiscrepancy(reconcileAll)}>
                        Process All Discrepancies
                      </Button>
                    </Grid>
                    }
                    <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                    >
                    <SimpleGrid gridColumns={reconciliationGridColumns} gridData={reconciliationGridData} />
                    </Grid>
                </>
              }
                  </Paper>
                </Grid>
            </>
              )}


              {/* Shipped Items */}
              {props.user.role > 3 && (
                  <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button onClick={toggleShippedItems}>
                      {" "}
                      {viewShippedItemsMessage}
                    </Button>

                    {/* ASN Filters */}
                    {viewShippedItems && 
                    <>
                  <Grid
                    container
                    justifyContent={"center"}
                    sx={{marginBottom:4, marginTop:2}}
                    >
                    <DropDownButtonless
                          itemList={warehouseList}
                          handleSelectedItem={handleSelectedWarehouseShippedItems}
                          dropDownTitles={warehouseTitles}
                          toggleClose={plecibo}
                      />
                    </Grid>

                    <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                    >
                    <SimpleGridShippedItems gridColumns={shippedItemsGridColumns} gridData={shippedItemsGridData} />
                    </Grid>
                </>
              }
                  </Paper>
                </Grid>
            </>
              )}



              {/* Received Items */}
              {props.user.role > 3 && (
                  <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button onClick={toggleDeliveredItems}>
                      {" "}
                      {viewDeliveredItemsMessage}
                    </Button>

                    {/* ASN Filters */}
                    {viewDeliveredItems && 
                    <>
                  <Grid
                    container
                    justifyContent={"center"}
                    sx={{marginBottom:4, marginTop:2}}
                    >
                    <DropDownButtonless
                          itemList={warehouseList}
                          handleSelectedItem={handleSelectedWarehouseDeliveredItems}
                          dropDownTitles={warehouseTitles}
                          toggleClose={plecibo}
                      />
                    </Grid>

                    <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                    >
                    <SimpleGrid gridColumns={deliveredItemsGridColumns} gridData={deliveredItemsGridData} />
                    </Grid>
                </>
              }
                  </Paper>
                </Grid>
            </>
              )}



              {/* Cycle Count */}
              {props.user.role > 3 && (
                  <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button onClick={toggleCycleCounts}>
                      {" "}
                      {viewCycleCountsMessage}
                    </Button>

                    {/* Cycle Counts Filters */}
                    {viewCycleCounts && 
                    <>
                    <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                    >
                    <SimpleGrid gridData={cycleCountsGridData} gridColumns={cycleCountsGridColumns} user={props.user} />
                    </Grid>
                </>
              }
                  </Paper>
                </Grid>
            </>
              )}



                
            </>

              <Box sx={{justifyContent:'center', border:0}}>
                    <Copyright sx={{ p: 2, align: "center" }} />
              </Box>

            </Grid>

          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};