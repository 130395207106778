import * as React from 'react';

import { DataGridPro, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';

import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function CurrentMarketsTable(props) {

    const nonAdminUser = props.user.role !== 10 ? true : false;
    const [selectedModel, setSelectedModel ] = React.useState();
    const  currentMarkets  = {
      columns: [
      {field: 'id', hide: true},
      // {field: 'accountName', headerName: 'Account Name', width:200, headerAlign: 'center', align: 'center',  editable: false, hide: nonAdminUser},
      {field: 'marketName', headerName: 'Market Name', width:200, headerAlign: 'center', align: 'center',  editable: false, },
      {field: 'regionName', headerName: 'Region', width:200, headerAlign: 'center', align: 'center', editable: false},
      {field: 'countryName', headerName: 'Country', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'status', headerName: 'Status', width:125, headerAlign: 'center', align: 'center', editable: false},
    //   {field: 'actions', type: 'actions', headerName: 'Delete User', width:150 ,  
    //   getActions: (params) => [
    //     <GridActionsCellItem icon={<DeleteIcon />} onClick={props.deleteMobileUser(params.id)}  label="Delete" />
    //   ]},

      ], 
      rows: props.currentMarketsDisplayData
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 

        showCellRightBorder
        showColumnRightBorder
        showCellLeftBorder
        
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...currentMarkets} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};