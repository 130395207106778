import * as React from 'react';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import { useDemoData } from '@mui/x-data-grid-generator';

import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function NetworkOptimizationGrid(props) {
    
    const [selectedModel, setSelectedModel ] = React.useState();
    const  NetOptzModels  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'file', headerName: 'File', width: 250, align: 'center', editable: false},
      {field: 'warehousefile', headerName: ' Warehouse File', width: 250, align: 'center', editable: false},
      {field: 'market', headerName: 'Market', width: 250, align: 'center', editable: false},
      {field: 'statwarehousecount', headerName: 'Warehouse Count', width: 145, headerAlign: 'center', align: 'center', editable: false, type: 'number'},
      {field: 'statavgdistance', headerName: 'Average Distance', width: 145, headerAlign: 'center', align: 'center', editable: false, type: 'number'},
      {field: 'userdistance', headerName: 'Distance', width: 100, editable: false, headerAlign: 'center', align: 'center', type: 'number'},
      {field: 'memberCompany', headerName: 'Member Company', hide:(props.user.role<10), width:200, editable: false},
      {field: 'accountCompany', headerName: 'Account Company', hide:(props.user.role<8),width:200, editable: false},
      {field: 'userwarehouses', headerName: 'Warehouse', width: 100, editable: false, headerAlign: 'center', align: 'center', type: 'number'},
      {field: 'userservicelevel', headerName: 'Service', width: 100, editable: false, headerAlign: 'center', align: 'center', type: 'number'},
      {field: 'statservicelevel', headerName: 'Service Level', width: 130, headerAlign: 'center', align: 'center', editable: false, type: 'number'},
      ], 
      rows: props.netModels
      }
      // console.log("Net Models:",props.netModels)



  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid 
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
        props.handleSelectedNetModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...NetOptzModels} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};