import * as React from 'react';
import axios from 'axios';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import { useStorageState } from "react-storage-hooks";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ShelfCycleScanGrid from './ShelfCycleScanGrid.js';



export default function CycleCountWarehouseScanning(props) {

    // const [ serialNumberOption, setSerialNumberOption ] = React.useState(true);
    // const [ productQuantityOption, setProductQuantityOption ] = React.useState(false);


   //SECTION Form Validation
    //! Form Validation
    const [ buttonDisable, setButtonDisable ] = React.useState(true);
    const [ completeButtonDisable, setCompleteButtonDisable ] = React.useState(true);
    const [ validFields, setValidFields ] = React.useState(
      {
        "shelfNumber": false,
        "productId": false,
        "serialNum": false,
        "quantity": false,
        "userFirstName": false,
        "userLastName": false,
        "userEmail": false,
    });
    const [ helperText, setHelperText ] = React.useState(
        {
        "shelfNumber":"Scan Shelf",
        "productId":"SKU Number",
        "serialNum":"Unique Identifier",
        "quantity": false,
        "userFirstName":"",
        "userLastName":"",
        "userEmail":"",
       });


    const [ validProductNumber, setValidProductNumber ] = React.useState(false);
    const [ validSerialNumber, setValidSerialNumber ] = React.useState(false);
    const [ validShelfNumber, setValidShelfNumber ] = React.useState(false);
    

    const formValidation =  (e) => {
      e.preventDefault();
      switch (e.target.id){
        case 'shelfId': 
          // console.log("Valid: ", props.allValidShelfNumbers)
          if (props.allValidShelfNumbers.includes(e.target.value) ){
            setValidFields(prevState => ({
              ...prevState,
              "shelfNumber": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                shelfNumber: "",
                }));
                handleValidShelfNumber(e.target.value);
                setValidShelfNumber(true);
            //   set shelf number to the target; open part scans; hide shelf number; put Shelf Number in title
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "shelfNumber": true}));
              setHelperText(prevState => ({
                ...prevState,
                shelfNumber: "This is not valid Shelf Number.",
                }));
              setValidShelfNumber(false)
          }
          break;

        case 'productId': 
        // NOTE check to see if it is a valid Product Id
          if (props.allValidProductNumbers.includes(e.target.value) ){
            setValidFields(prevState => ({
              ...prevState,
              "productId": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                productId: "",
                }));
              setValidProductNumber(true);
              setButtonDisable(false)
              getProductInfo(e.target.value);
              setViewCurrentProductInfo(true);

              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "productId": true}));
              setHelperText(prevState => ({
                ...prevState,
                productId: "This product number is not on the order or not valid.",
                }));
              setValidProductNumber(false);
              setButtonDisable(false)
          }
          break;
        case 'serialNum': 

          if (props.allValidSerialNumbers.includes(e.target.value)){
            setValidFields(prevState => ({
              ...prevState,
              "serialNum": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                serialNum: "",
                }));
              setButtonDisable(false)
              getProductInfo(e.target.value);
              setCurrentSerialNumber(e.target.value);
              setViewCurrentProductInfo(true);
            //! get sn info from database 
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "serialNum": true}));
              setHelperText(prevState => ({
                ...prevState,
                serialNum: "This is not a valid Serial Number", //! do you want to add the serial number and product information  
                }));
              setButtonDisable(true);
              setViewCurrentProductInfo(false);
          }
          break;
        default:
          break;
      }
    }
    //!SECTION Form Validation


    //SECTION - Shelf
    const [ shelfNumberInput, setShelfNumberInput ] = React.useState();
    const [ viewShelfNumberInput, setViewShelfNumberInput ] = React.useState(true);
    const [ currentShelfNumber, setCurrentShelfNumber ] = useStorageState(localStorage, "EventCurrentShelfNumber");
    const [ viewPartsScans, setViewPartsScans ] = useStorageState(localStorage, "EventViewPartsScans");
    // const [ currentCycleCountShelves, setCurrentCycleCountShelves ] = useStorageState(localStorage, "EventCurrentShelves",[]);

    const handleValidShelfNumber = async (shelfNumber) => {
        setDisableCompleteButton(true);
        setCurrentShelfNumber(shelfNumber)
        setViewShelfNumberInput(false);
        setViewPartsScans(true);
        var currentShelfContents = shelfContents.find(items => { 
          return items.Shelf === shelfNumber
        })
        // if there are items on the shelf display them
        if(currentShelfContents){
            var contents = shelfContents.find(shelfContent => {
              return shelfContent.Shelf === shelfNumber
            })
            var otherContents = shelfContents.filter(shelfContent=> {
              return shelfContent.Shelf !==shelfNumber
            })
            setProducts(contents.items);
            setShelfContents(otherContents);
            setCompleteButtonDisable(false);
        };
    };

    const handleChangeShelfNumber = async () => {
        setCurrentShelfNumber("")
        setViewShelfNumberInput(true);
        setViewPartsScans(false);
    };
    //!SECTION 

    //SECTION - Product

      //! Current scanned product info
  const [ currentProductInfo, setCurrentProductInfo ] = React.useState({});
  //! current serial number
  const [ currentSerialNumber, setCurrentSerialNumber ] = React.useState("");
  //! view current product info
  const [ viewCurrentProductInfo, setViewCurrentProductInfo ] = React.useState(false);

  //Function to get the product info from the database
  const getProductInfo = async (itemNumber) => {
    var headers = props.headers
    var account = props.accountCode
    await axios
    .get(
      `${props.mobileSite}MobileApp/productinfoweb/${account}/${itemNumber}`, { headers: headers}
      )
      .then(function async(response) {
        setCurrentProductInfo(response.data.info)
        // productIdInput.current.value = response.data.info.productNum;
        // setAllValidShelfNumbers(response.data.shelfs)
        // setValidProductNumber(true);
  })
  .catch(function (error) {
    // handle error
    console.log(error);
    //! Need itMessage bar
    // setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
    // setMessageBarOpen(true);
    // setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
  })
  .then(function () {
    // always executed
  });
  };

    // const [ products, setProducts ] = React.useState([]);
    const [ products, setProducts ] = useStorageState(localStorage, "EventProductScans",[]);
    const [ totalProductScans, setTotalProductScans ] = useStorageState(localStorage, "EventTotalProductScans", 0);
    const [ shelfContents, setShelfContents ] = useStorageState(localStorage, "EventShelfContents",[]);
    const productIdInput = React.useRef(null)
    const serialNumInput = React.useRef(null)
    
    const handleRemoveProduct = async (currentProducts, currentSerialNumber) => {
        //!add serial number back to allValidSerialNumbers
        var currentSerialNumbers = props.allValidSerialNumbers;
        currentSerialNumbers.push(currentSerialNumber);
        props.changeValidItemInfo("item", currentSerialNumbers);
        setViewCurrentProductInfo(false);

        //remove product from products
        setProducts([...currentProducts])
        if (currentProducts.length === 0) {
          setCompleteButtonDisable(true)
        };
    };


  const handleAddProduct = async (e) => {
    e.preventDefault();
    var newId = products.length + 1
    var addProduct = {
        "id":newId,
        "productId": currentProductInfo.productNum,
        "serialNum": currentSerialNumber,
        "productName": currentProductInfo.productName,
        "shelfNum": currentShelfNumber
      }   
    var currentProduct = [ ...products, addProduct]
    setProducts(currentProduct);
    if (currentProduct.length > 0) {
      setCompleteButtonDisable(false)
    };
    // console.log("addProduct", addProduct);
    if (props.eventAccountSerialType){
        // console.log(addProduct)
        //! remove serial number from allValidSerialNumbers
        var currentSerialNumbers = props.allValidSerialNumbers;
        var index = currentSerialNumbers.indexOf(currentSerialNumber);
        if (index > -1) {
          currentSerialNumbers.splice(index, 1);
        }
        props.changeValidItemInfo("item", currentSerialNumbers);
        // setAllValidSerialNumbers(currentSerialNumbers);
        //! add product to products
        serialNumInput.current.value = "";
        // //! if there is one product disable add button
        setButtonDisable(true);
        setViewCurrentProductInfo(false);
      }else{
        //!start here
        // console.log("no serial number");
        productIdInput.current.value = "";
        setButtonDisable(true);
        setViewCurrentProductInfo(false);
      }
  };
  //!SECTION


//SECTION Complete Shelf
  const handleCompleteShelf = () => {
    handleStoreContents();
    countTotalProducts()
    setProducts([]); // clear products
    handleChangeShelfNumber();
    setCompleteButtonDisable(true);
    setDisableCompleteButton(false);
    setViewCurrentProductInfo(false);
  };

  const countTotalProducts = () => {
    var productCount = products.length
    shelfContents.forEach((shelf) => {
      productCount = productCount + shelf.items.length
    });
    setTotalProductScans(productCount);
  };


  const handleStoreContents = () => {
    var currentContents = {
      "Shelf": currentShelfNumber,
      "items": products
    };
    // console.log([...shelfContents, currentContents])
    setShelfContents([...shelfContents, currentContents])
  };
//!SECTION

//SECTION - Complete Cycle Count

  const handleContinue = () => {
    setDisableCompleteButton(false);
    setViewCompletionButtons(false);
    alert('Continuing the cycle count')
  }

  const handleComplete = async () => {
    setDisableCompleteButton(false);
    setViewCompletionButtons(false);
    //!Send shelfContents to database
    //!Send shelfContents to database

    // console.log(props.warehouseCode)
    // console.log(props.cycleCountIndex)
    var headers = props.headers
    var data = JSON.stringify({
      mobileUser: props.user.email,
      account: props.eventAccountCode,
      location: props.warehouseCode,
      type: props.scanningType,
      eventIndex: props.eventIndex,
      eventInfo: shelfContents
    });

    // console.log(data)

    await axios({
      method: "post",
      url: `${props.mobileSite}MobileApp/locationinventoryeventweb`,
      headers: headers,
      data: data,
    })
      .then(function (response) {
        // Message about the User account
        // console.log(response.data);
        props.handleSnackBar(response.data.message, "success")

      })
      .catch(function (error) {
        // handle error
        // console.log(error.response.data.detail);
        props.handleSnackBar(error.response.data.detail, "error")
        //!Message bar
        // setMessageBarMessage(`Oh no! Something went wrong. ${error.response.data.detail} You can email info@edonconnect.tech for help. ${error.response.data.detail}`);
        // setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        // setMessageBarOpen(true);
      })
      .then(function (response) {
        // Send the email
        // if (sendUserEmail) {
        //   // console.log("Account Infor", assignAccount);
        //   props.sendUserInvite(userEmail, assignAccount, userFirstName);
        // }
      });


    props.handleSnackBar("Cycle Count Complete", "success");
    setProducts([]);
    setCurrentShelfNumber("");
    setViewPartsScans(false);
    props.toggleScanInventory();
    props.handleChangeWarehouse();
    props.handleLocalStorageChange();

    localStorage.removeItem('EventValidProductNumbers')
    localStorage.removeItem('EventValidSerialNumbers')
    localStorage.removeItem('EventCurrentShelves')
    localStorage.removeItem('EventShelfContents')
    localStorage.removeItem('InventoryScanningType')//remove later
  }


  // const handleStartOver = () => {
  //   setDisableCompleteButton(false);
  //   setViewCompletionButtons(false);
  //   setProducts([]);
  //   setCurrentShelfNumber("");
  //   setViewPartsScans(false);
  //   props.toggleScanInventory();
  //   props.handleChangeWarehouse();
  //   props.handleLocalStorageChange();
  //   localStorage.setItem('CycleCountCurrentShelfNumber', '');
  //   localStorage.setItem('CycleCountProductScans', []);
  //   localStorage.setItem('CycleCountViewPartsScans', false);
  //   localStorage.setItem('CurrentCycleCountShelves', []);
  //   localStorage.setItem('CycleCountShelfContents', []);
  //   setCurrentCycleCountShelves([])
  //   setShelfContents([]);
  // }

  const handleStartCompletion = () => {
    // send message to user via snackbar
    props.handleSnackBar(`You have scanned ${totalProductScans} parts for this cycle count`, "success")

    // alert(`You have scanned ${totalProductScans} parts for this cycle count`)
    setViewCompletionButtons(true);
    setDisableCompleteButton(true);
  };
  const [ viewCompletionButtons, setViewCompletionButtons ] = React.useState(false);
  const [ disableCompleteButton, setDisableCompleteButton ] = React.useState(true);
//!SECTION

  return (

    <Grid
      container
      marginTop={1}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 1,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >

    <Grid item xs={8} md={8} lg={8} sx={{ border: 0 }}>
        <Typography component="h1" variant="h5">
          {props.scanningTypeMessage} Warehouse Scans
        </Typography>
    </Grid>
    
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={6} md={6} lg={6} sx={{ border: 0, marginTop: 2}}>
          <Typography variant="subtitle">
            Product Transactions: {totalProductScans} 
          </Typography>
      </Grid>
      <Grid item xs={6} md={6} lg={6} sx={{ border: 0, marginTop: 2 }}>
          <Typography variant="subtitle">
            Shelf Scans:  {shelfContents.length}
          </Typography>
      </Grid>
    </Grid>

      {/* Shelf Number */}
       { viewShelfNumberInput && 
      <Grid
      container
      marginTop={1}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        p: 1,
        border: 0,
        borderStyle: "dotted",
        borderRadius: "5px",
        direction: "row-reverse",
      }}
    >
          <TextField
            error = {validFields.shelfNumber}
            helperText={helperText.shelfNumber}
            inputRef={shelfNumberInput}
            margin="normal"
            required
            fullWidth
            name="shelfId"
            label="Shelf Number"
            type="text"
            id="shelfId"
            onChange={(e) => formValidation(e)}
          />
          </Grid>
       }

       {/* Scanning */}
       { viewPartsScans && 
        <Box
          component="form"
          onSubmit={handleAddProduct}
          noValidate
          sx={{ mt: 1 }}
        >

        <Typography component="h1" variant="h5">
          Shelf: {currentShelfNumber}
        </Typography>
        <Button
            onClick={handleChangeShelfNumber}
        >
        Change Shelf
        </Button>

        {props.eventAccountSerialType &&
          <TextField
          error = {validFields.serialNum}
          helperText={helperText.serialNum}
          inputRef={serialNumInput}
          margin="normal"
          fullWidth
          required
          name="serialNum"
          label="Serial Number"
          type="text"
          id="serialNum"
          onChange={(e) => formValidation(e)}
          />
        }
        {!props.eventAccountSerialType &&
        <TextField
          error = {validFields.productId}
          helperText={helperText.productId}
          inputRef={productIdInput}
          margin="normal"
          required
          fullWidth
          name="productId"
          label="Product Number"
          type="text"
          id="productId"
          onChange={(e) => formValidation(e)}
        />
    }
    
    {viewCurrentProductInfo &&
        <>
          <Grid item xs={12} md={12} lg={12} sx={{ border: 0, marginTop: 2 }}>
            <Typography  variant="subTitle">
            Product Number: {currentProductInfo.productNum}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ border: 0, marginTop: 2 }}>
            <Typography  variant="subTitle">
             Product Name: {currentProductInfo.productName} 
            </Typography>
          </Grid>
        </>
    }

          <Button
            disabled={buttonDisable}
            align="left"
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}  
          >
            Add Product to Shelf
          </Button>
          

        </Box>
        }
          <ShelfCycleScanGrid  gridData = {products} handleRemoveProduct={handleRemoveProduct} eventAccountSerialType={props.eventAccountSerialType}/>
          <Button
            disabled={completeButtonDisable}
            align="left"
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleCompleteShelf}
          >
            Complete Shelf
          </Button>
          <Grid
      container
      marginTop={1}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        p: 1,
        border: 0,
        borderStyle: "dotted",
        borderRadius: "5px",
        direction: "row-reverse",
      }}
    >
      <Button
            disabled={disableCompleteButton}
            align="left"
            variant="contained"
            color="error"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleStartCompletion}
          >
            Complete Location Cycle Count
          </Button>
      </Grid>

      {viewCompletionButtons &&
      <>
      <Grid item xs={6} md={6} lg={6} sx={{ border: 0 }}>
      <Button
            disabled={false}
            align="left"
            variant="contained"
            color="warning"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleContinue}
          >
            Continue with Cycle Count
          </Button>
      </Grid>

      <Grid item xs={6} md={6} lg={6} sx={{ border: 0 }}>
      <Button
            disabled={false}
            align="left"
            variant="contained"
            color="success"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleComplete}
          >
            Complete Location Cycle Count
          </Button>
      </Grid>
      </>
    }  
    </Grid>
  );
}