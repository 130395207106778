import * as React from 'react';

import { DataGridPro, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';


import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function MobileUserTable(props) {

    const nonAdminUser = props.user.role !== 10 ? true : false;
    const [selectedModel, setSelectedModel ] = React.useState();

    if (nonAdminUser == true) {
      var currentServices  = {
        columns: [
        {field: 'id', hide: true},
        {field: 'service', headerName: 'Service', width:175, headerAlign: 'center', align: 'center',  editable: false},
        {field: 'status', headerName: 'Status', width:100, headerAlign: 'center', align: 'center',  editable: false},
        ], 
        rows: props.currentServicesDisplayData
        }
    }else{

    var currentServices  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'service', headerName: 'Service', width:175, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'status', headerName: 'Status', width:100, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'actions', type: 'actions', headerName: 'Change Service Status', width:200, hide: nonAdminUser,
      getActions: (params) => [
        <GridActionsCellItem icon={<ChangeCircleIcon />} onClick={props.changeCurrentServices(params.id, props.currentServicesDisplayData.find(x => x.id === params.id).status, props.selectedAccount)}  label="Change" />
      ]
    },
      ], 
      rows: props.currentServicesDisplayData
      }
    };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 

        showCellRightBorder
        showColumnRightBorder
        showCellLeftBorder

        initialState={{
            sorting: {
              sortModel: [{ field: "status" }],
            },
          }}
        
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...currentServices} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};