import * as React from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { createTheme } from '@mui/material/styles';

import GridInput from './GridInputs';
import LegendY from './legendY';
import { ThemeProvider } from '@emotion/react';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MessageSnackBar from './MessageSnackBar';



const theme = createTheme();

theme.typography.h2 = {
    fontSize: '1.2rem',
    '@media (min-width, 600px)': {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.4rem'
    },
};




export default function EchelonInputGrid(props) {

    //SECTION Message Bar Controls
        const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
        const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
        const [ messageSeverity, setMessageSeverity ] = React.useState('success')
        const handleMessageBarClosing = () => {
        setMessageBarOpen(false);
        };
    //!SECTION


    const [ gridInputs, setGridInputs ] = React.useState(
        {'25': 'N',
        '50': 'N',
        '75': 'N',
        '100': 'N',
        '175': 'N',
        '350': 'N',
        '525': 'N',
        '700': 'N',
        '275': 'R',
        '550': 'R',
        '825': 'M',
        '1100': 'N',
        '325': 'R',
        '650': 'R',
        '975': 'M',
        '1300': 'M'}
    );

    const [ spinner, setSpinner ] = React.useState(false);

    const handleGridInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const cellName = parseInt(event.target.name);
        // console.log("Names:", cellName);
        // console.log("Old grid", gridInputs)
        // console.log("values", Object.values(gridInputs))
        // console.log(gridInputs.get('650'))
        const newGridInputs = Object.assign({}, gridInputs, {[cellName]: event.target.value});
        // console.log("New grid", newGridInputs)
        setGridInputs(newGridInputs); 
    };

    //SECTION Axios Assign all items to an Echelon
const echelonInputGrid = () => {
    const userid = props.user.email;
    var grid_value_list=[gridInputs[25], gridInputs[50], gridInputs[75], gridInputs[100],
                        gridInputs[175], gridInputs[350], gridInputs[525], gridInputs[700],
                        gridInputs[275], gridInputs[550], gridInputs[825], gridInputs[1100],
                        gridInputs[325], gridInputs[650], gridInputs[975], gridInputs[1300],
                        ];
    var data = JSON.stringify({
        "grid_value_list": grid_value_list,
        "netmodel": props.selectedModel,
        "netmodelID": props.currentNetModelID,
        "cost_percentage_list":[100,75,50,25],
        "volume_percentage_list":[100,75,50,25],
        "demand_period":"Order Week"});
        
    setSpinner(true);

    var config = {
        method: 'post',
        url: `/echelonItemAssignment/${userid}/${props.accountCode}`,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
    };
    axios(config)
    .then(function (response) {
    
        props.handleMarketSummary(response.data);
        // console.log('data from api', response.data)
        // console.log('detail Type', (typeof response.data.detail.data))
        props.handleInventoryDetail(response.data.detail.data)
        setSpinner(false);
        
    })
    .catch(function (error) {
        console.log(error);
        //!Message bar
        setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
    });
  };
  //!SECTION
  
    const xaxisLabel = "<---  Volume%  --->"

  return (
    <>
    <Grid id='echelonPolicy' 
        overflow="auto"  //! Changed from scroll to auto 
        container sx= {{border:0}} >
        
        {/* Title 

        <Grid item xs={12} sm={12}  sx={{textAlign:'center', ml:0, border:0, mb:3}}>
            <ThemeProvider theme= {theme}>
                <Typography sx={{}} variant={'h2'}>Echelon Policy Grid</Typography>
            </ThemeProvider>
        </Grid>*/}


    {/* Echelon Grid */}
    
        {/* Legend and Y-Axis */}
        <Grid item xs={2} sm={2}>
                <LegendY />
        </Grid>

        {/* X-axis and Inputs */}

        {/* X-axis title */}
        <Grid item xs={10} sm={10} sx={{border: 0 }}>

                <Grid container sx={{border:0}}>
                    <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />

                <Grid item xs={12} sm={12} sx={{pb:1, pt:0, border:0}}>
                    <ThemeProvider theme= {theme}>
                        <Typography align='center'  variant={'subtitle1'}>{xaxisLabel} </Typography>
                    </ThemeProvider>

                </Grid>

                    <Grid item sx={{align: 'center', border: 1}} xs={3} sm={3}>
                        <Box 
                            sx={{ 
                            p: 1,
                            borderTop: 1,
                            borderLeft: 1,
                            }}>
                            <ThemeProvider theme= {theme}>
                                <Typography align='center' variant={'h2'}>25</Typography>
                            </ThemeProvider>
                        </Box>
                    </Grid>

                    <Grid item sx={{align: 'center', border: 1}}  xs={3} sm={3}>
                        <Box 
                            sx={{ 
                            p: 1,
                            borderTop: 1,
                            borderLeft: 0,
                            }}>
                            <ThemeProvider theme= {theme}>
                                <Typography align='center' variant={'h2'}>50</Typography>
                            </ThemeProvider>
                        </Box>
                    </Grid>
                    <Grid item sx={{align: 'center', border: 1}}  xs={3} sm={3}>
                        <Box 
                            sx={{ 
                            p: 1,
                            borderTop: 1,
                            borderLeft: 0,
                            }}>
                            <ThemeProvider theme= {theme}>
                                <Typography align='center' variant={'h2'}>75</Typography>
                            </ThemeProvider>
                        </Box>
                    </Grid>
                    <Grid item sx={{align: 'center', border: 1}} xs={3} sm={3}>
                        <Box 
                            sx={{ 
                            p: 1,
                            borderTop: 1,
                            borderRight: 1,
                            }}>
                            <ThemeProvider theme= {theme}>
                                <Typography align='center' variant={'h2'}>100</Typography>
                            </ThemeProvider>
                        </Box>
                    </Grid>

        {/*  Grid Inputs  */}
            <Grid item xs={12} sm={12}>
                <GridInput user={props.user} gridInputs={gridInputs} handleGridInputChange={handleGridInputChange} />
            </Grid>
        </Grid>

            <Grid align={'center'}  xs={12} sm={12} md={12} sx={{ mt: 3, border:0}}>
                {!spinner &&
                <Button disabled={props.optimizeButtonDisable} onClick={echelonInputGrid} sx={{backgroundColor:"#68a0d7", color: "white", 
                '&:hover': {backgroundColor: "#BA5F8e"}}} variant="contained">Optimize</Button>}
                {spinner &&
                <CircularProgress/> }
            </Grid>
                </Grid>

                </Grid>
                <Divider/>


      </>
  );
}


