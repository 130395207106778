import * as React from 'react';

import { DataGridPro, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid-pro';


import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function UserStats(props) {


    
    const [selectedModel, setSelectedModel ] = React.useState();
    const  ResourceMembershipStats  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'companyName', headerName: 'Company Name', headerAlign: 'center', align: 'center', flex:1, editable: false},
      {field: 'adminstratorEmail', headerName: 'Adminstrator', headerAlign: 'center', align: 'center', flex:1, editable: false},
      {field: 'creatorEmail', headerName: 'Creator', headerAlign: 'center', align: 'center', flex:1, editable: false },
      {field: 'membershipCode', headerName: 'Membership Code',  flex:1, editable: false, headerAlign: 'center', align: 'center', type:'number' },
      {field: 'memberType', headerName: 'Membership Type',  flex:1, editable: false, headerAlign: 'center', align: 'center', type:'number' },
      {field: 'active', headerName: 'Active',  width:100, editable: false, headerAlign: 'center', align: 'center', type:'number' },

      ], 
      rows: props.membersDisplayData
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 

        showCellRightBorder
        showColumnRightBorder
        showCellLeftBorder
        
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...ResourceMembershipStats} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};