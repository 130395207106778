import React from "react";

import axios from 'axios';

import { Route, Switch, Redirect, HashRouter } from "react-router-dom";
import { useStorageState } from "react-storage-hooks";
import './App.css';
import Dashboard from './Dashboard.js'
import Upload from './DataUpload.js'
import ResetPasswordRequest from './ResetPasswordRequest.js';
import Network from './NetworkOptimization.js'
import Inventory from './InventoryOptimization.js'
import Demand from './DemandAnalyzer.js';
import Simulator from './Simulator.js';
import Partners from './Partners.js';
import Resources from './YourResources.js';
import Replenishment from './Replenishment.js';
import Fulfillment from './Fulfillment.js';
import Evaluation from './EvaluationDashboard.js';
import MachineLearning from './MachineLearning.js';
import EdonAPI from './EdonAPI.js';
import IoT from './IoT.js';
import InventoryManagement from './InventoryManagementPage.js';
import SignIn from './SignIn.js';
import SignUp from './SignUp.js';
import PasswordSet from './PasswordSet.js';

import WarehouseScanning from './WarehouseScanningPage.js'


//autho db
import UserContext from "./context/UserContext";
// import firebase from "./firebase.js";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const DSN_SENTRY = process.env.SENTRY_DSN;
// const BACKEND_URL = process.env.RBACKEND_URL;
const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;



//! data 5/31/2023
//!Sentry Error Tracking
Sentry.init({
  dsn: DSN_SENTRY,
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  //! Video Replay
  replaysOnErrorSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()]
});

// axios.defaults.baseURL = REACT_APP_BACKEND_URL;

REACT_APP_BACKEND_URL ? axios.defaults.baseURL = REACT_APP_BACKEND_URL:
  axios.defaults.baseURL = 'http://127.0.0.1:8000';

//! uncomment before build
//! uncomment before build
//! uncomment before build
axios.defaults.baseURL = 'https://edon-backend.herokuapp.com/';
//! uncomment before build
//! uncomment before build
//! uncomment before build
//! uncomment before build

// Platform Admin: 10
// Member Admin: 8
// Account Admin: 6
// User: 4
// Viewer: 2


function App() {

  //$ user context
  // const userTry = React.useContext(UserContext);

  const linkURL = 'http://edonconnect.tech/#/PasswordSet'
  // const linkURL = 'http://localhost:3000/#/PasswordSet'
  const mobileSite = 'https://edon-connect-mobile-backend.herokuapp.com/'
  // const mobileSite = 'http://127.0.0.1:8000/'
  
  const [ user, setUser ] = useStorageState(sessionStorage, "state-user", {});
  const [ accessToken, setAccessToken ] = useStorageState(sessionStorage, "access-user", {});
  const [ headers, setHeaders ] = useStorageState(sessionStorage, "headers", {});
  // const history = useHistory();



  // const [ userInfo, setUserInfo ] = React.useState({});

  const [ badLogin, setBadLogin ] = React.useState(false);
  const [ emailLink, setEmailLink ] = React.useState();
  const [ emailSent, setEmailSent ] = React.useState(false);

  //! Set a cutoff after a certain
 //!Change the sign on from the database
  React.useEffect(() => {
    var currentTime = new Date();
    const timeSinceLastSignOn = currentTime - Date.parse(user.lastSignOnDate);
    var daysSinceLastSignon = timeSinceLastSignOn / 86400000
    //! if the user has not signed in in two days sign them off
    // console.log("Context", userTry)
    // console.log("User", user)
    // console.log("Token", accessToken)
    // console.log("Headers start", accessToken)
    if (daysSinceLastSignon > 2){
      setUser({isAuthenticated:false});
      }; // eslint-disable-next-line no-console
  },// eslint-disable-next-line no-console
  [])



  const onLogin = (email, password) => {

    var lastSignOnDate = new Date();
    updatePlatformUserSignOnDate(email);
    // logInToken(email, lastSignOnDate); //!This sets the user info to Session Storage
    logInToken(email, password, lastSignOnDate); //!This sets the user info to Session Storage

  };

  //SECTION Axios getPlatform User
  const logInToken = async (email, password) => {

    const auth = {
      user: email,
      password: password,
    }
    await axios.post(`LogIn/login`, auth )
      .then(function (response) {
        // console.log("getPlatform UserInfo", response.data)
        setAccessToken(response.data.access_token)
        setUser({
          email: response.data.userEmail,
          isAuthenticated: true,
          lastSignOnDate: response.data.userLastSignOnDate,
          role: response.data.userRole,
          roleName: response.data.roleName,
          accountCode: response.data.accountCode,
          services: response.data.services,
          membershipCode: response.data.membershipCode,
          firstName: response.data.userFirstName,
          lastName: response.data.userLastName,
          company: response.data.userCompany,
          accountViewer: response.data.accountViewer, 
          membershipViewer: response.data.membershipViewer, 
        });
        const authorization = "Bearer " + response.data.access_token
        // console.log("Token", response.data.access)
        setHeaders({
          'Content-Type': 'application/json',
          'Authorization': authorization
        });
      })
      .catch(function (error) {
        // handle error
        alert("The password or email is not in the platform system. Please try again or contact your adminstrator.")
        setBadLogin(true);
      })
      .then(function () {
        // console.log("App Headers", headers)
      });
  }
//!SECTION


  // const handleEmailLink = () => {
  //   var linkEmail = window.localStorage.getItem('emailForSignIn')
  //   // console.log("localStorage", window.localStorage.getItem('emailForSignIn'));
  //   //!This worked.  window.location.href returns the url of the current window
  //   // console.log("worked?", firebase.auth().isSignInWithEmailLink(window.location.href));
  //   if (firebase.auth().isSignInWithEmailLink(window.location.href)){
  //     // console.log("local Storage", window.location.href)
  //     // console.log("Local Email", linkEmail )
  //     setEmailLink(linkEmail);
  //     // localStorage.removeItem('emailForSignIn');
  //   }
  // };

  const handleEmailSent = () => {
    setEmailSent(false);
  }


// //!SECTION

  //SECTION Axios getPlatform User Sign On Data
  const updatePlatformUserSignOnDate = async (email) => {
    await axios.put(`LogIn/platformusersignondate/${email}`)
      .then(function (response) {
        // console.log("Sign on date", response.data)
      })
      .catch(function (error) {
        // handle error
        setBadLogin(true);
      })
      .then(function () {
        // always executed
      });
    }
  //!SECTION


  const onRegister = (userEmail, securityCode) => {
    window.sessionStorage.setItem('registerationEmail', userEmail );
    window.sessionStorage.setItem('registerationSecurityCode', securityCode );
    window.location.href=linkURL; //! send the user to the linkURL "password set page"
  }
    
  //! Create and email
  // const sendEmailVerification = async (email) => {
  //   var actionCodeSettings = {
  //     url:linkURL,
  //     handleCodeInApp: true,
  //   };
  //   await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
  //     .then((response) => {
  //       // console.log('email to google', email)
  //       setEmailSent(true);
  //       window.localStorage.setItem('emailForSignIn', email );
  //       // history.push("/"); //! Need to figure out how to get the user to the login page
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     })
  //     .then(function(){
  //       // console.log("Made it through")
  //     });
  //   }

  
  const changeDatabasePassword = async ( data) => {
    // console.log("Change Password", data)
    await axios.put(`security/platformuserchangepassword`, data)
    .then(function(response){
      alert(`Password set for ${data.email}.  Please login.`)
      // console.log("Password Set", response.data)
      setPasswordSet(true);
      // localStorage.removeItem('emailForSignIn');

      sessionStorage.removeItem('registerationEmail');
      sessionStorage.removeItem('registerationSecurityCode');
      gotoSignIn();
    })
    .catch((error) => {
      var errorMsg = error.response.data.detail
      setBadLogin(true);
      alert(`Could not set the password on platform. ${errorMsg}  Please contact your administrator.`)
      // sessionStorage.removeItem('registerationEmail');
    })
  }

  const gotoSignIn = () => {
    window.location.href="/";
  }


  const [passwordSet, setPasswordSet] = React.useState(false);

  const createUserPassword = async ( password) => {
    // console.log("Create User Password", email, password)
    const data = {
      "email": sessionStorage.getItem("registerationEmail"),
      "password": password,
      "securityCode": sessionStorage.getItem("registerationSecurityCode")
    }
    changeDatabasePassword(data);
  }



  // const resetPasswordEmail = async (email) => {
  //   // alert(`Reset email sent to: ${email}`)
  //   // resetPasswordFlag(email); //! signal database that the password was changed
  //   console.log("Reset Password", email)
  //   const data = {
  //     "requestingUser": email
  //   }
    
  //   await axios.post(`notifications/requestpasswordresetemail`, data)
  //   .then(function(response){
  //     alert(`Reset email sent to: ${email}`)
  //     console.log(response.data.message);
  //   })
  //   .catch(function(error){
  //     if (error.response.status === 401){
  //       onLogout();
  //     };
  //     alert(`Could not send reset email to: ${email}, ${error.response.detail}`)
  //   })
  // }


  // const resetPasswordFlag = async (email) => {
  //   await axios.put(`security/resetpasswordflag/${email}`)
  //   .then(function(response){
  //     // console.log(response.data);
  //   })
  //   .catch((error) => {
  //     alert(error);
  //   })
  //   .then(function(){
  //   }); 
  // }

  const [openHeaderDrawer, setOpenHeaderDrawer] = React.useState(true);
  const handleOpenHeaderDrawer = () => {
    setOpenHeaderDrawer(!openHeaderDrawer);
  };




  // I put in the hist push when this is called
  const onLogout = () => {

    localStorage.clear();
    setUser({ isAuthenticated: false });
    setAccessToken({});
    setHeaders({});

    // firebase
    // .auth()
    // .signOut()
    // .then(() => {
    //   localStorage.clear();
    //   setUser({ isAuthenticated: false });
    //   setAccessToken({});
    //   setHeaders({});
    //   // window.location= '/SignIn';
      
    //   //console.log(process.env.REACT_APP_SWEAR);
    // })
    // .catch((error) => console.error(error));

  };

  
  return (

  <HashRouter >
    <UserContext.Provider value = {{onLogin,onLogout}}> 

    <Switch>

      <Route exact 
        path="/" 
        render={() => 
          (!user.isAuthenticated) ? <SignIn user={user}  /> : <Dashboard user={user}  headers={headers} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
        } />


      <Route exact 
        path="/Dashboard"
        render={() => 
          (!user.isAuthenticated) ? <SignIn user={user}   /> : <Dashboard user={user} headers={headers} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer}  />
        } />
      <Route exact 
        path="/SignIn"
        render={() => 
          (!user.isAuthenticated) ? <SignIn user={user}   /> : <Dashboard user={user} headers={headers} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer}   />
        } />


      <Route exact 
        path="/PasswordSet"
        render={() => 
          // (passwordSet) ? <SignIn user={user} resetPasswordEmail={resetPasswordEmail}  /> :
          <PasswordSet createUserPassword={createUserPassword} logInToken={logInToken}  emailLink={emailLink} passwordSet={passwordSet} />
        } />

      <Route exact 
        path="/SignUp"
        render={() => 
          <SignUp emailSent={emailSent} handleEmailSent={handleEmailSent} onRegister={onRegister}  />
        } />

      <Route exact 
        path="/ResetPasswordRequest"
        render={() => 
          <ResetPasswordRequest emailSent={emailSent} handleEmailSent={handleEmailSent} onRegister={onRegister}  />
        } />

      <Route exact 
        path="/inventory"
        render={() => 
          (!user.isAuthenticated) ? <SignIn user={user}   /> : <Inventory user={user} headers={headers} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
        } />

      <Route exact 
        path="/upload"
        render={() => 
          (!user.isAuthenticated) ? <SignIn   user={user}  /> : <Upload user={user} headers={headers} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer}/>
        } />

      <Route exact 
        path="/network"
        render={() => 
          (!user.isAuthenticated) ? <SignIn onLogin={onLogin} user={user}   /> : <Network user={user} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} headers={headers} />
        } />

      <Route exact 
        path="/demand"
        render={() => 
          (!user.isAuthenticated) ? <SignIn onLogin={onLogin}  user={user}  /> : <Demand user={user} headers={headers} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
        } />

      <Route exact 
        path="/simulator"
        render={() => 
          (!user.isAuthenticated) ? <SignIn  user={user}  /> : <Simulator openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} user={user} />
        } />

      <Route exact 
        path="/partners"
        render={() => 
          (!user.isAuthenticated) ? <SignIn  user={user}  /> : <Partners headers={headers} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
        } />

      <Route exact 
        path="/resources"
        render={() => 
          (!user.isAuthenticated) ? <SignIn   user={user} /> : <Resources openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer}  />
        } />

      <Route exact 
        path="/inventoryTracking"
        render={() => 
          (!user.isAuthenticated) ? <SignIn  user={user}  /> : <InventoryManagement  headers ={headers} user={user} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} /> //warehouseScanningpage
        } />

      <Route exact 
        path="/replenishment"
        render={() => 
          (!user.isAuthenticated) ? <SignIn   user={user}  /> : <Replenishment user={user}openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
        } />

      <Route exact 
        path="/fulfillment"
        render={() => 
          (!user.isAuthenticated) ? <SignIn user={user}  /> : <Fulfillment user={user} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer}/>
        } />

      <Route exact 
        path="/CycleCount"
        render={() => 
          (!user.isAuthenticated) ? <SignIn  user={user}  /> : <WarehouseScanning  headers= {headers} user={user} mobileSite={mobileSite} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer}/> 
        } />
      <Route exact 
        path="/iot"
        render={() => 
          (!user.isAuthenticated) ? <SignIn  user={user}  /> : <IoT  user={user}openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer}/> //IoT
        } />
        <Route exact 
          path="/evaluation"
          render={() => 
            (!user.isAuthenticated) ? <SignIn  user={user}  /> : <Evaluation user={user} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
          } />
      <Route exact 
        path="/MachineLearning"
        render={() => 
          (!user.isAuthenticated) ? <SignIn   user={user}  /> : <MachineLearning user={user} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
        } />
      <Route exact 
        path="/EdonAPI"
        render={() => 
          (!user.isAuthenticated) ? <SignIn  user={user}  /> : <EdonAPI user={user} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
        } />

        
      {/* <Route 
        path="/"
        render={()=>
          (!user.isAuthenticated) ? <SignIn resetPasswordEmail={resetPasswordEmail}  /> : <Redirect to = "/" />
          // (!user.isAuthenticated) ? <SignIn resetPasswordEmail={resetPasswordEmail}  /> : <Redirect to = "/#/" />
        }
        /> */}


    </Switch>
    </UserContext.Provider>


  </HashRouter>


  );
}

export default App;


