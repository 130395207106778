import * as React from 'react';

import { DataGridPro, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';

import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function CurrentMobileUserAccess(props) {

    const nonAdminUser = props.user.role !== 10 ? true : false;
    const [selectedModel, setSelectedModel ] = React.useState();
    const  currentWarehouseShelfs  = {
      columns: [
      {field: 'id', hide: false},
      {field: 'id', headerName: 'Warehouse', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'actions', type: 'actions', headerName: 'Remove Access', width:150 ,  
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} onClick={props.removeWarehouseAccess(params.id, props.selectedMobileUser)}  label="Delete" />
      ]},
      {field: 'address', headerName: 'Address', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'city', headerName: 'City', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'state', headerName: 'State', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'zip', headerName: 'Zip', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'type', headerName: 'Type', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'market', headerName: 'Market', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'region', headerName: 'Region', width:200, headerAlign: 'center', align: 'center',  editable: false},

      ], 
      rows: props.mobileUserWarehouseAccess
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 

        showCellRightBorder
        showColumnRightBorder
        showCellLeftBorder
        
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...currentWarehouseShelfs} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};