import * as React from 'react';
import axios from 'axios';

import { DataGrid, GridToolbar, GridActionsCellItem  } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { LicenseInfo } from '@mui/x-data-grid-pro';

// import { DataGridPro } from '@mui/x-data-grid-pro';
import MessageSnackBar from './MessageSnackBar';

const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);




export default function SavedNetOptzModels(props) {

    //SECTION Message Bar Controls
    const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
    const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
    const [ messageSeverity, setMessageSeverity ] = React.useState('success')
    const handleMessageBarClosing = () => {
      setMessageBarOpen(false);
    };
    //!SECTION
    
    const [selectedModel, setSelectedModel ] = React.useState('None');


    const  SavedNetworkOptzModels  = {
        columns: [
        {field: 'id', hide:true},
        {field: 'file', headerName: 'File', minWidth: 200, align: 'center', headerAlign: 'center', editable: false},
        {field: 'memberCompany', headerName: 'Member Company', hide:(props.user.role<10), width:200, editable: false},
        {field: 'accountCompany', headerName: 'Account Company', hide:(props.user.role<8),width:200, editable: false},
        {field: 'warehousefile', headerName: 'Warehouse File',align: 'center', minWidth: 300, headerAlign: 'center', editable: false},
        {field: 'market', headerName: 'Market', minWidth: 250,align: 'center', headerAlign: 'center', editable: false},
        {field: 'statwarehousecount', headerName: 'Warehouse Results', align: 'center',minWidth: 150,  editable: false, headerAlign: 'center', type: 'number'},
        {field: 'statavgdistance', headerName: 'Distance Results', align: 'center',minWidth: 150,  editable: false, headerAlign: 'center', type: 'number'},
        {field: 'statservicelevel', headerName: 'Service Level Results', align: 'center',width: 170,  editable: false, headerAlign: 'center', type: 'number'},
        {field: 'userdistance', headerName: 'Distance', flex:1, align: 'center',minWidth: 100, editable: false, headerAlign: 'center', type: 'number'},
        {field: 'userwarehouses', headerName: 'Warehouses', align: 'center',minWidth: 125,  editable: false, headerAlign: 'center', type: 'number'},
        {field: 'userservicelevel', headerName: 'Service Level', align: 'center',minWidth: 125,  editable: false, headerAlign: 'center', type: 'number'},
        {field: 'actions', hide:(props.user.role<6), type: 'actions', align: 'center',headerName: 'Action', minWidth: 125,  
          getActions: (params) => [
            <GridActionsCellItem icon={<DeleteIcon />} onClick={deleteNetModel(params.id)}  label="Delete" />
          ]},
        {field: 'saved', headerName: 'Saved', align: 'center',minWidth: 100,  editable: false, headerAlign: 'center', type: 'number'},
        {field: 'viewed', headerName: 'Viewed', align: 'center',minWidth: 100,  editable: false, headerAlign: 'center', type: 'number'},
        ],
      rows: props.savedNetOptzModelsData
      };

      //SECTION Axios Delete Net Model
      const deleteNetModel = React.useCallback( (sentID) => async () => {
        const user = props.user.email;
        const index = props.savedNetOptzModelsData.map(model => model.id).indexOf(sentID)
        // const file = props.savedNetOptzModelsData[index].file; 
        // const market = props.savedNetOptzModelsData[index].market; 
        // const maxdistance = props.savedNetOptzModelsData[index].userdistance;
        // const maxwarehouses = props.savedNetOptzModelsData[index].userwarehouses;
        // const requestedservicelevel = props.savedNetOptzModelsData[index].userservicelevel;
        // console.log(props.savedNetOptzModelsData[index].netmodelID)
        const netmodelID = props.savedNetOptzModelsData[index].netmodelID
        const collection = "netModel";
        // console.log(props.savedNetOptzModelsData[index].statwarehousecount)
        await axios.delete(`/deleteplatformmodel/${user}/${collection}/${netmodelID}/`)
        .then(function (response) {
          // handle success
          alert(response.data)
          props.resetSavedNetworkOptimizations();
        })
        .catch(function (error) {
          // handle error
          // console.log(error);
          //!Message bar
          setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        })
        .then(function () {
          // always executed
        });

      },[props], );
      //!SECTION




  return (
  
    <div style={{ height: 400, width: '100%' }}>
      <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
      <DataGrid
        // rows={rows}
        // columns={columns}
        pinnedColumns= {{pinnedColumns: {left:['file', 'market'] } } }
        // initialState={{ pinnedColumns: { left: ['name'], right: ['actions'] } }}
        
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
        props.handlegetsavedmodel(newSelectionModel);
      }}
      selectedModel={selectedModel}
      {...SavedNetworkOptzModels} 
      components={{
        Toolbar: GridToolbar,
      }}


      />
    </div>




    // <div style={{ height: 400, width: '100%' }}>
    //   <DataGridPro
    //   onSelectionModelChange={(newSelectionModel) => {
    //     setSelectedModel(newSelectionModel)
    //     props.handlegetsavedmodel(newSelectionModel);
    //     //props.handleMarketFileNames(newSelectionModel)
    //   }}
    //   selectedModel={selectedModel}
    //   {...SavedNetworkOptzModels} 
    //   components={{
    //     Toolbar: GridToolbar,
    //   }}
    //   />
    // </div>

  );
};