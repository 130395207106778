import * as React from 'react';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// import axios from 'axios';

import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
// import MenuItem from '@mui/material//MenuItem';

// import { createTheme, ThemeProvider } from '@mui/material/styles';

import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert';
import MessageSnackBar from './MessageSnackBar';
// import ReplenishmentShipmentGrid from './ReplenishmentShipmentGrid';

import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import ATLSaltBox from '../img/ATLSaltbox.png'
// import { PersonPinCircleRounded } from '@mui/icons-material';






// const theme = createTheme();

// const darkTheme = createTheme({
//     palette: {
//       mode: 'dark',
//     },
//   });

export default function CustomerOrderDetail(props) {
  //! Local SnackBar Messaging
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  //SECTION Message Bar Controls
  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION



  


    const [ serialNumberOption, setSerialNumberOption ] = React.useState(true);
    const [ productQuantityOption, setProductQuantityOption ] = React.useState(false);


   //SECTION Form Validation
    //! Form Validation
    const [ buttonDisable, setButtonDisable ] = React.useState(true);
    const [ validFields, setValidFields ] = React.useState(
      {
        "productId": false,
        "serialNum": false,
        "quantity": false,
        "userFirstName": false,
        "userLastName": false,
        "userEmail": false,
        
    });
    const [ helperText, setHelperText ] = React.useState(
        {
        "productId":"SKU Number",
        "serialNum":"Unique Identifier",
        "quantity": false,
        "userFirstName":"",
        "userLastName":"",
        "userEmail":"",
       });
    //    NOTE Review
    const [ validFirstName, setValidFirstName ] = React.useState(false);
    const [ validLastName, setValidLastName ] = React.useState(false);
    const [ validEmail, setValidEmail ] = React.useState(false);
    const [ validSeletedRole, setValidSelectedRole ] = React.useState(false);
    const [ validSeletedAccount, setValidSelectedAccount ] = React.useState(false);


    const [ validProductNumber, setValidProductNumber ] = React.useState(true);
    const [ validSerialNumber, setValidSerialNumber ] = React.useState(true);


    // const forceUpdate = React.useCallback(() => setValidField(!validField),[]);

    const formValidation =  (e) => {
      e.preventDefault();
      // console.log(e.target.id);
      // console.log(e.target.value);
      switch (e.target.id){

        case 'trackingNum': 
          var trackingNum = e.target.value;
          // console.log(trackingNum)
          if (trackingNum.length >8){
            props.handleTrackingNumber(trackingNum)
          }
          // var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          // var emailValid = email.match(pattern);
          //NOTE Build Tracking verfications with carrier apis
          break;

        case 'userFirstName': 
          if (e.target.value.length > 1){
            setValidFields(prevState => ({
              ...prevState,
              "userFirstName": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                userFirstName: "",
                }));
              setValidFirstName(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "userFirstName": true}));
              setHelperText(prevState => ({
                ...prevState,
                userFirstName: "First Name needs at least two characters",
                }));
              setValidFirstName(false)
          }
          break;

        case 'productId': 
        // NOTE check to see if it is a valid Product Id
          if (e.target.value.length > 1){
            setValidFields(prevState => ({
              ...prevState,
              "productId": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                productId: "",
                }));
              setValidProductNumber(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "productId": true}));
              setHelperText(prevState => ({
                ...prevState,
                productId: "This is not a valid Product Number",
                }));
              setValidProductNumber(false)
          }
          break;
        case 'serialNum': 
          if (e.target.value.length > 1){
            setValidFields(prevState => ({
              ...prevState,
              "serialNum": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                serialNum: "",
                }));
              setValidSerialNumber(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "serialNum": true}));
              setHelperText(prevState => ({
                ...prevState,
                serialNum: "This is not a valid Serial Number",
                }));
              setValidSerialNumber(false)
          }
          break;

        default:
          break;
      }
    }

    // NOTE Review
    React.useEffect(() => {
      if (validProductNumber && validSerialNumber ) {
        setButtonDisable(false);
      }else{
        setButtonDisable(true);
      }
    },[ validProductNumber, validSerialNumber ]
    )
    //!SECTION

    const [ products, setProducts ] = React.useState([])

    const handleRemoveProduct = async (currentProducts) => {
        setProducts([...currentProducts])
    };

    //NOTE review

  
  
  //!SECTION Show Destination address
  

  const locationData = props.locationInfo[props.customerLocationId]

//!SECTION select Destination
  // const imageName = locationInfo[deliveryId].image;

  return (
    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 5,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      <Stack direction="column" alignItems="center" spacing={2}>
        <Typography component="h1" variant="h5">
          Customer Order Information
        </Typography>
          <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
          <Snackbar
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              {snackBarMessage}
            </MuiAlert>
          </Snackbar>

        {true &&
            <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                    component="img"
                    height="140"
                    image= {ATLSaltBox} //Need to replace with an image from a database
                    alt="green iguana"
                />
                <CardContent>
                    <Typography align="center" gutterBottom variant="h5" component="div">
                    {locationData.name}
                    </Typography>
                    <Typography variant="h6" align="left" color="text.secondary">
                    Address:
                    </Typography>
                    <Typography variant="h6" align="left" color="text.secondary">
                    {locationData.address}
                    </Typography>
                    <Typography variant="h6" align="left" color="text.secondary">
                    {locationData.cityStateZip} 
                    </Typography>
                </CardContent>
            </Card>
        }


      </Stack>

    </Grid>
  );
}