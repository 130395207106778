import * as React from 'react';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Paper from '@mui/material/Paper';

import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import EdonWidget from './img/edon Connect Logo only.png'
// import MuiLink from '@mui/material/Link'
//! signing on

// import { useStorageState } from "react-storage-hooks";

// import UserContext from "./context/UserContext";
// import userEvent from '@testing-library/user-event';
import { useHistory } from "react-router-dom";
import Copyright from './Components/Copyright.js';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';




const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert sx={{width: '100%'}} elevation={6} ref={ref} variant="filled" {...props} />;
  });

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

const vertical ='top';
const horizontal ='center';

export default function PasswordSet(props) {




    const history = useHistory();



    const [passwordError, setPasswordError ] = React.useState(false);
    const [ passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
    const [ dialogOpen, setDialogOpen ] = React.useState(false);
    const [ enteredEmail, setEnteredEmail ] = React.useState('');
    // const [ confirmedEmail, setConfirmedEmail ] = React.useState('');
    const [ confirmedPassword, setConfirmedPassword ] = React.useState('');


  
    // const handleCloseDialog = (event) => {
    //   setDialogOpen(false);
    //   // console.log(enteredEmail, confirmedPassword);
    //   props.createUserPassword( enteredEmail, confirmedPassword);
    //   // history.push('/');
    // };

    const onEmailChange = (event) => {
      setEnteredEmail(event.target.value);
    }
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setPasswordError(false);
    };

    const [ buttonDisable, setButtonDisable ] = React.useState(true);
    const [ validPassword, setValidPassword ] = React.useState(false);
    const [ badPassword, setBadPassword ] = React.useState(false);   
    const [ validPassword2, setValidPassword2 ] = React.useState(false);   
    const [ badPassword2, setBadPassword2 ] = React.useState(false);  

    const formValidation =  (e) => {
      e.preventDefault();
      // console.log(e.target.id);
      // console.log(e.target.value);
      switch (e.target.id){
        case 'password': 
          var password = e.target.value;
          // var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          // var emailValid = email.match(pattern);

          if (password.length >8){
            // setValidFields(prevState => ({
            //   ...prevState,
            //   "userEmail": false,
            //   }));
            //   setHelperText(prevState => ({
            //     ...prevState,
            //     userEmail: "",
            //     }));
              setValidPassword(true);
              setBadPassword(false)
            }else{
              // setValidFields(prevState => ({
              //   ...prevState,
              //   "userEmail": true}));
  
              // setHelperText(prevState => ({
              //   ...prevState,
              //   userEmail: "Need a valid email adress",
              //   }));
              setValidPassword(false);
              setBadPassword(true);
          }
          break;
        case 'password2': 
          if (e.target.value.length > 8){
              setValidPassword2(true);
              setBadPassword2(false);            
            }else{
              setValidPassword2(false)
              setBadPassword2(true);            
          }
          break;
        default:
          break;
      }
    }
  
    React.useEffect(() => {
      if (validPassword && validPassword2) {
        setButtonDisable(false);
      }else{
        setButtonDisable(true);
      }
    },[ validPassword, validPassword2]
    )

    
    const handleSignIn = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const password = data.get('password')
        const password2 = data.get('password2')
        const passwordLength = password.length;
        var email = props.emailLink;
        // console.log("Email", props.userRegisterEmail)
        //! Check the password
        var error = false;
        if (password){
          const samePassword = (password === password2);
          if(passwordLength<8){
            setPasswordErrorMessage("Passwords need to be at least 8 characters.");
            setPasswordError(true);
            error = true;
          }
          if(!samePassword){
            setPasswordErrorMessage("Passwords do not match.");
            setPasswordError(true);
            error = true;
          }
        };

        if (!error){
          props.createUserPassword(password);
        }
    };



  return (

    <ThemeProvider theme={darkTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: '#68a0d7' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <img src={EdonWidget} width={50}  alt="Logo" />
          <Typography component="h1" marginTop={2} variant="h5">
            Set Password
          </Typography>

            {/* Dialog box in case the email is not in local storage */}
            {/* <Dialog open={dialogOpen} onClose={handleClose}>
              <DialogTitle>Verify Your Email</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please enter your email address here. 
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="emailEntered"
                  label="Email Address"
                  type="emailEntered"
                  fullWidth
                  variant="standard"
                  onChange={onEmailChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Submit Email</Button>
              </DialogActions>
            </Dialog> */}

          <Box component="form" onSubmit={handleSignIn} noValidate sx={{ mt: 1 }}>

            <Snackbar sx={{width: '50%'}} anchorOrigin={{vertical, horizontal}} open={passwordError} autoHideDuration={6000} onClose={handleClose}>
              <Alert sx={{width: '100%'}}  onClose={handleClose} severity="error">{passwordErrorMessage}</Alert>
            </Snackbar>


            <TextField
              error={badPassword}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => formValidation(e)}
              />
            <TextField
              error={badPassword2}
              margin="normal"
              required
              fullWidth
              name="password2"
              label="Repeat Password"
              type="password"
              id="password2"
              autoComplete="current-password"
              onChange={(e) => formValidation(e)}
            />

            <Button
              align="center"
              disabled={buttonDisable}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }} 
            >
              Set Password
            </Button>
            <Grid container>

              {false &&
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
                }

              <Grid sm={12} md={12} lg={12} border={0} item>

              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
);
}