import * as React from "react";

import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import axios from "axios";

// import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
// import MenuItem from "@mui/material//MenuItem";

// import { createTheme, ThemeProvider } from "@mui/material/styles";

// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// import MessageSnackBar from "./MessageSnackBar";

// import ATLSaltBox from "../img/ATLSaltbox.png";

//NOTE Children
import CustomerOrderGrid from "./CustomerOrderGrid.js";

// const theme = createTheme();

// const darkTheme = createTheme({
//   palette: {
//     mode: "dark",
//   },
// });

export default function CreateAccount(props) {
  // //SECTION Local SnackBar Messaging
  // const [snackBarMessage, setSnackBarMessage] = React.useState("");
  // const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setSnackbarOpen(false);
  // };

  // //SECTION Message Bar Controls
  // const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  // const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  // const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  // const handleMessageBarClosing = () => {
  //   setMessageBarOpen(false);
  // };
  //!SECTION

  //SECTION Form Validation
  //! Form Validation
  const [buttonDisable, setButtonDisable] = React.useState(true);
  const [buttonCompleteDisable, setButtonCompletDisable] = React.useState(true);

  const [validFields, setValidFields] = React.useState({
    productId: false,
    serialNum: false,
    quantity: false,
    userFirstName: false,
    userLastName: false,
    userEmail: false,
    customerLocationId: false,
    qty: false,
  });
  const [helperText, setHelperText] = React.useState({
    productId: "SKU Number",
    qty: "Quantity the product to order",
    customerLocationId: "Location where the order will be delivered or used",
    serialNum: "Unique Identifier",
    userFirstName: "",
    userLastName: "",
    userEmail: "",
  });
  //    NOTE Review

  const [validProductNumber, setValidProductNumber] = React.useState(true);
  const [validQuantity, setValidQuantity] = React.useState(true);
  const [validCustomerLocationId, setValidCustomerLocationId] =
    React.useState(false);

  const [productQuantity, setProductQuantity] = React.useState();
  const [productId, setProductId] = React.useState();

  // const forceUpdate = React.useCallback(() => setValidField(!validField),[]);

  const formValidation = (e) => {
    e.preventDefault();
    // console.log(e.target.id);
    // console.log(e.target.value);
    switch (e.target.id) {
      case "customerLocationId":
        if (e.target.value.length > 4) {
          setValidFields((prevState) => ({
            ...prevState,
            customerLocationId: false,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            customerLocationId: "",
          }));
          setValidCustomerLocationId(true);
          setcustomerLocationId(e.target.value);

          props.handleSelectCustomerLocationId(e.target.value);
        } else {
          setValidFields((prevState) => ({
            ...prevState,
            customerLocationId: true,
          }));

          setHelperText((prevState) => ({
            ...prevState,
            customerLocationId: "Not a valid Location Id",
          }));
          setValidCustomerLocationId(false);
          setcustomerLocationId(e.target.value);
        }
        break;

      case "qty":
        if (e.target.value > 0 && e.target.value < 20) {
          setValidFields((prevState) => ({
            ...prevState,
            qty: false,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            qty: "",
          }));
          setValidQuantity(true);
          setProductQuantity(e.target.value);
        } else {
          setValidFields((prevState) => ({
            ...prevState,
            qty: true,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            qty: "Make sure the quantity is correct",
          }));
          setValidQuantity(false);
          setProductQuantity(e.target.value);
        }
        break;

      case "productId":
        // NOTE check to see if it is a valid Product Id
        if (e.target.value.length > 1) {
          setValidFields((prevState) => ({
            ...prevState,
            productId: false,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            productId: "",
          }));
          setValidProductNumber(true);
          setProductId(e.target.value);
        } else {
          setValidFields((prevState) => ({
            ...prevState,
            productId: true,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            productId: "This is not a valid Product Number",
          }));
          setValidProductNumber(false);
          setProductId(e.target.value);
        }
        break;

      default:
        break;
    }
  };

  // NOTE Review
  React.useEffect(() => {
    if (validProductNumber && validQuantity) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [validProductNumber, validQuantity]);

  //!SECTION

  // SECTION General Customer Order Fields
  const [acquistionType, setAquistionType] = React.useState("pickup");

  const handleAcquistionTypeCheckbox = () => {
    if (acquistionType) {
      setAquistionType("pickup");
    } else {
      setAquistionType("delivery");
    }
  };
  const [customerLocationId, setcustomerLocationId] = React.useState([]);
  // !SECTION General Customer Order Fields

  const [products, setProducts] = React.useState([]);

  const handleRemoveProduct = async (currentProducts) => {
    setProducts([...currentProducts]);
  };

  const handleCompleteShipment = async () => {
    // console.log("Thank you");
    const owner = "edon_owner";
    const user = "glenn.caudill@edonconnect.com";
    const utilizingAddress =
      props.locationInfo[props.customerLocationId].utilizingAddress;
    const min = Math.ceil(1000);
    const max = Math.floor(9999);
    const orderNum = `DB_ATL_${Math.floor(Math.random() * (max - min) + min)}`;

    const productNumbers = [products[0].productId];

    const data = {
      orderNum: orderNum,
      acquistionType: acquistionType,
      ownersCustomerId: orderNum,
      productNum: productNumbers,
      quantity: 1,
      utilizingAddress: utilizingAddress,
      orderCreatedBy: user,
    };

    await axios({
      method: "post",
      url: `/iot/createCustomerOrder/${owner}/${user}`,
      data: data,
    })
      .then(function async (response) {
        // Message about the User account
     
        // console.log(response.data);
        props.handleSnackBar(response.data.message, "success");
        // alert(response.data.message)
        props.handleCloseCreateCustomerOrders();
        // console.log(response.data);
        // setSnackBarMessage(response.data);
        // setSnackbarOpen(true);
      })
      .catch(function (error) {
        // handle error
        // console.log(error.response.data.detail);
        var errorMessage = `Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.response.data.detail}`;
        props.handleSnackBar(errorMessage, "error"); // error: red, warning: orange, information: blue, success: green
        // !Message bar

      })
      .then(function (response) {
        // Send the email
      });
  };

  //SECTION Add Product
  const handleAddProduct = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const productId = formData.get("productId");
    const quantity = formData.get("qty");
    var newId = products.length + 1;

    var addProduct = {
      id: newId,
      productId: productId,
      quantity: quantity,
    };

    // console.log(addProduct);
    setProducts((prevState) => [...prevState, addProduct]);
    setButtonCompletDisable(false);
  };
  //!SECTION

  return (
    <Box
      sx={{
        border: 1,
        justifyContent: "left",
        alignContent: "center",
        p: 5,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      <Grid
        container
        sx={{
          p: 1,
          justifyContent: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Order Location Id
        </Typography>

      </Grid>

      <Grid container sx={{ p: 2 }}>
        <TextField
          error={validFields.customerLocationId}
          helperText={helperText.customerLocationId}
          margin="normal"
          required
          value={customerLocationId}
          fullWidth
          name="customerLocationId"
          label="Customer Location Id"
          type="text"
          id="customerLocationId"
          onChange={(e) => formValidation(e)}
        />
      </Grid>
      <FormControlLabel
        control={
          <Checkbox defaultChecked onChange={handleAcquistionTypeCheckbox} />
        }
        label="Picking Up At Warehouse"
      />

      {true && (
        <>
          <Grid sx={{ border: 0, p: 2, pt: 5 }}>
            <Typography component="h1" variant="h5">
              Customer Order
            </Typography>

            <Box
              component="form"
              onSubmit={handleAddProduct}
              noValidate
              fullWidth
              sx={{ mt: 1, border: 0 }}
            >
              <TextField
                error={validFields.productId}
                helperText={helperText.productId}
                margin="normal"
                required
                fullWidth
                name="productId"
                label="Product Number"
                type="text"
                id="productId"
                onChange={(e) => formValidation(e)}
              />
              <TextField
                error={validFields.qty}
                helperText={helperText.qty}
                // value={productQuantity}
                margin="normal"
                required
                fullWidth
                name="qty"
                label="Quantity"
                type="text"
                id="qty"
                onChange={(e) => formValidation(e)}
              />
              <Button
                disabled={buttonDisable}
                align="left"
                type="submit"
                variant="contained"
                sx={{ mt: 1, mb: 1 }}
              >
                Add Product to Shipment
              </Button>
            </Box>
          </Grid>

          <Grid sx={{ border: 0, pt: 1 }}>
            <CustomerOrderGrid
              gridData={products}
              handleRemoveProduct={handleRemoveProduct}
            />
          </Grid>

          <Grid sx={{ border: 0, pt: 3 }}>
            <Button
              disabled={buttonCompleteDisable}
              align="left"
              type="submit"
              onClick={handleCompleteShipment}
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
            >
              Complete Customer Order
            </Button>
          </Grid>
        </>
      )}
    </Box>
  );
}
