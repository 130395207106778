import * as React from 'react';
import axios from 'axios';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useHistory } from 'react-router-dom';
import EdonWidget from './img/edon Connect Logo only.png'
// import UserContext from "./context/UserContext";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Copyright from './Components/Copyright';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert sx={{width: '100%'}} elevation={6} ref={ref} variant="filled" {...props} />;
  });



// const theme = createTheme();
const vertical ='top';
const horizontal ='center';

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });


export default function ResetPassword(props) {
    
  const pageTitle = 'Reset Password';
  const history = useHistory();

  const [ buttonDisable, setButtonDisable ] = React.useState(true);
  const [ validEmail, setValidEmail ] = React.useState(false);
  const [ badEmail, setBadEmail ] = React.useState(false);
  const [ validSecurityCode, setValidSecurityCode ] = React.useState(false);
  const [ badSecurityCode, setBadSecurityCode ] = React.useState(false);
  const [ validPassword1, setValidPassword1 ] = React.useState(false);
  const [ badPassword1, setBadPassword1 ] = React.useState(false);
  const [ validPasswords, setValidPasswords ] = React.useState(false);
  const [ badPasswords, setBadPasswords ] = React.useState(false);
  const [ password1, setPassword1 ] = React.useState('');
  const [ emailErrorMessage, setEmailErrorMessage ] = React.useState('');
  const [ securityCodeErrorMessage, setSecurityCodeErrorMessage ] = React.useState('');
  const [ password1ErrorMessage, setPassword1ErrorMessage ] = React.useState('');
  const [ password2ErrorMessage, setPassword2ErrorMessage ] = React.useState('');

  const formValidation =  (e) => {
    e.preventDefault();
    // console.log(e.target.id);
    // console.log(e.target.value);
    switch (e.target.id){
      case 'userEmail': 
        var email = e.target.value;
        // eslint-disable-next-line 
        var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        var emailValid = email.match(pattern);
        if (emailValid){
            setEmailErrorMessage('')
            setValidEmail(true);
            setBadEmail(false)
          }else{
            setEmailErrorMessage('Please enter a valid email address.')
            setValidEmail(false);
            setBadEmail(true);
        }
        break;

      case 'securityCode': 
        if (e.target.value.length > 6){
            setValidSecurityCode(true);
            setBadSecurityCode(false);      
            setSecurityCodeErrorMessage('');      
          }else{
            setValidSecurityCode(false)
            setBadSecurityCode(true);       
            setSecurityCodeErrorMessage('Please enter a valid security code.');     
        }
        break;
      case 'password1': 
        if (e.target.value.length > 8){
            setValidPassword1(true);
            setBadPassword1(false);    
            setPassword1(e.target.value);
            setPassword1ErrorMessage('');        
          }else{
            setValidPassword1(false)
            setBadPassword1(true);
            setPassword1ErrorMessage('Passwords must contant eight characters.');            
        }
        break;
      case 'password2': 
        if (e.target.value == password1){
            setValidPasswords(true);
            setBadPasswords(false);
            setPassword2ErrorMessage('');            
          }else{
            setValidPasswords(false);
            setBadPasswords(true);
            setPassword2ErrorMessage('Passwords do not match.');            
        }
        break;

      default:
        break;
    }
  }

  React.useEffect(() => {
    if (validEmail && validSecurityCode && validPassword1 && validPasswords) {
      setButtonDisable(false);
    }else{
      setButtonDisable(true);
    }
  },[ validEmail, validSecurityCode, validPassword1, validPasswords ])
  

  //!SECTION


  const handleResetEmailSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    //! Check to see if email is in the system for this account

    const userEmail = data.get("userEmail"); 
    const securityCode = data.get("securityCode"); 
    const password1 = data.get("password1");
    const password2 = data.get("password2");
    const payload = {  
      "email": userEmail,
      "securityCode": securityCode,
      "password1": password1,
      "password2": password2
    }
    // console.log("Data", payload)

    await axios.put(`/security/platformresetpassword`, payload )
    .then(function (response) {
      alert(`${response.data.message}.  Please sign in.`)
      handleHaveAnAccount();
    })
    .catch(function (error) {
      alert(`Oh no! There was a problem. ${error.response.data.detail}. Try again or notify info@edonconnect.com.`)
    })
  };


  const handleHaveAnAccount = () => {
    history.push('/');
  };


  return (
    <ThemeProvider theme={darkTheme}>
        
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: '#68a0d7' }}>
            <AppRegistrationIcon />
          </Avatar> */}
          <img src={EdonWidget} width={50} margin={40} alt="Logo" />
          <Typography component="h1" marginTop={2} variant="h5">
            {pageTitle}
          </Typography>


          <Box component="form" noValidate onSubmit={handleResetEmailSubmit} sx={{ mt: 3 }}>     
            <Grid container spacing={2}>


              <Grid item xs={12}>
                <TextField
                  error = {badEmail}
                  helperText = {emailErrorMessage}
                  required
                  fullWidth
                  id="userEmail"
                  label="Email Address"
                  name="userEmail"
                  autoComplete="email"
                  type="email"
                  onChange={(e) => formValidation(e)}
                  />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  error = {badSecurityCode}
                  helperText = {securityCodeErrorMessage}
                  required
                  fullWidth
                  id="securityCode"
                  label="Security Code"
                  name="securityCode"
                  onChange={(e) => formValidation(e)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  error = {badPassword1}
                  helperText = {password1ErrorMessage}
                  required
                  fullWidth
                  id="password1"
                  label="Password"
                  name="password1"
                  type="password"
                  onChange={(e) => formValidation(e)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  error = {badPasswords}
                  helperText = {password2ErrorMessage}
                  required
                  fullWidth
                  id="password2"
                  label="Repeat Password"
                  type="password"
                  name="password2"
                  onChange={(e) => formValidation(e)}
                />
              </Grid>
           
            </Grid>
            <Button
              disabled={buttonDisable}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </Button>

            <Grid container justifyContent="center">
              <Grid item>
                <Button fullWidth onClick={handleHaveAnAccount} align='center' variant="body2">
                  Already have an account?
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}