import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
// import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import useMediaQuery from '@mui/material/useMediaQuery';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import UserContext from "../context/UserContext";

import { mainListItems, secondaryListItems } from '../listItems';



import EdonLogo from '../edonLogoWhite.png';




// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});




const drawerWidth = 260;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);




export default function HeaderMenus(props) {

  // const [open, setOpen] = React.useState(true);
  const { onLogout } = React.useContext(UserContext);
  
  const toggleDrawer = () => {
    props.handleOpenHeaderDrawer();
  };

  const handleLogout =() => {
    onLogout();
  }

  // const matches = useMediaQuery('(min-width:800px)');


  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex' }}>
        <AppBar position="absolute" open={props.openHeaderDrawer}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(props.openHeaderDrawer && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            {!props.openHeaderDrawer&&
            <>
            <Divider orientation="vertical" flexItem />
            <img align="center" src={EdonLogo} width={195} alt="Logo" />
            <Divider orientation="vertical" flexItem />
            </>
            }
            <Typography
              paddingLeft={3}
              component="h1"
              variant="h4"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {props.pageTitle}
            </Typography>
              

            <IconButton onClick={handleLogout} color="inherit">
              <Badge color="secondary">
                <ExitToAppIcon />
              </Badge>
              <Typography padding={1}>Sign Out</Typography>
            </IconButton>
            

          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={props.openHeaderDrawer}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <img src={EdonLogo} width={200} alt="Logo" />
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />
          {/* {true &&
          <List>{secondaryListItems}</List>
          } */}
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          
        </Box>
      </Box>
    </ThemeProvider>
  );
}