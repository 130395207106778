import * as React from 'react';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

import axios from 'axios';

import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
// import MenuItem from '@mui/material//MenuItem';

// import { createTheme, ThemeProvider } from '@mui/material/styles';

// import Snackbar from '@mui/material/Snackbar'
// import MuiAlert from '@mui/material/Alert';
// import MessageSnackBar from './MessageSnackBar';
// import ReplenishmentShipmentGrid from './ReplenishmentShipmentGrid';




// const theme = createTheme();

// const darkTheme = createTheme({
//     palette: {
//       mode: 'dark',
//     },
//   });

export default function CreateAccount(props) {


  // SECTION Serial Number or Quantity Option Controls
  const [serialNumberOption, setSerialNumberOption] = React.useState(true);
  const [productQuantityOption, setProductQuantityOption] =
    React.useState(false);
  //! SECTION Serial Number or Quantity Option Controls

  // SECTION Button Disable Controls
  const [buttonShelveDisable, setButtonShelveDisable] = React.useState(true);
  const [buttonAssociationDisable, setButtonAssociationDisable] =
    React.useState(true);
  // !SECTION Button Disable Controls

  //SECTION Form Validation
  //  ! Helper Text
  const [helperText, setHelperText] = React.useState({
    productId: "SKU Number",
    serialNum: "Unique Identifier",
    quantity: false,
    userFirstName: "",
    userLastName: "",
    userEmail: "",
  });

  // NOTE Why am I doing this twice
  //  ! Valid Field indicator
  const [validFields, setValidFields] = React.useState({
    productId: false,
    serialNum: false,
    quantity: false,
    userFirstName: false,
    userLastName: false,
    userEmail: false,
  });
  //  ! Valid Field indicator Again
  const [validSerialNumber, setValidSerialNumber] = React.useState(false);
  const [validProductNumber, setValidProductNumber] = React.useState(false);
  const [validTagId, setValidTagId] = React.useState(false);
  const [validShelfTagId, setValidShelfTagId] = React.useState(false);
  const [validShelfId, setValidShelfId] = React.useState(false);

  const formValidation = (e) => {
    e.preventDefault();
    // console.log(e.target.id);
    // console.log(e.target.value);
    switch (e.target.id) {
      case "productId":
        if (e.target.value.length > 4) {
          setValidFields((prevState) => ({
            ...prevState,
            productId: false,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            productId: "",
          }));
          setValidProductNumber(true);
        } else {
          setValidFields((prevState) => ({
            ...prevState,
            productId: true,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            productId: "This is not a valid Product Number",
          }));
          setValidProductNumber(false);
        }
        break;
      case "serialNum":
        if (e.target.value.length > 4) {
          setValidFields((prevState) => ({
            ...prevState,
            serialNum: false,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            serialNum: "",
          }));
          setValidSerialNumber(true);
          setAssociateSerialNum(e.target.value);
        } else {
          setValidFields((prevState) => ({
            ...prevState,
            serialNum: true,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            serialNum: "This is not a valid Serial Number",
          }));
          setValidSerialNumber(false);
          setAssociateSerialNum(e.target.value);
        }
        break;
      case "tagId":
        if (e.target.value.length > 10) {
          setValidFields((prevState) => ({
            ...prevState,
            tagId: false,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            tagId: "",
          }));
          setValidTagId(true);
          setAssociateTagId(e.target.value);
        } else {
          setValidFields((prevState) => ({
            ...prevState,
            tagId: true,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            tagId: "This is not a valid tag number.",
          }));
          setValidTagId(false);
          setAssociateTagId(e.target.value);
        }
        break;
      case "shelfTagId":
        if (e.target.value.length > 1) {
          setValidFields((prevState) => ({
            ...prevState,
            shelfTagId: false,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            shelfTagId: "",
          }));
          setValidShelfTagId(true);
          setShelfTagId(e.target.value);
        } else {
          setValidFields((prevState) => ({
            ...prevState,
            shelveTagId: true,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            shelveTagId: "This is not a valid tag number.",
          }));
          setValidShelfTagId(false);
          setShelfTagId(e.target.value);
        }
        break;
      case "shelf":
        if (e.target.value.length > 1) {
          setValidFields((prevState) => ({
            ...prevState,
            shelf: false,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            shelf: "",
          }));
          setValidShelfId(true);
          setShelfId(e.target.value);
        } else {
          setValidFields((prevState) => ({
            ...prevState,
            shelf: true,
          }));
          setHelperText((prevState) => ({
            ...prevState,
            shelf: "This is not a valid tag number.",
          }));
          setValidShelfId(false);
          setShelfId(e.target.value);
        }
        break;
      default:
        break;
    }
  };

  // SECTION Check for valid Fields for association and enable button
  React.useEffect(() => {
    if (validTagId && validSerialNumber) {
      setButtonAssociationDisable(false);
    } else {
      setButtonAssociationDisable(true);
    }
  }, [validTagId, validSerialNumber]);
  // !SECTION Check for valid Fields for association and enable button

  // SECTION Check for valid Fields for shelving and enable button
  React.useEffect(() => {
    if (validShelfTagId && validShelfId) {
      setButtonShelveDisable(false);
    } else {
      setButtonShelveDisable(true);
    }
  }, [validShelfTagId, validShelfId]);
  // !SECTION Check for valid Fields for shelving and enable button

  //SECTION Click Associate Item Button Controls
  const handleAssociateItem = async (event) => {
    event.preventDefault();
    const owner = "edon_owner";
    const user = "glenn.caudill@edonconnect.com";
    const currentLocationId = "SB-ATL";
    const formData = new FormData(event.currentTarget);
    const serialNum = formData.get("serialNum");
    const tagId = formData.get("tagId");

    // console.log(serialNum)
    // console.log(tagId)
    // console.log(props.trackingNumber)

    await axios({
      method: "post",
      url: `/iot/associateItem/${owner}/${user}/${serialNum}/${tagId}/${currentLocationId}`,
    })
      .then(function async(response) {
        // Message about the Shipment
        // console.log("Correct Response",response.data);
        props.handleSnackBar(response.data.message, "success");
        // setSnackBarMessage(response.data.message);
        // setSnackbarOpen(true);
        // setMessageSeverity('success');
        // alert(response.data.message)
        setAssociateSerialNum("");
        setAssociateTagId("");
        setValidTagId(false);
        setValidSerialNumber(false);
      })
      .catch(function (error) {
        // handle error
        // console.log("there");
        // console.log("error data", error);
        // alert(`Error: ${error.response.data.detail}.`)
        setAssociateSerialNum("");
        setAssociateTagId("");
        // //!Message bar
        var errorMessage = `Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`;
        props.handleSnackBar(errorMessage, "error"); // error: red, warning: orange, information: blue, success: green
      })
      .then(function (response) {});
  };
  //!SECTION Click Associate Item Button Controls

  const [shelfId, setShelfId] = React.useState("");
  const [products, setProducts] = React.useState([]);

  const handleRemoveProduct = async (currentProducts) => {
    setProducts([...currentProducts]);
  };

  //NOTE review
  //SECTION Axios Create User
  const handleShelveItem = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const tagId = formData.get("shelfTagId");
    const shelf = formData.get("shelf");

    // console.log(tagId);
    // console.log(shelf);

    const owner = "edon_owner";
    const user = "glenn.caudill@edonconnect.com";
    const currentLocationId = "SB-ATL";

    await axios({
      method: "PUT",
      url: `/iot/receiptShelf/${owner}/${user}/${tagId}/${currentLocationId}/${shelf}`,
    })
      .then(function (response) {
        // Message about the Shipment
        // console.log(response.data);
        var message = `${response.data.item_info.serialNum} is in Stock.`;
        props.handleSnackBar(message, "success");
        // setSnackBarMessage(message);
        // setSnackbarOpen(true);
        // setMessageSeverity('success');
        // alert(response.data.message)
        setShelfTagId("");
        setShelfId("");

        props.handleCompleteInboundShipment();
      })
      .catch(function (error) {
        // handle error
        // console.log(error.response.data.detail);
        // //!Message bar
        var errorMessage = `Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`;
        props.handleSnackBar(errorMessage, "error"); // error: red, warning: orange, information: blue, success: green
      })
      .then(function (response) {});
  };

  const [associateSerialNum, setAssociateSerialNum] = React.useState("");
  const [associateTagId, setAssociateTagId] = React.useState("");
  const [shelfTagId, setShelfTagId] = React.useState("");

  //NOTE Change
  //& Email Checkbox
  // const [sendUserEmail, setSendUserEmail] = React.useState(true);

  // const handleEmailCheckbox = () => {
  //   setSendUserEmail(!sendUserEmail);
  // };

  return (
    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 5,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      <Stack
        sx={{ border: 0 }}
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <Typography component="h1" variant="h5">
          Association and Receipt Item
        </Typography>



        <Box
          component="form"
          onSubmit={handleAssociateItem}
          noValidate
          fullWidth
          sx={{ mt: 1, border: 0 }}
        >
          {serialNumberOption && (
            <TextField
              error={validFields.serialNum}
              helperText={helperText.serialNum}
              margin="normal"
              fullWidth
              required
              name="serialNum"
              label="Serial Number"
              value={associateSerialNum}
              type="text"
              id="serialNum"
              onChange={(e) => formValidation(e)}
            />
          )}
          <TextField
            error={validFields.tagId}
            helperText="Bluetooth Tag"
            margin="normal"
            value={associateTagId}
            fullWidth
            required
            name="tagId"
            label="Tag Number"
            type="text"
            id="tagId"
            onChange={(e) => formValidation(e)}
          />

          <Button
            disabled={buttonAssociationDisable}
            align="left"
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Associate Item
          </Button>
        </Box>
        <Box
          component="form"
          onSubmit={handleShelveItem}
          noValidate
          fullWidth
          sx={{ mt: 1, border: 0 }}
        >
          {serialNumberOption && (
            <TextField
              error={validFields.shelfTagId}
              helperText="Blue Tooth Tag"
              margin="normal"
              value={shelfTagId}
              fullWidth
              required
              name="shelfTagId"
              label="Tag Number"
              type="text"
              id="shelfTagId"
              onChange={(e) => formValidation(e)}
            />
          )}
          <TextField
            error={validFields.shelf}
            helperText="Warehouse Shelf Number"
            margin="normal"
            value={shelfId}
            fullWidth
            required
            name="shelf"
            label="Shelf Number"
            type="text"
            id="shelf"
            onChange={(e) => formValidation(e)}
          />

          <Button
            disabled={buttonShelveDisable}
            align="left"
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Shelve Item
          </Button>
        </Box>
      </Stack>
    </Grid>
  );
}