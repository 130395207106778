import * as React from 'react';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import DeleteIcon from '@mui/icons-material/Delete';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);


export default function SimpleGrid(props) {

    // console.log(props.gridColumns)
    const [selectedModel, setSelectedModel ] = React.useState();
    const  GridData  = {
      columns: props.gridColumns, 
      rows: props.gridData
      };
    const SortModel = [
        {
            field: props.forecastGroupingTitle
        }
    ]

  return (

    <div style={{ height: 400, width: '100%'}}>
      <DataGrid 
        getRowClassName={(params) => {
            return params.row['Period Type'] === 'Actual' ? 'MuiDataGrid-row--actual' : 'MuiDataGrid-row--forecast'
        }}
        sx={{ 'MuiDataGrid-cell--actual': { backgroundColor: '#e0e0e0' }, 'MuiDataGrid-cell--forecast': { backgroundColor: '#f5f5f5' } }}
        // sx = {{backgroundColor: '#f5f5f5'}}
        showCellRightBorder
        showColumnRightBorder
        showCellLeftBorder
        initialState={{
            sorting: {
            sortModel: SortModel,
            }
      }}

      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
        // props.handleMarketFileNames(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...GridData} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};