import * as React from 'react';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import WALogo from './WA_logo.png'
import Button from '@mui/material/Button';
import EdonLogo from './edonLogoWhite.png'


import HeaderMenus from './Components/HeaderMenus.js';
// import IoTInventoryFilters from './Components/IoTInventoryFilters.js';
// import IoTAccessFilters from './Components/IoTAccessFilters.js';
// import IoTAssignmentFilters from './Components/IoTAssignmentFilters';


import FlexLogo from './flexe_Logo.png';
import MessageSnackBar from './Components/MessageSnackBar';
import SimpleGrid from './Components/SimpleGrid.js';
import Copyright from './Components/Copyright.js';
import Unauthorized from './Components/Unauthorized.js';


// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

// const Video = () => {
//   // console.log("Thanks");
//   return(
//     <div >
//       <iframe title= "test" src="https://www.loom.com/embed/58aa2c6c6e0940efbfd009aa089108e8" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen >
//       </iframe>
//       </div>
//   )
// }


export default function IoTTracking(props) {

  const pageTitle = 'IoT Tracking';

    //SECTION Message Bar Controls
    const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
    const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
    const [ messageSeverity, setMessageSeverity ] = React.useState('success')
    const handleMessageBarClosing = () => {
      setMessageBarOpen(false);
    };
    //!SECTION


    //SECTION  View Current Inventory 


  const [ viewInventory, setViewInventory ] = React.useState(false);
  const [ viewInventoryMessage, setViewInventoryMessage ] = React.useState('View Current Inventory');
  const toggleInventory = () => {
      if (viewInventory){
          setViewInventory(false);
          setViewInventoryMessage('View Current Inventory');
          setViewInventoryTable(false); // view the  
          setDisableInventoryButton(false);
      }else{
          setViewInventory(true);
          setViewInventoryMessage('Hide Current Inventory');
          handleGetInventory();
      };
  };

  //! View the Inventory Table
  // eslint-disable-next-line 
  const [ viewInventoryTable, setViewInventoryTable ] = React.useState(false);

  // const toggleInventoryTable = () => {
  //     if (viewInventoryTable){
  //     setViewInventoryTable(false);
  //     }else{
  //     setViewInventoryTable(true);
  //     };
  // };

      //! State variables for Inventory
      //! This is sent down to the Grid Component
      // eslint-disable-next-line 
  const [ inventoryGridColumns, setInventoryGridColumns ] = React.useState([
      {field: 'id', hide: true},
      {field: 'productName', headerName: 'Item Name', width: 200, editable: false},
      {field: 'productNum', headerName: 'Item Number', width: 200, editable: false},
      {field: 'serialNum', headerName: 'Serial Number', width: 200, editable: false},
      {field: 'status', headerName: 'Status', width: 200, editable: false},
      {field: 'scanStatus', headerName: 'Scan Status', width: 200, editable: false},
      {field: 'confidenceLevel', headerName: 'Confidence', width: 200, editable: false},
      {field: 'destinationId', headerName: 'Current Location', width: 300, editable: false},
      {field: 'shelf', headerName: 'Shelf', width: 300, editable: false},
      {field: 'tagId', headerName: 'Tag Id', width: 250, editable: false},
      {field: 'Unit Cost', headerName: 'Unit Cost', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'Echelon', headerName: 'Echelon', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'Demand Rate', headerName: 'Demand Rate', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'Demand Variation', headerName: 'Demand Variation', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'Time Between Orders', headerName: 'Time Between Orders', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      ])
  const [ inventoryGridData, setInventoryGridData ] = React.useState([])


      //! Button disable control 
      //! This is sent down to the filters component 
  const [ disableInventoryButton, setDisableInventoryButton ] = React.useState(false);

      //! Get the data 
      //! This is sent down to the filters component
  //SECTION Axios get example current inventory files
  const handleGetInventory = () => {
    // var rowlimit = 5000;
    var user = props.user.email;
    const owner = "edon_owner"
    // var filetype = "currentInventory";
    // var assignmentCode = "All";
    // var accessCode = "All";
    // var accessDate = "All";
    //! Redo this with new database and endpoint
    // axios
    //   .get(
    //     `/iot/currentInventory/${owner}/${user}`
    //   )
    //   .then(function async(response) {
    //     // handle success
    //     setInventoryGridData(response.data.data);
    //     // console.log("Current Inventory:", response.data);
    //     // toggleInventoryTable();
    //     // setDisableInventoryButton(true);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //     //!Message bar
    //     setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
    //     setMessageBarOpen(true);
    //     setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
    //   })
    //   .then(function () {
    //     // always executed
    //   });
  };
  //!SECTION View Current Inventory



  

  //SECTION View Item Assignment 
  const [ viewItemAssignment, setViewItemAssignment ] = React.useState(false);
  const [ viewItemAssignmentMessage, setViewItemAssignmentMessage ] = React.useState('View Item Assignment');

  const toggleItemAssignment = () => {
      if (viewItemAssignment){
          setViewItemAssignment(false);
          setViewItemAssignmentMessage('View Item Assignment');
          setViewAssignmentTable(false);
          setDisableAssignmentButton(false);
      }else{
          setViewItemAssignment(true);
          setViewItemAssignmentMessage('Hide Item Assignment');
          handleGetAssignment();
      };
  };

  //! View the Assignment Table
  const [ viewAssignmentTable, setViewAssignmentTable ] = React.useState(false);
  const toggleAssignmentTable = () => {
      if (viewAssignmentTable){
      setViewAssignmentTable(false);
      }else{
      setViewAssignmentTable(true);
      };
  };

      //! State variables for Inventory
      //! This is sent down to the Grid Component
      const [ assignmentGridColumns, setassignmentGridColumns ] = React.useState([
          {field: 'id', hide: true},
          {field: 'assignedSerialNum', headerName: 'Serial Number', width: 300, editable: false},
          {field: 'productNum', headerName: 'Product Number', width: 300, editable: false},
          {field: 'acquistionType', headerName: 'Acquistion', width: 150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'status', headerName: 'Status', width: 150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'assignedWarehouse', headerName: 'Warehouse', width: 150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'assignFieldUser', headerName: 'Assigned To', width: 150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'utilizingAddress', headerName: 'utilizingAddress', width: 150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'courierCompany', headerName: 'Courier Company', width: 150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'courierTrackingNumber', headerName: 'Tracking Number', width: 150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'OrderUpdatedAt', headerName: 'Update Time', width: 150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'UTCOrderUpdatedAt', headerName: 'UTC Update', width: 150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'Unit Cost', headerName: 'Unit Cost', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'Demand Rate', headerName: 'Demand Rate', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'Demand Variation', headerName: 'Demand Variation', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          {field: 'Time Between Orders', headerName: 'Time Between Orders', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
          ])
      const [ assignmentGridData, setAssignmentGridData ] = React.useState([])

      //! Button disable control 
      //! This is sent down to the filters component 
      const [ disableAssignmentButton, setDisableAssignmentButton ] = React.useState(false);

      //! Get the data 
      //! This is sent down to the filters component
      //SECTION Axios get example inventory Assignment 
      const handleGetAssignment = () => {
          var rowlimit = 5000;
          var user = props.user.email;
          const owner = "glenn.caudill@edonconnect.com"
          var filetype = 'currentAssignment'
          var accessCode = "All";
          var accessDate = "All";
        //! redo this with new database and endpoint
          // axios
          //   .get(
          //     `/iot/customerorders/${owner}/${user}`
          //   )
          //   .then(function async(response) {
          //     // handle success
          //     setAssignmentGridData(response.data.data);
          //     // console.log("Completed Orders:", response.data.data);
          //     // toggleAssignmentTable();
          //     // setDisableAssignmentButton(true);
          //   })
          //   .catch(function (error) {
          //     // handle error
          //     console.log(error);
          //     //!Message bar
          //     setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
          //     setMessageBarOpen(true);
          //     setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          //   })
          //   .then(function () {
          //     // always executed
          //   });
      };
      //!SECTION






    //SECTION View Warehouse Access 

    const [ viewWarehouseAccess, setViewWarehouseAccess ] = React.useState(false);
    const [ viewWarehouseAccessMessage, setViewWarehouseAccessMessage ] = React.useState('View Warehouse Access');

      const toggleWarehouseAccess = () => {
        if (viewWarehouseAccess){
          setViewWarehouseAccess(false);
          setViewWarehouseAccessMessage('View Warehouse Access');
          setViewAccessTable(false);
          setDisableAccessButton(false);
        }else{
          setViewWarehouseAccess(true);
          setViewWarehouseAccessMessage('Hide Warehouse Access');
          handleGetAccess();
        };
      };

    //! View the Assignment Table
    const [ viewAccessTable, setViewAccessTable ] = React.useState(false);
    const toggleAccessTable = () => {
        if (viewAccessTable){
        setViewAccessTable(false);
        }else{
        setViewAccessTable(true);
        };
    };

        //! State variables for Inventory
        //! This is sent down to the Grid Component
        const [ accessGridColumns, setAccessGridColumns ] = React.useState([
            {field: 'id', hide: true, width:550},
            {field: 'entryTime', hide:true,  headerName: 'Unix Entry Time', width: 250, editable: false, headerAlign: 'center', align:'center', type: 'number'},
            {field: 'warehouseId', headerName: 'Warehouse', width: 150, editable: false},
            {field: 'warehouseUserId', headerName: 'Access Code', width: 150, editable: false},
            {field: 'UTCentryTime', headerName: 'UTC Entry Time', width: 250, editable: false, headerAlign: 'center', align:'center', type: 'number'},
            {field: 'UTCexitTime', headerName: 'UTC Exit Time', width:250, editable: false, headerAlign: 'center', align:'center', type: 'number'},
            {field: 'durationMinutes', headerName: 'Duration Minutes', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
            {field: 'customerOrderNumbers', headerName: 'Customer Orders', width:350, editable: false, headerAlign: 'center', align:'center', type: 'number'},
            ])
        const [ accessGridData, setAccessGridData ] = React.useState([])

        //! Button disable control 
        //! This is sent down to the filters component 
        const [ disableAccessButton, setDisableAccessButton ] = React.useState(false);

          //! Get the data 
        //! This is sent down to the filters component
        //SECTION Axios get example access data
        const handleGetAccess = () => {
            var rowlimit = 5000;
            var user = props.user.email;
            const owner = "edon_owner"
            var filetype = "currentAccess"
            if(accessDate!=="All")
                {var accessDate = new Date(accessDate)}
            // console.log("Iot Market:", marketName)
            // console.log("Iot Access Code:", accessCode)
            // console.log("Iot Warehouse Number:", warehouseNumber)
            // console.log("Iot Access Date:", accessDate)
            var itemNumber = "All"
            var assignmentCode = "All"

            // // //! Get Data from endpoint
            //! redo this with new database and endpoint
            // axios
            //   .get(
            //     `/iot/warehouseAccess/${owner}/${user}`
            //   )
            //   .then(function async(response) {
            //     // handle success
            //     setAccessGridData(response.data.data);
            //     // console.log("access:", response.data.data);
            //     toggleAccessTable();
            //     setDisableAccessButton(true);
            //   })
            //   .catch(function (error) {
            //     // handle error
            //     console.log(error);
            //     //!Message bar
            //     setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
            //     setMessageBarOpen(true);
            //     setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
            //   })
            //   .then(function () {
            //     // always executed
            //   });
        };
        //!SECTION

  
  
  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />

        {/* Content*/}
        {/* Content*/}
        {/* Content*/}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          <img src={EdonLogo} width={200} alt="Logo" />
          <Container
            overflow="auto"  //! Changed from scroll to auto 
            maxWidth="xl"
            sx={{ mt: 5, mb: 10, border: 1 }}
          >
            {props.user.membershipCode === "0x12a6b5324" && ( //Warehouse Anywhere membership
              <Grid item sx={{ p: 2 }}>
                <img src={WALogo} width={200} alt="Logo" />
              </Grid>
            )}
            {props.user.membershipCode === "0x4ee11bfe" && //Warehouse Anywhere membership
            <Grid item sx={{p:2}}>
                <img src={FlexLogo} width={150} alt="Logo" />
              </Grid>
            }

            <Grid
              container
              spacing={3}
              sx={{ alignContent: "center", border: 0, pb: 2 }}
            >
              <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ alignContent: "center", border: 0 }}
              >
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    border: 0,
                  }}
                >
                  <Typography align="center" variant={"h4"}>
                    {props.user.company} {pageTitle}
                  </Typography>
                  <Typography align="center" variant={"subtitle1"}>
                    {props.user.email}
                  </Typography>

                  {!props.user.services.includes("IT") &&
                  <Grid item md={12} lg={12} sx={{ alignContent: "center", p:2, border: 0 }}>
                    <Unauthorized />
                  </Grid>
                  }

                </Paper>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{ alignContent: "center", border: 0 }}
            >
              {/* <Grid item>
                <Video/>
              </Grid> */}
              

              {/* Inventory Tracking */}
              {props.user.role > 5 && (
                  <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button onClick={toggleInventory}>
                      {" "}
                      {viewInventoryMessage}
                    </Button>

                    {/* Inventory Filters */}
                    {viewInventory && 
                    <>
                        {/* <IoTInventoryFilters
                        disableButton={disableInventoryButton}
                        handleCurrentData={handleGetInventory}
                      /> */}
                
                    <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                    >
                    <SimpleGrid gridColumns={inventoryGridColumns} gridData={inventoryGridData} />
                    </Grid>
                </>
              }
                    
                  </Paper>
                </Grid>

            </>

              )}
              {/*  Assignment Tracking */}
              {props.user.role > 5 && (
                  <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button onClick={toggleItemAssignment}>
                      {" "}
                      {viewItemAssignmentMessage}
                    </Button>

                    {/* Item Assignment Filters */}
                    {viewItemAssignment && 
                      //   <IoTAssignmentFilters
                      //   disableButton={disableAssignmentButton}
                      //   handleCurrentData={handleGetAssignment}
                      // />

                      <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sx={{ alignContent: "center", border: 0 }}
                      >
      
                      <SimpleGrid gridColumns={assignmentGridColumns} gridData={assignmentGridData} />
      
                      </Grid>
                    }
                  </Paper>
                </Grid>

            </>
              )}

            <>
              {/* Warehouse Access Tracking */}
              {props.user.role > 5 && (
                  <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                  >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                    <Button onClick={toggleWarehouseAccess}>
                      {" "}
                      {viewWarehouseAccessMessage}
                    </Button>

                    {/* Access Filters */}
                    {viewWarehouseAccess && (
                        // <IoTAccessFilters
                        // disableButton={disableAccessButton}
                        // handleCurrentData={handleGetAccess}

                        // />
                      <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}     
                      sx={{ alignContent: "center", border: 0 }}
                      >

                      <SimpleGrid gridColumns={accessGridColumns} gridData={accessGridData} />

                      </Grid>


                        )}
                  </Paper>
                </Grid>
              )}

                
            </>


            </Grid>





            <Grid item border={0} xs={12} sm={12} md={12}>
              <Copyright sx={{ pt: 4, align: "center" }} />
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};