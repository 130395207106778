import { createContext } from "react";


const UserContext = createContext({
  email: ``,
  isAuthenticated: false,
  lastSignOnDate: new Date(),
  role: ``,
  userCode: ``,
  work:"yes"
});
export default UserContext;