import React from "react";
import axios from 'axios';


import Grid from "@mui/material/Grid";


// import { createTheme, ThemeProvider } from "@mui/material/styles";

// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
import DropDownSelector from "./DropDownSelector";
import MessageSnackBar from "./MessageSnackBar";



// const theme = createTheme();

// const darkTheme = createTheme({
//   palette: {
//     mode: "dark",
//   },
// });


export default function AccountDropDown(props) {
  //! Local SnackBar Messaging

  React.useEffect(()=> {
      getAccountList();
  }, [])

 //SECTION Message Bar Controls
 const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
 const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
 const [ messageSeverity, setMessageSeverity ] = React.useState('success')
 const handleMessageBarClosing = () => {
   setMessageBarOpen(false);
 };
 //!SECTION


  const handleSelectedAccount = (accountName) => {
    const result = accountList.find( ({ value }) => value === accountName );
    props.handleSelectedAccount(accountName, result.accountCode, result.membershipCode)
  };



  // !Down to AccountDrop Down
  const [accountList, setAccountList] = React.useState([
    {
      id: 1,
      label: "Default Regions",
      value: "Zip_Metros_Default.csv",
    },
  ]);

  //Variables to send down
  const accountsTitles = {
    dropDownTitle: "Select An Account",
    dropDownButtonTitle: "Load Account",
    dropDownLabel: "Select An Account",
    dropDownHelperText: "Files will load under your if not selected.",
    dropDownAlertMessage: "Files will load under " ,
    buttonView: true
  };
    //! Items for Dropdown
  // Get accounts list from Database
  //SECTION Axios get Account List
  const getAccountList = () => {
    const user = props.user.email;
    var headers = props.headers
    axios
      .get(`information/listaccounts`, {headers:headers})
      .then(function (response) {
        setAccountList(response.data);
        // console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        //!Message bar
        setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      })
      .then(function () {
        // always executed
      });
  };
  //!SECTION


  return (
    <Grid
      container
      marginTop={1}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 1,
        border: 0,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
          <DropDownSelector
                itemList={accountList}
                handleSelectedItem={handleSelectedAccount}
                dropDownTitles={accountsTitles}
                toggleClose={props.toggleAccounts}
            />

    </Grid>
  );
}