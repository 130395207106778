import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import Link from '@mui/material/Link';

import EdonLogo from './edonLogoWhite.png'
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import EdonAPIPic from './img/EdonAPI.png'
import HeaderMenus from './Components/HeaderMenus.js';
import Unauthorized from './Components/Unauthorized.js'




// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Edon Connect
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}




const cards = [1];


export default function EdonAPIs(props) {
  const pageTitle = "Edon APIs"


  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
          >
          <Toolbar />


          {/* Content*/}
          {/* Content*/}
          {/* Content*/}

        <Container sx={{ py: 8 }} maxWidth="md">
          <img src={EdonLogo} width={200} alt="Logo" />


          {!props.user.services.includes("AP") &&
              <Grid item md={12} lg={12} sx={{ alignContent: "center", p:2, border: 0 }}>
                <Unauthorized />
              </Grid>
            }

          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (

              <Grid item key={card} xs={12} sm={12} md={12}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      // 16:9
                      pt: '10%',
                      maxHeight: "100%"
                    }}
                    image={EdonAPIPic}
                    alt="random"
                  />

                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Edon API
                    </Typography>
                    <Typography>
                      You can utlize the Edon API to integrate your own data and models into the Edon Connect platform.
                    </Typography>
                  </CardContent>

                </Card>
              </Grid>
            )
            
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Copyright sx={{ pt: 4, align: "center" }} />
            </Grid>
        </Container>

          
        </Box>
      </Box>
    </ThemeProvider>
  );
}