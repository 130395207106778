import * as React from 'react';
import {  createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import EdonLogo from './edonLogoWhite.png'
import BarChart from './Components/BarChart.js'
import HighVolumeLineChart from './Components/HighVolumeLineChart.js'
import HeaderMenus from './Components/HeaderMenus.js';
import Unauthorized from './Components/Unauthorized.js'

// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Edon Connect
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const drawerWidth = 240;




export default function Simulator(props) {

  const pageTitle = 'Simulator';

  var data = [{'name': '75%', 'value': '.13'}, {'name': '80%', 'value': '.12'}, {'name': '85%', 'value': '.25'}, {'name': '90%', 'value': '.3'}, {'name': '95%', 'value': '.2'}]
  var range = ['.13', '.12', '.25', '.3', '.2']

  const lineData = [
    {
      "name": "1",
      "value": 200,
    },
    {
      "name": "2",
      "value": 210,

    },
    {
      "name": "3",
      "value": 383,

    },
    {
      "name": "4",
      "value": 194,

    },
    {
      "name": "5",
      "value": 249,
    },
    {
      "name": "6",
      "value": 159,

    },
    {
      "name": "7",
      "value": 179,
    },
    {
      "name": "8",
      "value": 188,
    },
    {
      "name": "9",
      "value": 200,
    },
    {
      "name": "10",
      "value": 143,

    },
    {
      "name": "11",
      "value": 165,

    },
    {
      "name": "12",
      "value": 126,

    },
    {
      "name": "13",
      "value": 332,
    },
    {
      "name": "14",
      "value": 129,

    },
    {
      "name": "15",
      "value": 225,
    },
    {
      "name": "16",
      "value": 378,
    },
    {
      "name": "17",
      "value": 200,
    },
    {
      "name": "18",
      "value": 179,

    },
    {
      "name": "19",
      "value": 194,

    },
    {
      "name": "20",
      "value": 262,

    },
    {
      "name": "21",
      "value": 405,
    },
    {
      "name": "22",
      "value": 159,

    },
    {
      "name": "23",
      "value": 399,
    },
    {
      "name": "24",
      "value": 364,
    },
    {
      "name": "25",
      "value": 140,
    },
    {
      "name": "26",
      "value": 284,

    },
    {
      "name": "27",
      "value": 242,

    },
    {
      "name": "28",
      "value": 280,

    },
    {
      "name": "29",
      "value": 387,
    },
    {
      "name": "30",
      "value": 257,

    },
    {
      "name": "31",
      "value": 379,
    },
    {
      "name": "32",
      "value": 158,
    },
    {
      "name": "33",
      "value": 200,
    },
    {
      "name": "34",
      "value": 388,

    },
    {
      "name": "35",
      "value": 330,

    },
    {
      "name": "36",
      "value": 304,

    },
    {
      "name": "37",
      "value": 202,
    },
    {
      "name": "38",
      "value": 374,

    },
    {
      "name": "39",
      "value": 249,
    },
    {
      "name": "40",
      "value": 192,
    },
    {
      "name": "41",
      "value": 405,
    },
    {
      "name": "42",
      "value": 358,

    },
    {
      "name": "43",
      "value": 397,

    },
    {
      "name": "44",
      "value": 235,

    },
    {
      "name": "45",
      "value": 249,
    },
    {
      "name": "46",
      "value": 159,

    },
    {
      "name": "47",
      "value": 179,
    },
    {
      "name": "48",
      "value": 188,
    },
    {
      "name": "49",
      "value": 179,
    },
    {
      "name": "50",
      "value": 188,
    },
  ]
  



  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />       
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />



        <Container sx={{ pt: 8, pb:4 }} maxWidth="md">
          <img src={EdonLogo} width={200} alt="Logo" />

          {!props.user.services.includes("SM") &&
            <Grid item md={12} lg={12} sx={{ alignContent: "center", p:2, border: 0 }}>
              <Unauthorized />
            </Grid>
            }
          

          <Grid container paddingTop = {5} spacing={4}>

            <Grid item  xs={12} sm={12} md={12} sx={{border:1, p:5}}>
              <Box sx ={{display: "flex", pb:5 ,justifyContent:'space-around'}} >
              <Typography variant="h6" gutterBottom>Demand Simulation </Typography>
              </Box>
              <HighVolumeLineChart data={lineData} range={range} />
            </Grid>
              
            <Grid item  xs={12} sm={12} md={12} sx={{border:1, p:5}}>
              <Box sx ={{display: "flex", justifyContent:'space-around'}} >
              <Typography variant="h6" gutterBottom>Service Levels</Typography>
              </Box>
              <BarChart data={data} range={range} />
            </Grid>

          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Copyright sx={{ pt: 4, align: "center" }} />
          </Grid>

        </Container>

          
        </Box>
      </Box>
    </ThemeProvider>
  );
}