import * as React from 'react';
import axios from 'axios';


import FileDrop from './FileDrop.js';
import MessageSnackBar from './MessageSnackBar.js';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid'


// This component accepts a file from FileDrop
// sends it to the endpoint
// It receives a prop to toggle the view for this component
export default function WarehouseFileDrop(props) {

  //SECTION Message Bar Controls
  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION
  const [allAccountsScope, setAllAccountsScope ] = React.useState(true)
  const handleAccountScopeChange = () => {
    setAllAccountsScope(!allAccountsScope);
  }

  //SECTION Axios Upload a warehouse file Post
  const handleUploadFile = (file) => {
    const userid = props.user.email
    // console.log(file);

    const formData = new FormData();
    formData.append('file', file);

    var postConfig = {
      method: 'post',
      url: `Upload/uploadwarehouses/${userid}/${props.accountCode}/${allAccountsScope}/`,
      data : formData
    };
    axios(postConfig)
    .then(function (response) {
        // console.log(response)
        //!Message bar
        setMessageBarMessage(`Great! ${response.data}`);
        setMessageBarOpen(true);
        setMessageSeverity("success") // error: red, warning: orange, information: blue, success: green
        setTimeout(() => {
          props.toggleWarehouseDrop();
        }, 3000);
    })
    .catch(function (error) {
      // console.log(error);
      //!Message bar
      setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
      setMessageBarOpen(true);
      setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      setTimeout(() => {
        props.toggleWarehouseDrop();
      }, 3000);
    });
  }
  //!SECTION


  return (
    <>
    <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
      <FileDrop handleUploadFile={handleUploadFile} />
    <Grid container  sx={{justifyContent:'center', border:0, p:0, borderStyle: 'dotted', borderRadius: '5px' }} >
      <FormControlLabel control={<Checkbox onChange={handleAccountScopeChange} sx={{alignContent: "center"}}checked={allAccountsScope}/>} label="For All Accounts" />
    </Grid>
    </>

  );
}