import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';


import axios from 'axios';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material//MenuItem';
// import { createTheme} from '@mui/material/styles';





export default function CreateMarkets(props) {

  const title = "Create A Market";
  //&Account DropDown
  //&Account DropDown
  //&Account DropDown
  //! Data and Select for Account Dropdown
  const [selectedAccount, setSelectedAccount] = React.useState('');
  //! accountList comes from Dashboard
  const handleAccountSelect = (event) => {
    setSelectedAccount(event.target.value);
    props.getRegionsList(event.target.value);
  };
  const [selectedRegion, setSelectedRegion] = React.useState('');
  //! regionList comes from Dashboard
  const handleRegionSelect = (event) => {
    setSelectedRegion(event.target.value);
  };



   //SECTION Form Validation
    //! Form Validation
    const [ buttonDisable, setButtonDisable ] = React.useState(true);


    const [ validFields, setValidFields ] = React.useState(
      {
        "marketName": false,
        "countryName": false,
     });

    const [ helperText, setHelperText ] = React.useState(
      {
        "marketName":"Market Name",
        "countryName":"Country Name",
        "MarketDesc":"Description of Market",
    
       });


    const [ validMarketName, setValidMarketName ] = React.useState(false);
    const [ validCountryName, setValidCountryName ] = React.useState(false);

    React.useEffect(() => {
        if (validMarketName, validCountryName) {
          setButtonDisable(false);
        }else{
          setButtonDisable(true);
        }
      },[ validMarketName, validCountryName]
      )
 

    const formValidation =  (e) => {
      e.preventDefault();
      // console.log(e.target.id);
      // console.log(e.target.value);
      switch (e.target.id){

        case 'marketName': 
          var marketName = e.target.value;
          // eslint-disable-next-line 
          if (marketName.length > 4){
            setValidFields(prevState => ({
                ...prevState,
                "marketName": false,
                }));
            setHelperText(prevState => ({
            ...prevState,
            marketName: "Market Name",
            }));
            setValidMarketName(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "marketName": true}));

              setHelperText(prevState => ({
                ...prevState,
                marketName: "Name needs at least five characters",
                }));
              setValidMarketName(false);
          }
          break;

        case 'countryName':
          var countryName = e.target.value;
          // eslint-disable-next-line 
          if (countryName.length > 1){
            setValidFields(prevState => ({
                ...prevState,
                "countryName": false,
                }));
            setHelperText(prevState => ({
            ...prevState,
            countryName: "Country Name",
            }));
            setValidCountryName(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "countryName": true}));

              setHelperText(prevState => ({
                ...prevState,
                countryName: "Country Name needs at least two characters",
                }));
              setValidCountryName(false);
          }
          break;
        // case 'itemCost':           
        //     var itemCost = e.target.value;
        //     // eslint-disable-next-line
        //     if (itemCost.length > 3){
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "itemCost": false, 
        //             }));
        //         setHelperText(prevState => ({
        //         ...prevState,
        //         itemCost: "Item Cost",
        //         }));
        //         setValidItemCost(true);
        //     }else{
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "itemCost": true}));
        //         setHelperText(prevState => ({
        //             ...prevState,
        //             itemCost: "Cost needs at least five characters",
        //             }));
        //         setValidItemCost(false);
        //     }
        //     break;
        // case 'size':
        //     var size = e.target.value;
        //     // eslint-disable-next-line
        //     if (size.length > 4){
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "size": false,
        //             }));
        //         setHelperText(prevState => ({
        //         ...prevState,
        //         size: "Size",
        //         }));
        //         setValidSize(true);
        //     }else{
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "size": true}));
        //         setHelperText(prevState => ({
        //             ...prevState,
        //             size: "State needs at least two characters",
        //             }));
        //         setValidSize(false);
        //     }
        //     break;
        // case 'weight':
        //     var weight = e.target.value;
        //     // eslint-disable-next-line
        //     if (weight.length > 0){
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "weight": false,
        //             }));
        //         setHelperText(prevState => ({
        //         ...prevState,
        //         weight: "Product Weight",
        //         }));
        //         setValidWeight(true);
        //     }else{
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "weight": true}));
        //         setHelperText(prevState => ({
        //             ...prevState,
        //             weight: "Weight needs at least five characters",
        //             }));
        //         setValidWeight(false);
        //     }
        //     break;
        // case 'manufacture':
        //     var manufacture = e.target.value;

        //     if (manufacture.length > 3){
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "manufacture": false,
        //             }));
        //         setHelperText(prevState => ({
        //         ...prevState,
        //         manufacture: "Product Manufacture",
        //         }));
        //         setValidManufacture(true);
        //     }else{
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "manufacture": true}));
        //         setHelperText(prevState => ({
        //             ...prevState,
        //             manufacture: "Manufacture needs at least six character",
        //             }));
        //         setValidManufacture(false);
        //     }
        //     break;


        default:
          break;
      }
    }


    //!SECTION
    


    //SECTION Axios Create Product
  const handleCreateMarket = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    var assignAccount =formData.get("assignAccount");
    var assignRegion =formData.get("assignRegion");
    var marketName = formData.get("marketName");
    var countryName = formData.get("countryName");
    var marketDesc = formData.get("marketDesc");

    const headers = props.headers;
    if (assignAccount === "" || assignAccount === null || assignAccount === undefined){
      assignAccount = props.user.accountCode;
    }



    var data = {
        accountCode: assignAccount,
        region: assignRegion,
        marketName: marketName,
        country: countryName,
        marketDesc: marketDesc,
    };

    await axios(
      {
      method: "post",
      url: `/MobileAdmin/createmarket/`,
      data: data,
      headers: headers
      }
      )
      .then(function (response) {
        props.handleMessageBarOpen(response.data.message, "success");
        props.toggleViewCreateMarket();
      })
      .catch(function (error) {
        props.handleMessageBarOpen(error.response.data.detail, "error");
      })


  };
  //!SECTION


  return (
    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 5,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      {/* <Stack direction="column" alignItems="center" spacing={2}> */}
        <Typography component="h1" variant="h5">
          {title}
        </Typography>

        <Box
          component="form"
          onSubmit={handleCreateMarket}
          noValidate
          overflow="auto"
          sx={{ mt: 1 }}
        >
          {props.user.role ==10 &&
          <TextField
            margin="normal"
            select
            required
            fullWidth
            name="assignAccount"
            label="Account"
            value={selectedAccount}
            onChange={handleAccountSelect}
            type="number"
            id="assignAccount"
            helperText="Select an Account"
          >
            {props.accountList.map((option) => (
              <MenuItem
                sx={{ whiteSpace: "normal" }}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          }

        <TextField
            margin="normal"
            select
            required
            fullWidth
            name="assignRegion"
            label="Region"
            value={selectedRegion}
            onChange={handleRegionSelect}
            type="number"
            id="assignRegion"
            helperText="Select an Region"
          >
            {props.regionList.map((option) => (
              <MenuItem
                sx={{ whiteSpace: "normal" }}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>


          <TextField
            error = {validFields.marketName}
            helperText={helperText.marketName}
            margin="normal"
            required
            fullWidth
            name="marketName"
            label="ex. Chicago Metro"
            type="text"
            id="marketName"
            onChange={(e) => formValidation(e)}
          />
          <TextField  
            error = {validFields.countryName}
            helperText={helperText.countryName}
            margin="normal"
            required
            fullWidth
            name="countryName"
            label="ex. USA"
            type="text"
            id="countryName"
            onChange={(e) => formValidation(e)}
          />
          <TextField

            helperText={helperText.marketDesc}
            margin="normal"
            fullWidth
            name="marketDesc"
            label="ex. Counties around Chicago"
            type="text"
            id="marketDesc"
          />
         
          

          <Button
            disabled={buttonDisable}
            align="center"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Create Market
          </Button>
          
          {/* <FormControlLabel
            control={<Checkbox defaultChecked onChange={handleEmailCheckbox} />}
            label="Send Mobile User Notification Email"
          /> */}
        </Box>
      {/* </Stack> */}
    </Grid>
  );
}