import * as React from 'react';

// import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

// import axios from 'axios';

// import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
// import MenuItem from '@mui/material//MenuItem';

// import { createTheme, ThemeProvider } from '@mui/material/styles';

// import Snackbar from '@mui/material/Snackbar'
// import MuiAlert from '@mui/material/Alert';
// import MessageSnackBar from './MessageSnackBar';
import InboundShipmentGrid from './InboundShipmentGrid.js';




// const theme = createTheme();

// const darkTheme = createTheme({
//     palette: {
//       mode: 'dark',
//     },
//   });

export default function CreateAccount(props) {

  //SECTION Local SnackBar Messaging
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION Local SnackBar Messaging


    // const [ serialNumberOption, setSerialNumberOption ] = React.useState(true);
    // const [ productQuantityOption, setProductQuantityOption ] = React.useState(false);


   //SECTION Form Validation
    //! Form Validation
    // const [ buttonDisable, setButtonDisable ] = React.useState(true);
    const [ validFields, setValidFields ] = React.useState(
      {
        "selectTrackingNumber": false,

        
    });
    const [ helperText, setHelperText ] = React.useState(
        {
        "selectTrackingNumber":"Shipment Tracking Number",
       });

    // //    NOTE Review
    // const [ validFirstName, setValidFirstName ] = React.useState(false);
    // const [ validLastName, setValidLastName ] = React.useState(false);
    // const [ validEmail, setValidEmail ] = React.useState(false);
    // const [ validSeletedRole, setValidSelectedRole ] = React.useState(false);
    const [ validTrackingNumber, setValidTrackingNumber ] = React.useState(false);


    // const [ validProductNumber, setValidProductNumber ] = React.useState(true);
    // const [ validSerialNumber, setValidSerialNumber ] = React.useState(true);


    // // const forceUpdate = React.useCallback(() => setValidField(!validField),[]);

    const formValidation =  (e) => {
      e.preventDefault();
      // console.log(e.target.id);
      // console.log(e.target.value);
      switch (e.target.id){
        case 'selectTrackingNumber': 
        // NOTE check to see if it is a valid Tracking Number
          if (e.target.value.length > 8){
            setValidFields(prevState => ({
              ...prevState,
              "selectTrackingNumber": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                selectTrackingNumber: "",
                }));
              setValidTrackingNumber(true);
              handleSelectTrackingNumber(e);

            }else{
              setValidFields(prevState => ({
                ...prevState,
                "selectTrackingNumber": true}));
              setHelperText(prevState => ({
                ...prevState,
                selectTrackingNumber: "This is not a valid Tracking Number",
                }));  
              setValidTrackingNumber(false);
              setSelectedTrackingNumber(e.target.value)
          }
          break;


        default:
          break;
      }
    }

    // NOTE Review
    // React.useEffect(() => {
    //   if (validProductNumber && validSerialNumber ) {
    //     setButtonDisable(false);
    //   }else{
    //     setButtonDisable(true);
    //   }
    // },[ validProductNumber, validSerialNumber ]
    // )
    //!SECTION

    
    // const [ viewOrderDetail, setViewDetailOrder ] = React.useState(false);

const [ viewShipmentDetail, setViewShipmentDetail ] = React.useState(false);

  //SECTION Select Tracking Number
  const handleSelectTrackingNumber = (event) => {
      // console.log("value", event.target.value)
      // console.log(event.target)
      setSelectedTrackingNumber(event.target.value)
      setViewShipmentDetail(true)
      props.handleViewAssociation();
      props.handleGetShipmentData(event.target.value);

  };

    const [selectedTrackingNumber, setSelectedTrackingNumber ] = React.useState("");


  return (
    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 5,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
        <Grid item xs={12} md={12} lg={12} sx={{alignContent: 'center', border:0 }} >
        <Typography component="h1" variant="h5">
          Inbound Shipment Information
        </Typography>
        </Grid>
          {/* <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
          <Snackbar
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              {snackBarMessage}
            </MuiAlert>
          </Snackbar> */}

          
        <Grid item xs={12} md={12} lg={12} sx={{alignContent: 'center', border:0 }} >
          <TextField
            error = {validFields.selectTrackingNumber}
            helperText={helperText.selectTrackingNumber}
            margin="normal"
            required
            name="selectTrackingNumber"
            label="Tracking Number"
            value={selectedTrackingNumber}
            onChange={(e) => formValidation(e)}
            type="text"
            id="selectTrackingNumber"
          />
        </Grid>

        {
            viewShipmentDetail &&
            <InboundShipmentGrid gridData = {props.shipmentDetail} />
        }


    </Grid>
  );
}