import * as React from 'react';

// import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import { DataGridPro, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid-pro';

import DeleteIcon from '@mui/icons-material/Delete';


import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function MobileUserTable(props) {

    const nonAdminUser = props.user.role !== 10 ? true : false;
    const [selectedModel, setSelectedModel ] = React.useState();
    const  MobileUserStats  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'user', headerName: 'Email', width:275, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'accountName', headerName: 'Company Name', width:200, headerAlign: 'center', align: 'center',  editable: false, hide: nonAdminUser},
      {field: 'userFirstName', headerName: 'First Name', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'userLastName', headerName: 'Last Name', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'manager', headerName: 'Manager', width:275, headerAlign: 'center', align: 'center', editable: false},
      {field: 'markets', headerName: 'Markets', width:125, headerAlign: 'center', align: 'center', editable: false},
      // {field: 'warehouseAccess', headerName: 'Locations', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'lastLogin', headerName: 'Last Log In', width:200,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'lastActivity', headerName: 'Last Activity', width:200,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'createdDate', headerName: 'User Create Date', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'createdBy', headerName: 'Created By', width:275, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'membershipCode', headerName: 'Membership Code',  width:150, editable: false, headerAlign: 'center',  align: 'center', hide:nonAdminUser},
      {field: 'actions', type: 'actions', headerName: 'Delete User', width:150 ,  
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} onClick={props.deleteMobileUser(params.id)}  label="Delete" />
      ]},

      ], 
      rows: props.mobileUsersDisplayData
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 

        showCellRightBorder
        showColumnRightBorder
        showCellLeftBorder
        
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...MobileUserStats} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};