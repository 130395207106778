import * as React from 'react';

import { DataGridPro, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function PlatformUserStats(props) {
    
    const nonAdminUser = props.user.role !== 10 ? true : false;
    const [selectedModel, setSelectedModel ] = React.useState();
    const  PlatformUserStats  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'userMembership', headerName: 'Member Name', width:150, headerAlign: 'center', align: 'center',  editable: false, hide:nonAdminUser},
      {field: 'userCompany', headerName: 'Company Name', width:150, headerAlign: 'center', align: 'center',  editable: false, hide:nonAdminUser},
      {field: 'userCode', headerName: 'User Code',  width:125, editable: false, headerAlign: 'center', align: 'center',  type:'number', hide:true },
      {field: 'userEmail', headerName: 'User Email', width:275, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'userFirstName', headerName: 'First Name', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'userLastName', headerName: 'Last Name', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'userCreateDate', headerName: 'User Create Date', width:150, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'userLastSignOnDate', headerName: 'Last Sign On', width:225,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'membershipCode', headerName: 'Membership Code',  width:150, editable: false, headerAlign: 'center',  align: 'center', hide:nonAdminUser},
      {field: 'accountCode', headerName: 'Account Code',   width:125, editable: false, headerAlign: 'center',  align: 'center', hide:nonAdminUser},
      {field: 'userRole', headerName: 'User Role',   width:125, editable: false, headerAlign: 'center',align: 'center', type:'number', hide:nonAdminUser },
      {field: 'manager', headerName: 'Manager',   width:125, editable: false, headerAlign: 'center',  align: 'center'},
      {field: 'actions1', type: 'actions', headerName: 'Change Manager Status', width:175,  
      getActions: (params) => [
        <GridActionsCellItem icon={<ManageAccountsIcon />} onClick={props.makePlatformUserManager(params.id)}  label="DesignateManager" />
      ]},
      {field: 'active', headerName: 'Active',  width:100, editable: false, headerAlign: 'center', align: 'center' },
      {field: 'actions', type: 'actions', headerName: 'Delete User', width:150,  
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} onClick={props.deletePlatformUser(params.id)}  label="Delete" />
      ]},
      ], 
      rows: props.platformUsersDisplayData
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 

        showCellRightBorder
        showColumnRightBorder
        showCellLeftBorder
        
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...PlatformUserStats} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};