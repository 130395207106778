import React from 'react';
// import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// import  { PureComponent } from 'react';

import { BarChart, Bar, XAxis, YAxis, LineChart, Line, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);


export default function HighVolLinechart(props) {


    return (
        <div style={{ height: 400, width: '100%' }}>
            <ResponsiveContainer height="80%">
            <LineChart width={730} height={250} data={props.data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <XAxis dataKey="name"stroke="#fff" />
                <YAxis stroke="#fff"  />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="value" stroke="#8884d8" />

                </LineChart>

              </ResponsiveContainer>


            </div>


    )
}