import * as React from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { useStorageState } from "react-storage-hooks";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import ProductScanning from "./ProductScanning.js";
import WarehouseDropDown from "./WarehouseDropDown";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


export default function WarehouseScanning(props) {





    //!SECTION

    //SECTION Warehouse Selection
    const [ viewWarehouseDropDown, setViewWarehouseDropDown ] = React.useState(true);
    const [ viewWarehouseName, setViewWarehouseName ] = React.useState(false);
    const [ warehouseName, setWarehouseName ] = useStorageState(localStorage, "EventWarehouseName");
    const [ warehouseCode, setWarehouseCode ] = useStorageState(localStorage, "EventWarehouseCode");
    const [ scanningType, setScanningType ] = useStorageState(localStorage, "EventScanningType","inbound");
    const [ scanningTypeMessage, setScanningTypeMessage ] = React.useState("");
    const [ eventIndex, setEventIndex ] = useStorageState(localStorage, "EventIndex");

    //handler to send down

    const handleSelectedWarehouse = async (warehouseName, warehouseCode) => {
        getEventIndex()
        getValidWarehouseShelfs(warehouseCode)
        setViewWarehouseDropDown(false);
        setViewWarehouseName(true);
        setWarehouseName(warehouseName);
        setViewWarehouseScans(true);
        setWarehouseCode(warehouseCode);
        getValidItemInfo(props.user.email, 'item')
        getValidItemInfo(props.user.email, 'serial')
    };

    const getEventIndex = async () => {
      var headers = props.headers
      await axios
      .get(
        `${props.mobileSite}MobileApp/uniqueeventindex`, {headers: headers}
        )
        .then(function async(response) {
          // console.log("index: ", response.data.eventIndex)
          setEventIndex(response.data.eventIndex); 
        })
      .catch(function (error) {
        // handle error
        console.log(error);
        //!Message bar
        // setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        // setMessageBarOpen(true);
        // setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      })

    }

    const [ allValidSerialNumbers, setAllValidSerialNumbers] = useStorageState(localStorage, "EventValidSerialNumbers");
    const [ allValidProductNumbers, setAllValidProductNumbers] = useStorageState(localStorage, "EventValidProductNumbers");
    //function to change valid serial numbers and product numbers
    const changeValidItemInfo = async (itemType, itemNumbers) => {
      if(itemType === 'item'){
        setAllValidSerialNumbers(itemNumbers)
      }
    }

    const getValidItemInfo = async (userEmail, itemType) => {
      await axios
      .get(
        `${props.mobileSite}MobileApp/validiteminfolistweb/${userEmail}/${itemType}`
        )
        .then(function async(response) {
          if(itemType === 'item'){
            // console.log(response.data.validItemInfoList)
            setAllValidProductNumbers(response.data.validItemInfoList);
          }else{
            setAllValidSerialNumbers(response.data.validItemInfoList);
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          //! Need it to put Message bar
          // setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
          // setMessageBarOpen(true);
          // setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        })
        .then(function () {
          // always executed
        });

        };


    const handleChangeWarehouse = async () => {
        setWarehouseName("")
        setWarehouseCode(0)
        setViewWarehouseDropDown(true)
        setViewWarehouseName(false)
        setViewWarehouseScans(false)
        setEventIndex(0)
        handleLocalStorageChange()

        // console.log("Hello Change Warehouse")
        // console.log(warehouseCode)
    };
    const handleScanningTypeChange = async (scanningValue) => {
      // console.log(scanningValue.target.value)
      setScanningType(scanningValue.target.value)
      if(scanningValue.target.value === 'cycleCount') {
        setScanningTypeMessage('Cycle Count')
      }
      if(scanningValue.target.value === 'inbound') {
        setScanningTypeMessage('Inbound')
      }
      if(scanningValue.target.value === 'outbound') {
        setScanningTypeMessage('Outbound')
      }
    };

    const handleLocalStorageChange = async () => {
      localStorage.removeItem('EventWarehouseName')
      localStorage.removeItem('CycleCountWarehouseCode')
      localStorage.removeItem('CycleCountViewWarehouseDropDown')
      localStorage.removeItem('CycleCountViewWarehouseName')
      localStorage.removeItem('CycleCountViewWarehouseScans')
      localStorage.removeItem('CycleCountIndex')
      localStorage.removeItem('EventValidWarehouseShelfs');
      localStorage.removeItem('EventIndex')
      localStorage.removeItem('EventViewWarehouseScans')

    };

    //!SECTION

    //SECTION Warehouse Scans
    const [viewWarehouseScans, setViewWarehouseScans] = React.useState(false);
    // const [ cycleCountAccount, setCycleAccount ] = useStorageState(localStorage, "What");
    //!SECTION


    //SECTION - Valid Warehouse Shelfs
    
    const [ allValidShelfNumbers, setAllValidShelfNumbers ] = useStorageState(localStorage, "EventValidWarehouseShelfs");

    const getValidWarehouseShelfs = async (warehouseCode) => {
        var headers = props.headers
        await axios
        .get(
          `${props.mobileSite}MobileApp/validwarehouseshelfs/${warehouseCode}`, {headers: headers}
          )
          .then(function async(response) {
            // console.log("valid Shelfs:", response.data.shelfs)
            setAllValidShelfNumbers(response.data.shelfs)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        //! Need itMessage bar
        // setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        // setMessageBarOpen(true);
        // setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      })
      .then(function () {
        // always executed
      });
      
    };

    
  //!SECTION
  // !SECTION Cycle Count Scanning
  return (
    <div>
      <Grid
        container
        marginTop={1}
        sx={{
          justifyContent: "center",
          alignItems: "stretch",
          p: 0,
          border: 0,
          borderStyle: "dotted",
          borderRadius: "1px",
          direction: "row-reverse",
        }}
      >
        <Grid item xs={8} md={8} lg={8} sx={{ border: 0 }}>
            <Paper sx={{ p: 1, display: "flex", flexDirection: "column" }}>
            <Typography align="center" variant={"h4"}>
                {props.eventAccount} Account
            </Typography>
            </Paper>
        </Grid>
        </Grid>

      <Grid
        container
        marginTop={1}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          p: 1,
          border: 0,
          borderStyle: "dotted",
          borderRadius: "5px",
          direction: "row-reverse",
        }}
      >
            {viewWarehouseDropDown && 
          <>
        <Grid item xs={4} md={4} lg={4} sx={{ border: 0 }}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label" sx={{fontSize: 24, color: "white", padding: 2}}>Scanning Type</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value= {scanningType}
              onChange={handleScanningTypeChange}
            >
              <FormControlLabel value="inbound" control={<Radio />} label="Inbound" />
              <FormControlLabel value="outbound" control={<Radio />} label="Outbound" />
              <FormControlLabel value="cycleCount" control={<Radio />} label="Cycle Count" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {props.user.services.includes("IS") &&
        <Grid item xs={4} md={4} lg={4} sx={{ border: 0}}>
            <WarehouseDropDown
                handleSelectedWarehouse={handleSelectedWarehouse}
                warehouseList={props.warehouseList}
                toggleScanInventory={props.toggleScanInventory}
                user={props.user}
            />
        </Grid>
            }

        </>
            }
        {viewWarehouseName &&
        <Grid item xs={8} md={8} lg={8} sx={{ border: 0 }}>
            <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    border: 0,
                  }}
                >
                <Typography align="center" variant={"h4"}>{warehouseName}</Typography>
            </Paper>
                <Button
                    onClick={handleChangeWarehouse}
                >
                Change Location
                </Button>

        </Grid>
        }
        </Grid>
        <Grid
        container
        marginTop={1}
        sx={{
          justifyContent: "center",
          alignItems: "stretch",
          p: 1,
          border: 0,
          borderStyle: "dotted",
          borderRadius: "5px",
          direction: "row-reverse",
        }}
      >
        {/* viewWarehouse Scans */}
        {viewWarehouseScans && (
            <Grid item xs={8} md={8} lg={8} sx={{ border: 0 }}>
              <Paper sx={{ p: 1, display: "flex", flexDirection: "column" }}>
                <ProductScanning 
                  user={props.user}
                  accountCode={props.accountCode}
                  eventAccount={props.eventAccount}
                  eventAccountCode={props.eventAccountCode}
                  toggleScanInventory={props.toggleScanInventory}
                  handleChangeWarehouse={handleChangeWarehouse}
                  handleLocalStorageChange={handleLocalStorageChange}
                  warehouseCode = {warehouseCode}
                  eventIndex = {eventIndex}
                  scanningTypeMessage = {scanningTypeMessage}
                  scanningType = {scanningType}
                  handleSnackBar={props.handleSnackBar}
                  eventAccountSerialType={props.eventAccountSerialType}
                  allValidShelfNumbers={allValidShelfNumbers}
                  allValidSerialNumbers={allValidSerialNumbers}
                  allValidProductNumbers={allValidProductNumbers}
                  changeValidItemInfo={changeValidItemInfo}
                  headers={props.headers}
                  mobileSite = {props.mobileSite}
                />
              </Paper>
            </Grid>
        )}
      </Grid>
    </div>
  );
}