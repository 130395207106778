import * as React from 'react';

import { DataGridPro, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';

import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function CurrentWarehouseShelfTable(props) {

    const nonAdminUser = props.user.role !== 10 ? true : false;
    const [selectedModel, setSelectedModel ] = React.useState();
    const  currentWarehouseShelfs  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'warehouseName', headerName: 'Warehouse', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'shelfName', headerName: 'Shelf', width:200, headerAlign: 'center', align: 'center',  editable: false},

      // {field: 'shelfName', headerName: 'Shelf', width:200, headerAlign: 'center', align: 'center',  editable: false},
    //   {field: 'actions', type: 'actions', headerName: 'Delete User', width:150 ,  
    //   getActions: (params) => [
    //     <GridActionsCellItem icon={<DeleteIcon />} onClick={props.deleteMobileUser(params.id)}  label="Delete" />
    //   ]},

      ], 
      rows: props.warehouseShelfsDisplayData
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 

        showCellRightBorder
        showColumnRightBorder
        showCellLeftBorder
        
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...currentWarehouseShelfs} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};