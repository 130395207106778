import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import UserContext from "./context/UserContext";


// import WALogo from './img/WarehouseAnywhere.png'
import EdonLogo from './edonLogoWhite.png'
import Button from '@mui/material/Button';
import axios from 'axios';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import HeaderMenus from './Components/HeaderMenus';
import ResourceMembershipStats from './Components/ResourceMembershipStats.js';
// import ComputerImage from './img/upload.jpg'
import ComputerImage from './edonLogoWhite.png'
import MessageSnackBar from './Components/MessageSnackBar';



// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Edon Connect
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const drawerWidth = 240;


const cards = [1,2,3,4,5,6,7,8,9];

const headings = [
  "Nothing",
  "Load and List Your Resources",
  "Co-branded Site",
  "Partnership Dashboard",
  "Sell New Services",
  "Create Customer Accounts",
  "Sell Optimizations",
  "Sell Other High-Value Add Services",
  "Become a Elite Resource Partner",
  "Profit Opportunities",

  "Execution",
  "Replenishment",
  "Fullfillment",
  "Evaluation Dashboard",
  "Blockchain",
  "Integrations",
  
]
const content = [
  "Nothing",
  "Upload your resources manually. (API can be available for automatic updates) All of your resources will be listed on all optimizations where appropriate.",
  "Your companies logo will be on the site. It will let your customers and/or employees know that you are providing the service.",
  "Load your available resources or manually. See all activity of employees or customers on your membership. Billing for all of your customer accounts for all services.",
  "Set up an account for your customers and charge them for high value added services. Whether you do it for a customer or give them a account under your membership, you can bill them for add-on services. ",
  "When you sell your customer an account, they can upload their order data. The site will break our the order data into markets(metro cities).  They can one Network Optimization for free.",
  "After one free Network Optimization, you can charge them for other markets. (Of course your resources will be listed) Then you can sell them Inventory Optimizations for each market.",
  "As Edon Connect adds new services, your customers will have access to those service and you can bill for those services.",
  "For a monthly membership fee you will be able to LOAD & LIST YOUR RESOURCES, CO-BRANDED SITE, ADMINSTRATIVE DASHBOARD. You can then resell all service at a profit.",
  "You can sell all of our services at a profit every month. From the the Partnership Dashboad, you can see the activity and bill your customers they way that you want.",



  "We will help you stay connected when you execute your plan. The Demand Analyzer will track your current demand for possible opportunities for improvement.",
  "We will automate all of your replenishment orders according to your orders and inventory levels.",
  "We can help you order fulfillment and routing",
  "Make sure that your supply chain is working as expected. If not, find out why and possible corrective actions.",
  "Participate in a supply chain blockchain application to reduce accounting reconcilation cost, improve forecasting and added services to your customers.  ",
  "Integrate Edon Connect to your ERP, WMS and other systems.",
  
]



export default function DataUpload(props) {

  const pageTitle = 'Resource Partnerships';
  const { onLogout } = React.useContext(UserContext);
  const [benefitsMessage, setBenefitsMessage] = React.useState("Show Resource Partner Benefits");
  const [showBenefits, setShowBenefits] = React.useState(false);

  //SECTION Message Bar Controls
  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION

  {/* Toggle the benefits */}
  const toggleBenefits = () => {
    if (showBenefits){
      setShowBenefits(false);
      setBenefitsMessage('Show Resource Partnership Benefits');
    }else{
      setShowBenefits(true);
      setBenefitsMessage('Hide Benefits');
    };
  };



  const [viewResourceMembershipStats, setViewResourceMembershipStats] = React.useState(false);
  const [resourceMembershipStatsMessage, setResourceMembershipStatsMessage] = React.useState("View Resource Membership Activities");
  const [ resourceMembershipStats, setResourceMembershipStats ] = React.useState([]);
  const toggleResouceMembershipStats = () => {
    if (viewResourceMembershipStats){
      setViewResourceMembershipStats(false);
      setResourceMembershipStatsMessage('View Resource Membership Activites');
    }else{
      getResourceMembershipStats();
      setViewResourceMembershipStats(true);
      setResourceMembershipStatsMessage('Hide Resource Membership Activites');

    };
  }

  {/* Get Resource Membership Stats */}
  //SECTION Axios get membership stats
  const getResourceMembershipStats = () => {
    //console.log(headers);
    const membershipid = 4
    const headers = props.headers
    axios.get(`information/resourcemembershipstats/${membershipid}`, { headers })
    .then(function (response) {
      // handle success
      setResourceMembershipStats([response.data])
      //("Membership Stats:", response.data); 

    })
    .catch(function (error) {
      if (error.response.status === 401) {
        alert ("Your session has expired. Please login again.");
        onLogout();
      }
      if (error.response.status != 401) {
        setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      }
    })
    .then(function () {
      // always executed
    });
  }
  //!SECTION


  return (
    <ThemeProvider theme={darkTheme}>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />

                {/* Content*/}
                {/* Content*/}
                {/* Content*/}
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />


        <Container overflow="auto" maxWidth="xl" sx={{ mt:5, pt:2 ,mb: 10, border:1 }}>  
          {/* End hero unit */}
          <img src={EdonLogo} width={200} padding={10} alt="Logo" />

          <Grid container spacing={3} sx={{alignContents:'center'}}>

           <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />

            <Grid item align={'center'} xs={12} sm={12} md={12} sx={{alignContent: 'center', border:0 } } >
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
              <Button onClick={toggleBenefits}> {benefitsMessage}</Button>
            </Paper>
            </Grid>
          
          {
          showBenefits &&
            <>
            <Grid item align={'center'} xs={12} sm={12} md={12} >
              <Typography variant="h3" >Resource Partnership Benefits</Typography>
            </Grid>
            {cards.map((card) => (
              <Grid item key={card} xs={12} sm={12} md={4}>

                <Card
                  sx={{ height: '80%', display: 'flex', flexDirection: 'column' }}
                >

                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {headings[card]}
                    </Typography>
                    <Typography>
                      {content[card]}
                    </Typography>
                  </CardContent>
                  {false &&
                  <CardActions>
                    <Button size="small">View</Button>
                  </CardActions>
                  }
                </Card>

              </Grid>
            )
            )}
            </>
          }
          </Grid>
          
            <Grid item xs={12} sm={12} md={12} sx={{pt:2, alignContent: 'center', border:0 } } >
              <Paper sx={{ p:2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleResouceMembershipStats}>{resourceMembershipStatsMessage}</Button>
              {viewResourceMembershipStats &&
              <ResourceMembershipStats resourceMembershipStats={resourceMembershipStats}  />}
              </Paper>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Copyright sx={{ p: 4, align: "center" }} />
            </Grid>

        </Container>

          
        </Box>
      </Box>
    </ThemeProvider>
  );
}