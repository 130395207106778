import * as React from 'react';
// import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import axios from 'axios';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import MessageSnackBar from './MessageSnackBar';

export default function FieldSelection(props) {

  //SECTION Message Bar Controls
  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION

  const [orderNumber ] = React.useState('');
  const [address ] = React.useState('');

  const [disableCleanFileButton, setDisableCleanFileButton ] = React.useState(true);
  const [disableVerifyFieldsButton, setDisableVerifyFieleButton ] = React.useState(false);
  const [ fieldSelectionError, setFieldSelectionError ] = React.useState(props.errorFieldSelection);
  const [ viewLoadingSpinner, setviewLoadingSpinner ] = React.useState(false)


  const [ cleaningSelections, setCleaningSelections ] = React.useState(
      {
          'orderNumberIndex': props.fileRecommendations.order[0],
          'addressIndex': props.fileRecommendations.address[0],
          'cityIndex': props.fileRecommendations.city[0],
          'stateIndex': props.fileRecommendations.state[0],
          'zipIndex': props.fileRecommendations.zip[0],
          'itemNumberIndex': props.fileRecommendations.item[0],
          'unitCostIndex': props.fileRecommendations.cost[0],
          'itemQuantityIndex': props.fileRecommendations.quantity[0],
          'orderDateIndex': props.fileRecommendations.date[0],
          'selectedZipFile' : props.regionalFileName,
    }
  );

  const [labels, setlabels ] = React.useState(
      {
          'orderNumberIndex': props.columnNamesList[props.fileRecommendations.order[0]].label,
          'addressIndex': props.columnNamesList[props.fileRecommendations.address[0]].label,
          'cityIndex': props.columnNamesList[props.fileRecommendations.city[0]].label,
          'stateIndex': props.columnNamesList[props.fileRecommendations.state[0]].label,
          'zipIndex': props.columnNamesList[props.fileRecommendations.zip[0]].label,
          'itemNumberIndex': props.columnNamesList[props.fileRecommendations.item[0]].label,
          'unitCostIndex': props.columnNamesList[props.fileRecommendations.cost[0]].label,
          'itemQuantityIndex': props.columnNamesList[props.fileRecommendations.quantity[0]].label,
          'orderDateIndex': props.columnNamesList[props.fileRecommendations.date[0]].label,
    }
  );



  const handleCheckFields = () => {


    //! Check for Select Field as a selector
    const selectedColumnErrorIndex =  props.columnNamesList.length - 1
    var newErrors= []
    for (const property in cleaningSelections){
        if (cleaningSelections[property]===selectedColumnErrorIndex){
            newErrors.push(true)
        }else{
            newErrors.push(false)
        }
    }
    if (newErrors.includes(true)){
        setFieldSelectionError(newErrors);
        alert("You need to select a valid field for all columns")
        return;
    }

    //! See if all of the fields are the differnent
    
    var differentSelections = new Set(Object.values(cleaningSelections));

    //! If any of them are the same do this
    if (differentSelections.size !== Object.keys(cleaningSelections).length){
        const cleaningValues = Object.values(cleaningSelections)
        const sameValues = Array.from(new Set(cleaningValues.filter((item, index) => cleaningValues.indexOf(item)!==index)))
        var sameValuesErrors = []
        //! find out which fields have the same value
        //! change the selection error to turn it red and give an alert
        for (const property in cleaningSelections){
            if (sameValues.includes(cleaningSelections[property])){
                sameValuesErrors.push(true)
            }
            else{
                sameValuesErrors.push(false);
            }
        };
        setFieldSelectionError(sameValuesErrors);
        alert("All fields must be a different value");
    }else{
        setFieldSelectionError([false, false, false, false, false, false, false, false, false,])
    }

    //TODO
    //! Check for high missing Values


    //! Check for errors and change the buttons
      if (fieldSelectionError.every( item => item === false)) {
        alert("Select the Clean Data Buttun to start the cleaning of data.  This will put the data into markets")
        setDisableCleanFileButton(false);
        setDisableVerifyFieleButton(true);
      }

  };



  const handleChange = (event) => {

    var fieldName = event.target.name;
    var fieldIndex = event.target.value;

    setlabels( prevState  => ({
        ...prevState,
        [fieldName]: props.columnNamesList[event.target.value].label,
    }))
    // console.log(FieldSelection)
    setCleaningSelections( prevState  => ({
        ...prevState,
        [fieldName]: fieldIndex,
    }))
};
    //SECTION Axios Clean Dirty Data
    const handleCleanData = () => {
        const data = cleaningSelections;
        const user = props.user.email;
        const file = props.fileName;
        setDisableCleanFileButton(false);
        setviewLoadingSpinner(true);


        var config = {
            method: 'post',
            url: `/platformsuperdirtyfilecleaning/${user}/${file}/${props.accountCode}`,
            data : data
        };
        axios(config)
        .then(function (response) {
            setviewLoadingSpinner(false);
            // console.log("Cleaning Response: ", response.data)
            props.closeWindows()
            var message = response.data
            alert(`Your data has been cleaned and put into ${message} markets. Go to the Network to optimize.  `)
            
        })
        .catch(function (error) {
            // console.log(error);
            //!Message bar
            setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        });
    }
    //!SECTION


  return (
    <Grid container spacing={2} sx={{alignContent: 'center', border:0, p:2  } }>
      <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />

    <Grid item xs={1.5}>
      <FormControl error={fieldSelectionError[0]} fullWidth>
        <InputLabel id="order-number-input-label">{labels.orderNumberIndex}</InputLabel>
        <Select
          labelId="order-number-label"
          id="order-number-id"
          value={orderNumber}
          label="Order"
          name="orderNumberIndex"
          onChange={handleChange}
        >
            {props.columnNamesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText>Order Number</FormHelperText>
    </Grid>

    <Grid item xs={1.5}>
      <FormControl error={fieldSelectionError[1]} fullWidth>
        <InputLabel id="address-input-label">{labels.addressIndex}</InputLabel>
        <Select
          labelId="address-label"
          id="address-id"
          label="Address"
          name="addressIndex"
          value={address}
          onChange={handleChange}
        >
            {props.columnNamesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Address</FormHelperText>
      </FormControl>
    </Grid>

    <Grid item xs={1}>
      <FormControl error={fieldSelectionError[2]} fullWidth>
        <InputLabel id="city-input-label">{labels.cityIndex}</InputLabel>
        <Select
          labelId="city-label"
          id="city-id"
          label="City"
          name="cityIndex"
          value={address}
          onChange={handleChange}
        >
            {props.columnNamesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>City</FormHelperText>
      </FormControl>
    </Grid>

    <Grid item xs={1}>
      <FormControl error={fieldSelectionError[3]} fullWidth>
      <InputLabel id="state-input-label">{labels.stateIndex}</InputLabel>
        <Select
          labelId="state-label"
          id="state-id"
          label="State"
          name="stateIndex"
          value={address}
          onChange={handleChange}
        >
            {props.columnNamesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>State</FormHelperText>
      </FormControl>
    </Grid>

    <Grid item xs={1}>
      <FormControl error={fieldSelectionError[4]} fullWidth>
      <InputLabel id="zip-input-label">{labels.zipIndex}</InputLabel>
        <Select
          labelId="zip-label"
          id="zip-id"
          label="Zip"
          name="zipIndex"
          value={address}
          onChange={handleChange}
        >
            {props.columnNamesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Zip</FormHelperText>
      </FormControl>
    </Grid>

    <Grid item xs={1.5}>
      <FormControl error={fieldSelectionError[5]} fullWidth>
      <InputLabel id="item-input-label">{labels.itemNumberIndex}</InputLabel>
        <Select
          labelId="item-label"
          id="item-id"
          label="Item"
          name="itemNumberIndex"
          value={address}
          onChange={handleChange}
        >
            {props.columnNamesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Item Number</FormHelperText>
      </FormControl>
    </Grid>

    <Grid item xs={1.5}>
      <FormControl error={fieldSelectionError[6]} fullWidth>
      <InputLabel id="unit_cost-input-label">{labels.unitCostIndex}</InputLabel>
        <Select
          labelId="unit-cost-label"
          id="unit_cost-id"
          label="Unit Cost"
          name="unitCostIndex"
          value={address}
          onChange={handleChange}
        >
            {props.columnNamesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Unit Cost</FormHelperText>
      </FormControl>
    </Grid>

    <Grid item xs={1.5}>
      <FormControl error={fieldSelectionError[7]} fullWidth>
      <InputLabel id="item-quantity-input-label">{labels.itemQuantityIndex}</InputLabel>
        <Select
          labelId="item-quantity-label"
          id="item-quantity-id"
          label="Item Quantity"
          name="itemQuantityIndex"
          value={address}
          onChange={handleChange}
        >
            {props.columnNamesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Item Quantity</FormHelperText>
      </FormControl>
    </Grid>

    <Grid item xs={1.5}>
      <FormControl error={fieldSelectionError[8]} fullWidth>
      <InputLabel id="order-date-input-label">{labels.orderDateIndex}</InputLabel>
        <Select
          labelId="order-date-label"
          id="order-date-id"
          label="Order Date"
          name="orderDateIndex"
          value={address}
          onChange={handleChange}
        >
            {props.columnNamesList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Order Date</FormHelperText>
      </FormControl>
    </Grid>
    <Grid container sx={{justifyContent:'center', border:0, pt:5, borderStyle: 'dotted', borderRadius: '5px'  }}>
        <Button disabled={disableVerifyFieldsButton} onClick={handleCheckFields} variant="contained" component="label">
            Verify Fields
        </Button>
    </Grid>

    <Grid container sx={{justifyContent:'center', border:0, pt:5, borderStyle: 'dotted', borderRadius: '5px'  }}>
        <Button disabled={disableCleanFileButton} onClick={handleCleanData} variant="contained" component="label">
            Clean Data
            {viewLoadingSpinner &&
          <CircularProgress sx={{margin:1}} color="inherit" size ={20}/>
            }
        </Button>
    </Grid>
    



  </Grid>  



  );
}
