import * as React from 'react';

import { DataGridPro, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';

import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function MobileUserTable(props) {

    const nonAdminUser = props.user.role !== 10 ? true : false;
    const [selectedModel, setSelectedModel ] = React.useState();
    const  currentItems  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'accountName', headerName: 'Account Name', width:200, headerAlign: 'center', align: 'center',  editable: false, hide: nonAdminUser},
      {field: 'productName', headerName: 'Product Name', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'productNum', headerName: 'Product Number', width:200, headerAlign: 'center', align: 'center', editable: false},
      {field: 'itemCost', headerName: 'Item Cost', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'size', headerName: 'Size', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'weight', headerName: 'Weight', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'manufacture', headerName: 'Manufacture', width:200,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'createdAt', headerName: 'Created Date', width:200,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'creator', headerName: 'Created By', width:200, headerAlign: 'center', align: 'center',  editable: false},
    //   {field: 'actions', type: 'actions', headerName: 'Delete User', width:150 ,  
    //   getActions: (params) => [
    //     <GridActionsCellItem icon={<DeleteIcon />} onClick={props.deleteMobileUser(params.id)}  label="Delete" />
    //   ]},

      ], 
      rows: props.currentItemsDisplayData
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 

        showCellRightBorder
        showColumnRightBorder
        showCellLeftBorder
        pagination
      //   pagination: { paginationModel: { pageSize: 5 }},
      //   }}
      // pageSizeOptions={[5, 10, 20]}  
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...currentItems} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};